import React from 'react';

function BodyLHHome() {
    return (<>
        <div className="container-fluid p-4 body-font">
            <div className="row">
                <div className="col-xs-12 col-md-6 py-md-5">
                    <p className="h6"><b>Lufthansa CarPool powered by Hertz 24/7 </b></p>
                    <p className="h1"><b>BUCHEN. EINSTEIGEN.</b><font className="text-warning"><b>FAHREN!</b></font></p>
                    <p className="h6">Einfach stunden- oder tageweise anmieten, wann immer Sie es brauchen. Kein Papier, <br />kein Counter, einfach registrieren und innerhalb von 15 Minuten unterwegs sein.</p>
                    <div className="row">
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }} >
                            <div className="row">
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }} >
                                    <a href='https://apps.apple.com/de/app/hertz-24-7-mobility/id6453605078'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }} >
                                    <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=de_de&gl=de'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-6">
                        </div>

                    </div>
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/LH_banner.jpg" alt='' />
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <h1 className="display-5"><b>Wie es funktioniert</b></h1>
                    <br />
                    <p className="h6">
                        Wir haben die Anmietung mit Hertz24/7® so einfach und schnell gemacht, dass Sie innerhalb von 15 Minuten in Ihrem Fahrzeug sitzen können. Und so funktioniert es.
                    </p>
                    <br />
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_large.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>01</b></p><br />
                    <p className="h2">App herunterladen</p>
                    <p>Laden Sie die neue Hertz 24/7 App schnell und einfach von Ihrem App-Store herunterladen, indem Sie den QR-Code scannen.</p>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Register.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>02</b></p><br />
                    <p className="h2">Registrierung</p>
                    <p>Erstellen Sie einfach Ihr Mitgliedschaftsprofil und senden Ihre Dokumente zur Überprüfung mit der In-App-Fotofunktion.</p>

                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Select_Location_DE.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>03</b></p><br />
                    <p className="h2">Fahrzeugbuchung</p>
                    <p>Wählen Sie den gewünschten Abholort, ggf. Abgabeort, und die Abhol- und Abgabezeiten aus. Die Konditionen, wie z.B. die inkludierten Freikilometer, werden Ihnen während der Buchung angezeigt.</p>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Unlock.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>04</b></p><br />
                    <p className="h2">Start Miete</p>
                    <p>Kurz vor Beginn Ihrer Anmietung wird Ihnen das Fahrzeug zugewiesen. Bei der Abholung unterschreiben Sie einfach digtial Ihren Mietvertrag und Sie erhalten den digitalen. Schlüssel in der App, um das Fahrzeug zu entriegeln.</p>
                </div>
            </div>
            <div className="row" style={{ backgroundColor: '#F3F3F3' }}>
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br />
                    <h1 className="display-5"><b>Hertz CarAbo</b></h1>
                    <br />
                    <p className="h6" style={{ textAlign: 'center' }}>
                        Das Hertz CarAbo - Längerfristig unabhängige Mobilität genießen.
                    </p>
                    <br />
                    <div className="row">
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hyundai_i20.png" alt='' />
                            <br />  <br />
                            <div className="row">
                                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                                </div>
                                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <p className="h2">Hyundai i20</p>
                                        <span>
                                            <ul>
                                                <li>ab 499,Euro pro Monat </li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                                </div>
                            </div>


                        </div>
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Seat_Arona.png" alt='' />
                            <br />  <br />
                            <div className="row">
                                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                                </div>
                                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <p className="h2">Seat Arona FR</p>
                                        <span>
                                            <ul>
                                                <li>ab 599,Euro pro Monat</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                                </div>
                            </div>
 
                        </div>
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Polestar_2LR.png" alt='' />
                            <br />  <br />
                            <div className="row">
                                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                                </div>
                                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <p className="h2">Polestar 2 Long Range</p>
                                        <span>
                                            <ul>
                                                <li>ab 749,Euro pro Monat</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Mobile_DE_LH.png" alt='' />
                </div>
                <br/>
                <div className="col-xs-12 col-md-6 py-md-5" style={{ textAlign: 'center' }}>    
                    <div className="row">
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }}>
                            <div className="row">
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'center' }}>
                                    <a href='https://apps.apple.com/de/app/hertz-24-7-mobility/id6453605078'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'center' }}>
                                    <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=de_de&gl=de'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                                    </a>
                                </div>

                            </div>

                        </div>
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }}>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row p-4" style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/LH_banner2.jpg)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1" >
                    </div>
                    <div className="col-xs-12 col-md-3" >
                        <br />
                        <br />
                        <br />
                        <span>
                            <h1 className="display-4"><b>Warum Lufthansa CarPool powered by Hertz 24/7® ?</b></h1>
                        </span>
                        <p>
                            Beim Lufthansa CarPool können Sie Fahrzeuge zu einem günstigen Preis anmieten, und zwar ab 3 Stunden, oder auch Tage- und Monatsweise.</p>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-8" >
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center', backgroundColor: '#fff7cc' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Contact.png" alt='' />
                    <p className="h4">Kundenbetreuung</p>
                    <p>Sie erreichen uns während unserer Öffnungszeiten persönlich, ansonsten melden wir uns schnellstmöglich bei Ihnen.</p>                   
                    
                </div>
                <div className="col-xs-12 col-md-4 " style={{ textAlign: 'center', backgroundColor: '#fff099' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Price.png" alt='' />
                    <p className="h4">Einfache Preisgestaltung</p>
                    <p>Buchen Sie Ihr Fahrzeug zu Stunden-, Tages- und Monatsraten.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center', backgroundColor: '#ffe866' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Location.png" alt='' />
                    <p className="h4">An vielen Flughäfen</p>
                    <p>Den Lufthansa CarPool finden Sie derzeit an den Flughäfen Frankfurt, München, Hamburg, Berlin und Bremen </p>
                </div>
            </div>


        </div>
    </>);
}
export default BodyLHHome;