import React from 'react';


function FAQBody() {
    return (<>
        <div className="container-fluid  p-4 body-font">
            <div className='row p-4' style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/FAQ_top.png)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1">
                    </div>
                    <div className="col-xs-12 col-md-5">
                        <br />
                        <p className="h6"><b>Questions fréquentes</b></p>
                        <p className='h1'><b>En savoir plus</b></p>
                        <p>
                            Nous comprenons que vous puissiez avoir des questions sur le fonctionnement de Hertz 24/7, des inquiétudes concernant vos factures ou que vous souhaitiez simplement savoir ce qu'il faut faire pour vous inscrire. Cette page répondra à ces questions.
                        </p>



                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-4">
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <br /><br />

                    <p><a href="#reg" style={{ textDecoration: 'none' }}>Inscription</a></p>
                    <hr />
                    <p><a href="#book" style={{ textDecoration: 'none' }}>Réservation</a></p>
                    <hr />
                    <p><a href="#yj" style={{ textDecoration: 'none' }}>Votre voyage</a></p>
                    <hr />
                    <p><a href="#bfi" style={{ textDecoration: 'none' }}>Facturation, frais et assurance</a></p>
                    <hr />
                </div>

                <div className="col-xs-12 col-md-9" style={{ textAlign: 'left' }}>
                    <br /><br />


                    <div className="accordion p-4" id="accordionFlushExample">
                        <br />
                        <p id="reg"><b>Register</b></p>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading1">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                                    <b>Qu'est-ce que Hertz 24/7 ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse1" className="accordion-collapse collapse" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Hertz 24/7® vous offre la possibilité et la flexibilité d'avoir accès à une large gamme d'utilitaires, aussi longtemps  que vous le souhaitez. Vous pouvez louer d'une heure à une semaine. Vous pouvez également récupérer votre véhicule à n'importe quelle heure du jour ou de la nuit, sous réserve d'accessibilité des parkings de nos partenaires. De plus, l'assurance et l'entretien sont inclus dans le tarif.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                                    <b>Quels sont les détails dont vous avez besoin ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse2" className="accordion-collapse collapse" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Pour adhérer à Hertz 24/7, vous devez fournir les informations suivantes :<br />
                                    · Un permis de conduire et une pièce d'identité (passeport ou CI) valides à votre nom<br />
                                    · Une carte de crédit ou de débit à votre nom<br />
                                    · Votre propre numéro de téléphone portable et votre adresse électronique personnelle.<br />
                                    'Nous procédons ensuite aux contrôles d'identité nécessaires avant de confirmer votre inscription.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading3">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                                    <b>Comment saurai-je si mon inscription a été acceptée ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse3" className="accordion-collapse collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Nous vous enverrons un courriel ou un SMS pour confirmer que nous avons reçu vos documents d'enregistrement puis pour vous confirmer que votre demande a été traitée.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading4">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                                    <b>Que se passe-t-il si mes coordonnées changent ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse4" className="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Il vous suffit de vous connecter à votre profil Hertz 24/7 dans l'application mobile et de modifier vos coordonnées.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading5">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                                    <b>Mon adhésion expire-t-elle ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse5" className="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Votre adhésion sera d'une durée d'un an et sera renouvelée automatiquement à la date anniversaire de votre adhésion et tant que votre permis de conduire est valide et que vous continuez à répondre à nos conditions de location. D'autres termes et conditions peuvent s'appliquer.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading7">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse7" aria-expanded="false" aria-controls="flush-collapse7">
                                    <b>Quel âge dois-je avoir pour louer un véhicule ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse7" className="accordion-collapse collapse" aria-labelledby="flush-heading7" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Vous devez être âgé de 21 ans ou plus pour louer et conduire l'un de nos véhicules. Vous devez également être titulaire d'un permis de conduire valide depuis au moins un an et n'avoir fait l'objet d'aucune suspension au cours des cinq dernières années.</div>
                            </div>
                        </div>

                        <br />
                        <p id="book"><b>Réservation</b></p>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading9">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse9" aria-expanded="false" aria-controls="flush-collapse9">
                                    <b>Que se passe-t-il si le véhicule que je souhaite est déjà réservé ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse9" className="accordion-collapse collapse" aria-labelledby="flush-heading9" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Si le véhicule que vous souhaitez réserver n'est pas disponible à l'endroit de votre choix, veuillez choisir un autre véhicule ou vérifier la disponibilité dans d'autres lieux à proximité.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading10">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse10" aria-expanded="false" aria-controls="flush-collapse10">
                                    <b>Comment saurai-je si ma réservation est confirmée ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse10" className="accordion-collapse collapse" aria-labelledby="flush-heading10" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Nous vous enverrons la confirmation de la réservation par courrier électronique et la réservation confirmée sera également visible dans la page d'accueil de l'application mobile.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading11">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse11" aria-expanded="false" aria-controls="flush-collapse11">
                                    <b>Y a-t-il des frais pour modifier ou annuler ma réservation ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse11" className="accordion-collapse collapse" aria-labelledby="flush-heading11" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Vous pouvez modifier ou annuler votre réservation jusqu'à 6 heures avant l'heure de prise en charge du véhicule, sans pénalité. Si l'annulation a lieu dans les 6 heures précédent l'heure de prise en charge, vous serez facturé pour la période réservée, jusqu'à un maximum d'un jour de facturation.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading12">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse12" aria-expanded="false" aria-controls="flush-collapse12">
                                    <b>Mon paiement est-il sécurisé ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse12" className="accordion-collapse collapse" aria-labelledby="flush-heading12" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Oui. Notre processeur de paiement est Stripe. Nous respectons également toute la législation appropriée en matière de protection des données, personnelles et financières.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading13">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse13" aria-expanded="false" aria-controls="flush-collapse13">
                                    <b>Comment serai-je facturé lors de la réservation d'une location Hertz 24/7 ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse13" className="accordion-collapse collapse" aria-labelledby="flush-heading13" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Nous ferons une demande de pré-autorisation à hauteur de 200 % des frais initiaux 48 heures avant le début de la location ou immédiatement si la location commence dans les 48 heures. La réservation ne sera confirmée qu'une fois l'autorisation obtenue. Les autorisations préalables seront débloquées au moment de l'émission de la facture. Nous déduirons les frais et rembourserons le reste. Si le coût de la location dépasse le montant de l'acompte, nous prélèverons le montant excédentaire. Veuillez noter que tout retard  dans le remboursement de la caution est imputable à la banque émettrice de votre carte de crédit ou de débit.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading14">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse14" aria-expanded="false" aria-controls="flush-collapse14">
                                    <b>Combien de temps à l'avance puis-je réserver un véhicule ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse14" className="accordion-collapse collapse" aria-labelledby="flush-heading14" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">En fonction du site de location, au maximum entre trois et six mois.</div>
                            </div>
                        </div>

                        <br />
                        <p id="yj"><b>Votre voyage</b></p>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading15">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse15" aria-expanded="false" aria-controls="flush-collapse15">
                                    <b>Comment déverrouiller le véhicule ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse15" className="accordion-collapse collapse" aria-labelledby="flush-heading15" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">L'application Hertz 24/7 sera votre clé numérique. Pour ouvrir votre voiture, appuyez sur le bouton "Déverrouiller" dans l'onglet "Conduite" pendant votre location. Remarque : vous ne pourrez pas ouvrir la voiture tant que la période de location n'aura pas officiellement commencé, que l'inspection n'aura pas été effectuée et que le contrat n'aura pas été signé. Assurez-vous que la fonction "Bluetooth" de votre téléphone est activée et que vous êtes à moins de 5 mètres du véhicule. **Si vous obtenez un message d' erreur d'activation du Bluetooth sur iOS (&gt; 13) et bien que votre Bluetooth soit activé, veuillez aller dans l'application 'Réglages' puis dans la section HERTZ 24/7 et activer l'option Bluetooth**</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading16">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse16" aria-expanded="false" aria-controls="flush-collapse16">
                                    <b>Où trouver les clés ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse16" className="accordion-collapse collapse" aria-labelledby="flush-heading16" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Les clés se trouvent à l'intérieur du véhicule, dans un boitier situé dans la boite à gant. Si elles ne s'y trouvent pas, vérifiez les autres compartiments.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading17">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse17" aria-expanded="false" aria-controls="flush-collapse17">
                                    <b>Comment démarrer le véhicule ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse17" className="accordion-collapse collapse" aria-labelledby="flush-heading17" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Sur les véhicules équipés d'une serrure, la clé de contact se trouve dans la boîte à gants ou sur une chaîne près du volant. Appuyez sur la pédale de frein (pédale d'embrayage pour les boîtes de vitesses manuelles) et appuyez sur le bouton de démarrage ou démarrez le moteur à l'aide de la clé. Si le véhicule ne démarre pas, il se peut que le système d'immobilisation soit activé. Dans l'application Hertz 24/7, sur l'écran Conduire, appuyez sur le bouton Power pour désactiver l'antidémarrage. Le véhicule devrait alors démarrer. REMARQUE : Il n'y a pas de bouton de démarrage séparé sur l'application Hertz 24/7 pour les Modèles TESLA et POLESTAR.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading18">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse18" aria-expanded="false" aria-controls="flush-collapse18">
                                    <b>Que faire si le véhicule ne démarre pas ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse18" className="accordion-collapse collapse" aria-labelledby="flush-heading18" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Le véhicule est doté d'un dispositif de sécurité qui active l'antidémarrage si le contact est coupé pendant plus de 2 minutes. Pour redémarrer le moteur, s'il est immobilisé, retirez la clé du contact, utilisez l'application pour supprimer l'antidémarrage en appuyant sur l'icône "power" sur l'écran de conduite (l'icône entre les icônes de verrouillage/déverrouillage). Vous pouvez également verrouiller et déverrouiller le véhicule via l'application. Si le  véhicule ne démarre toujours pas, contactez notre service Client. Il résoudra le problème à distance ou trouvera un autre véhicule à utiliser à proximité.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading19">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse19" aria-expanded="false" aria-controls="flush-collapse19">
                                    <b>Comment trouver le véhicule ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse19" className="accordion-collapse collapse" aria-labelledby="flush-heading19" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Votre confirmation de réservation comprend des détails sur l'endroit où trouver le véhicule. Ces informations sont également disponibles en ligne via l'application. Veillez à vérifier les horaires d'ouverture du lieu de prise en charge et les éventuelles restrictions de stationnement avant de réserver votre véhicule. Si vous avez l'intention de laisser votre propre véhicule privé sur le site, veuillez l'enregistrer auprès du personnel partenaire sur place.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading20">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse20" aria-expanded="false" aria-controls="flush-collapse20">
                                    <b>Que se passe-t-il si le véhicule que j'ai réservé n'est pas là ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse20" className="accordion-collapse collapse" aria-labelledby="flush-heading20" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Veillez à vérifier les environs immédiats. Si vous ne parvenez toujours pas à localiser votre véhicule, veuillez contacter notre Service Client. Vous trouverez le numéro dans l'application et nous vous proposerons un véhicule alternatif.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading21">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse21" aria-expanded="false" aria-controls="flush-collapse21">
                                    <b>Que se passe-t-il si je restitue le véhicule en retard ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse21" className="accordion-collapse collapse" aria-labelledby="flush-heading21" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Si vous dépassez la plage horaire qui vous a été attribuée sans prolonger votre location au préalable, vous serez facturé d'un tarif horaire plus élevé pour le temps supplémentaire d'utilisation du véhicule, qui varie selon le véhicule et l'emplacement. Des frais de retard standard vous seront également facturés.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading22">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse22" aria-expanded="false" aria-controls="flush-collapse22">
                                    <b>Le fait que je prenne le véhicule en retard a-t-il une incidence ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse22" className="accordion-collapse collapse" aria-labelledby="flush-heading22" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>Pour chaque location, il y a une période de 6 heures pendant laquelle vous pouvez prendre le véhicule. Après 6 heures, la location sera automatiquement annulée, mais le montant total de la location vous sera facturé jusqu'à 1 jour.</p>
                                    <p>Si vous ne pouvez pas prendre le véhicule à l'heure prévue, vous pouvez modifier la location à tout moment*.<br />Vous pouvez également annuler les réservations de location jusqu'à 6 heures avant le début de la location sans frais. Cependant, si vous annulez moins de 6 heures avant le début de la location, le montant total de la location sera facturé jusqu'à 1 jour.</p>
                                    <p>*Sous réserve de disponibilité du véhicule.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading23">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse23" aria-expanded="false" aria-controls="flush-collapse23">
                                    <b>Qui paie les frais de carburant ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse23" className="accordion-collapse collapse" aria-labelledby="flush-heading23" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Le carburant est facturé sur la base d'un prix par kilomètre, affiché au moment de la réservation. Tous les véhicules doivent être munis d'une carte de carburant sous le pare-soleil ou dans la boite a gant et d'au moins un quart de réservoir au début de la location. Vous devez également restituer le véhicule de cette manière. Si la carte de carburant est manquante au début de la location, veuillez nous contacter en utilisant le numéro de téléphone figurant dans la section Aide de l'application. La carte de carburant fournie est limitée à 60€ par transaction. Les cartes de carburant peuvent être utilisées dans les stations-service du réseau DKV et le code pin affiché dans l'application sera requis lors du paiement du carburant.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading24">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse24" aria-expanded="false" aria-controls="flush-collapse24">
                                    <b>Que se passe-t-il si le réservoir du véhicule est vide ou moins d'un quart plein ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse24" className="accordion-collapse collapse" aria-labelledby="flush-heading24" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Si le réservoir est moins d'un quart plein, veuillez nous en informer et faire le plein à l'aide de la carte de carburant fournie. Vous trouverez la carte de carburant sous le pare-soleil ou dans la boite a gant et le code PIN d'accès à la carte de carburant dans l'application mobile. S'il n'y a plus de carburant dans le véhicule ou si vous n'avez pas trouvé la carte de carburant, veuillez contacter notre Service Client.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading25">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse25" aria-expanded="false" aria-controls="flush-collapse25">
                                    <b>Que dois-je faire si j'ai dépassé la limite de la carte de carburant ou si j'ai payé du carburant sans carte ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse25" className="accordion-collapse collapse" aria-labelledby="flush-heading25" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Lorsque vous faites le plein du véhicule, veillez à ne pas dépasser la limite indiquée sur la carte de carburant. Si vous dépassez la limite, vous devrez payer vous-même le carburant. Vous devrez envoyer le reçu par courriel au Service Client Hertz 24/7 afin de recevoir un remboursement pour le carburant que vous avez payé vous-même.<br />Veuillez noter que le traitement de cette demande peut prendre plusieurs jours et qu'elle est soumise à des frais de traitement de 15 euros afin de contribuer aux coûts et dépenses liés au remboursement lorsque vous n'avez pas respecté la procédure de ravitaillement.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading26">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse26" aria-expanded="false" aria-controls="flush-collapse26">
                                    <b>Puis-je fumer à l'intérieur du véhicule ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse26" className="accordion-collapse collapse" aria-labelledby="flush-heading26" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Non. Veuillez ne pas fumer à l'intérieur d'un véhicule Hertz 24/7. Depuis le 1er mai 2013, nos véhicules sont classés comme des zones strictement non-fumeurs. Cela fait partie de notre volonté de fournir une flotte sûre et propre à tous nos employés et clients. Nous vous remercions de votre attention. Des frais de nettoyage de 50 € seront ajoutés à votre facture si nous trouvons des preuves que vous avez fumé à l'intérieur du véhicule pendant votre période de location.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading27">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse27" aria-expanded="false" aria-controls="flush-collapse27">
                                    <b>Que se passe-t-il si je reçois une contravention pour excès de vitesse ou une amende de stationnement ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse27" className="accordion-collapse collapse" aria-labelledby="flush-heading27" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Comme on peut s'y attendre, les péages, les contraventions pour excès de vitesse, les amendes de stationnement et les frais de ferry sont à votre charge.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading29">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse29" aria-expanded="false" aria-controls="flush-collapse29">
                                    <b>Que se passe-t-il si je trouve quelque chose dans le véhicule ou si j'oublie quelque chose qui m'appartient ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse29" className="accordion-collapse collapse" aria-labelledby="flush-heading29" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ce n'est pas un problème. Il vous suffit de contacter notre équipe et nous nous chargerons de récupérer votre article ou de trouver le locataire précédent qui a laissé l'objet.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading30">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse30" aria-expanded="false" aria-controls="flush-collapse30">
                                    <b>Fournissez-vous des sièges pour enfants ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse30" className="accordion-collapse collapse" aria-labelledby="flush-heading30" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Non. Cependant, vous pouvez tout à fait utiliser les vôtres.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading31">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse31" aria-expanded="false" aria-controls="flush-collapse31">
                                    <b>Suis-je autorisé à transporter des animaux domestiques dans le véhicule ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse31" className="accordion-collapse collapse" aria-labelledby="flush-heading31" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Non. Les animaux ne sont pas autorisés dans nos véhicules, à l'exception des chiens d'aide à la personne.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading32">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse32" aria-expanded="false" aria-controls="flush-collapse32">
                                    <b>Dois-je nettoyer le véhicule lorsque je le rends ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse32" className="accordion-collapse collapse" aria-labelledby="flush-heading32" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Oui. Pour le bénéfice du prochain client, veuillez vous assurer que l'intérieur du véhicule est propre à la fin de la location.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading33">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse33" aria-expanded="false" aria-controls="flush-collapse33">
                                    <b>Que faire si le véhicule n'est pas propre à l'intérieur ou à l'extérieur ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse33" className="accordion-collapse collapse" aria-labelledby="flush-heading33" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Nous demandons  à nos clients de laisser le véhicule propre et bien rangé à la fin de chaque location. Nous entretenons régulièrement notre flotte de véhicules. Toutefois, n'hésitez pas à nous faire part de vos remarques sur l'état des véhicules.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading34">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse34" aria-expanded="false" aria-controls="flush-collapse34">
                                    <b>Puis-je restituer le véhicule plus tôt ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse34" className="accordion-collapse collapse" aria-labelledby="flush-heading34" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Oui, vous pouvez restituer le véhicule à tout moment pendant la période de location. Cependant, vous devrez payer pour toute la période de réservation.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading35">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse35" aria-expanded="false" aria-controls="flush-collapse35">
                                    <b>Comment puis-je prolonger ma location ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse35" className="accordion-collapse collapse" aria-labelledby="flush-heading35" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Vous pouvez facilement prolonger votre location via l'application sous réserve de disponibilité des véhicules. Suivez les étapes  en appuyant sur le bouton "Prolonger la location" dans l'onglet "Conduite" pendant votre location.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading36">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse36" aria-expanded="false" aria-controls="flush-collapse36">
                                    <b>Où dois-je restituer le véhicule ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse36" className="accordion-collapse collapse" aria-labelledby="flush-heading36" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Veuillez remettre le véhicule à l'aire de stationnement désignée par Hertz 24/7 sur le site.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading37">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse37" aria-expanded="false" aria-controls="flush-collapse37">
                                    <b>Que se passe-t-il si la place de stationnement qui m'a été attribuée est déjà occupée ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse37" className="accordion-collapse collapse" aria-labelledby="flush-heading37" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Veuillez garer le véhicule dans la place la plus proche de l'emplacement Hertz 24/7.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading38">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse38" aria-expanded="false" aria-controls="flush-collapse38">
                                    <b>Que se passe-t-il si le véhicule que j'ai réservé est rendu en retard par le client qui m'a précédé ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse38" className="accordion-collapse collapse" aria-labelledby="flush-heading38" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Nous essaierons toujours de vous joindre à temps pour vous en informer. Toutefois, si vous n'avez pas eu de nouvelles de notre part, nous vous invitons à nous contacter le plus rapidement possible et nous vous proposerons un autre véhicule.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading39">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse39" aria-expanded="false" aria-controls="flush-collapse39">
                                    <b>Dois-je utiliser les clés pour verrouiller et déverrouiller le véhicule ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse39" className="accordion-collapse collapse" aria-labelledby="flush-heading39" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Non. Cela pourrait causer des problèmes pendant la location. Les clés doivent être laissées à l'intérieur du véhicule à tout moment. Veuillez utiliser l'application Hertz 24/7 pour verrouiller et déverrouiller le véhicule pendant votre location. REMARQUE : en cas d'inactivité, le dispositif d'immobilisation du véhicule peut se mettre en marche. Pour le débloquer, appuyez sur la touche bouton "power" sur l'écran Conduire de l'application.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading40">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse40" aria-expanded="false" aria-controls="flush-collapse40">
                                    <b>Comment vos véhicules sont-ils entretenus ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse40" className="accordion-collapse collapse" aria-labelledby="flush-heading40" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Une équipe de professionnels dévoués s'occupe de notre flotte de véhicules Hertz 24/7. En plus de l'entretien programmé habituel, chaque véhicule fait l'objet d'un contrôle de qualité mensuel en   40 points.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading41">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse41" aria-expanded="false" aria-controls="flush-collapse41">
                                    <b>Que faire en cas de panne ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse41" className="accordion-collapse collapse" aria-labelledby="flush-heading41" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Appelez notre équipe d'assistance. Vous trouverez notre numéro de téléphone sur la page de contact de l'application. L'Assistance peut également être jointe directement au 0800 286 464</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading42">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse42" aria-expanded="false" aria-controls="flush-collapse42">
                                    <b>Que dois-je faire en cas d'accident ou d'incident ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse42" className="accordion-collapse collapse" aria-labelledby="flush-heading42" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Contactez immédiatement l'équipe Hertz 24/7 en utilisant les coordonnées figurant dans la section "aide" de l'application mobile. Nous nous occuperons de tout. Veillez simplement à obtenir les coordonnées du tiers impliqué dans l'accident et à prendre des photos des dommages subis par les deux véhicules. Tout défaut de déclaration d'incident peut invalider votre couverture d'assurance.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading43">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse43" aria-expanded="false" aria-controls="flush-collapse43">
                                    <b>Que dois-je faire si le véhicule est volé ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse43" className="accordion-collapse collapse" aria-labelledby="flush-heading43" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Vous devez nous en informer immédiatement par téléphone. Vous trouverez le numéro de téléphone dans la rubrique contact.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading44">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse44" aria-expanded="false" aria-controls="flush-collapse44">
                                    <b>Puis-je utiliser Hertz 24/7 dans d'autres pays ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse44" className="accordion-collapse collapse" aria-labelledby="flush-heading44" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Oui. Si vous êtes inscrit chez nous, vous pouvez actuellement utiliser Hertz 24/7 dans n'importe quel établissement partenaire (Royaume-Uni, France, Allemagne, Belgique et Portugal).</div>
                            </div>
                        </div>

                        <br />
                        <p id="bfi"><b>Facturation, frais et assurance</b></p>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading45">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse45" aria-expanded="false" aria-controls="flush-collapse45">
                                    <b>Dois-je payer une redevance si le véhicule est remorqué ou bloqué ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse45" className="accordion-collapse collapse" aria-labelledby="flush-heading45" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Nous paierons d'abord l'amende, puis nous l'ajouterons à votre facture, ainsi que des frais administratifs de 19,90 €.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading46">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse46" aria-expanded="false" aria-controls="flush-collapse46">
                                    <b>Devrai-je payer des frais de restitution tardive si le véhicule tombe en panne ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse46" className="accordion-collapse collapse" aria-labelledby="flush-heading46" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Non. Dans le cas très improbable d'une panne, vous n'aurez pas à payer de frais supplémentaires (sauf si vous avez causé la panne bien entendu). L'assistance routière d'urgence 24 heures sur 24 est incluse dans votre réservation.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading47">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse47" aria-expanded="false" aria-controls="flush-collapse47">
                                    <b>Y a-t-il des frais supplémentaires dont je dois tenir compte ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse47" className="accordion-collapse collapse" aria-labelledby="flush-heading47" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Oui. Chaque réservation est assortie d'un prix au kilométre parcouru. Si vous endommagez le véhicule, vous devez payer une franchise d'assurance dont le montant varie en foction du type de véhicule. La négligence grave (par exemple, les dommages au toit, la contamination du carburant ou de l'Ad-blue) et l'utilisation abusive du véhicule ne sont pas couvertes par l'assurance collision et l'assurance responsabilité civile qui sont incluses dans le tarif de location du véhicule.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading48">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse48" aria-expanded="false" aria-controls="flush-collapse48">
                                    <b>Mon assurance automobile personnelle couvrira-t-elle les frais de dommages ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse48" className="accordion-collapse collapse" aria-labelledby="flush-heading48" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Non. Votre assurance automobile personnelle n'est pas valable pour les véhicules Hertz 24/7. Nos véhicules sont uniquement couverts par les polices d'assurance que nous fournissons ou mettons à disposition en relation avec votre réservation.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading49">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse49" aria-expanded="false" aria-controls="flush-collapse49">
                                    <b>Dois-je payer une franchise ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse49" className="accordion-collapse collapse" aria-labelledby="flush-heading49" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">En cas de dommage, de perte ou de vol du véhicule, vous devrez payer une franchise dont le montant varie en fonction du type de véhicule. L'information vous est fournie au moment de votre réservation. Si le montant de la réparation est inférieure à la franchise, nous vous remboursons la différence, moins des frais administratifs.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading50">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse50" aria-expanded="false" aria-controls="flush-collapse50">
                                    <b>Quelle est la couverture del'assurance de ma réservation ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse50" className="accordion-collapse collapse" aria-labelledby="flush-heading50" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">L'assurance vous couvre pour les dommages causés au véhicule, au-delà du montant de la franchise. La franchise est le montant maximum que vous êtes tenu de payer en cas de sinistre. Si vous respectez les conditions générales de votre contrat de location, vous serez également protégé par l'assurance responsabilité civile, qui est incluse en standard et qui est exigée par la loi. Cette assurance vous couvre pour les dommages causés aux autres véhicules, aux autres biens et aux personnes blessées en cas d'accident. Si la perte, les dommages ou le vol du véhicule sont causés intentionnellement, si vous mettez le mauvais carburant dans le véhicule, contaminez le réservoir de carburant ou le réservoir Ad-Blue, tous ces dommages seront considérés comme une négligence grave et vous serez tenu de payer les frais de réparation. Si le véhicule est volé, seul le montant de la franchise vous sera facturé.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading51">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse51" aria-expanded="false" aria-controls="flush-collapse51">
                                    <b>Puis-je compter sur l'assurance de mon fournisseur de carte de crédit pour couvrir les dommages éventuels ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse51" className="accordion-collapse collapse" aria-labelledby="flush-heading51" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Il est préférable de vérifier auprès de votre fournisseur de carte de crédit avant d'effectuer votre réservation Hertz 24/7. Les différentes cartes de crédit offrent des régimes de protection différents.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row shadow-lg p-3 mb-5 bg-body rounded" >
                <a href='https:/l.ead.me/beWm77'><img className="img-fluid rounded-4" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/App_Banner-new-format%20FR.png" alt='' style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} /></a>
            </div>


        </div>


    </>
    );
}

export default FAQBody;