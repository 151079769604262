import React from 'react';
import Button from 'react-bootstrap/Button';

function BusinessSolutionBody() {
    return (<>
        <div className="container-fluid  p-4 body-font">
            <div className='row p-4' style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/BusinessSolutions_top_desktop.png)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1">
                    </div>
                    <div className="col-xs-12 col-md-5">
                        <br />
                        <p className="h6"><b>Obchodní řešení</b></p>
                        <span>
                            <h1><i><b>Vaše společnost na cestách!</b></i></h1>
                        </span>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-4">
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <p className="h2">Obchodní řešení</p>
                    <br />
                    <p>
                        Začlenění služby Hertz 24/7® Car Sharing do vašeho firemního plánu udržitelnosti je více než jen chytré obchodní rozhodnutí - je to závazek k lepší a udržitelnější budoucnosti. Spoluprací se společností Hertz poskytujete svým zaměstnancům nejen pohodlné možnosti dopravy, ale také podnikáte smysluplné kroky ke snížení uhlíkové stopy vaší společnosti a podporujete ochranu životního prostředí.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                    <img className='img-fluid' src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Corda_Mobile.png" alt='' />
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" sstyle={{ textAlign: 'left' }}>
                            <p className="h3">Efektivní z hlediska nákladů</p>
                            <p>Díky vyhrazenému firemnímu vozovému parku pro sdílení vozidel mohou společnosti potenciálně snížit náklady ve srovnání s proplácením individuálních jízd zaměstnancům nebo využíváním služeb třetích stran.</p>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" style={{ textAlign: 'left' }}>
                            <p className="h3">Transparentní a pod kontrolou</p>
                            <p>Udržování vlastního sdíleného vozového parku poskytuje společnostem větší transparentnost a kontrolu nad jejich přepravními operacemi. Mohou sledovat způsoby využití, monitorovat údržbu vozidel a vynucovat dodržování předpisů.</p>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" style={{ textAlign: 'left' }}>
                            <p className="h3">Šetrný k životnímu prostředí</p>
                            <p>Sdílený firemní vozový park pro spolujízdu může výrazně snížit ekologickou stopu dopravy zaměstnanců. Podniky mohou v rámci svého vozového parku upřednostňovat používání ekologických vozidel, jako jsou hybridy nebo elektromobily. Tím přispějí ke snížení emisí uhlíku a podpoří iniciativy v oblasti udržitelnosti.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br />
                    <p className="h2">Kontaktujte nás a dozvíte se více</p>
                    <br />
                    <p>
                        Pokud máte zájem dozvědět se více, pošlete nám e-mail (<b>✉:tlopuskova@hertz.cz</b>). Člen našeho obchodního týmu se vám bude věnovat.
                    </p>
                    <br />
                    <p>
                        <Button href='mailto:tlopuskova@hertz.cz' variant="warning"><b>Kontaktujte nás</b></Button>
                    </p>
                </div>
            </div>
        </div>
    </>
    );
}

export default BusinessSolutionBody;

