import { useState } from 'react';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { NavLink } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';


function Cookiebanner(props) {

    const [show, setShow] = useState(true);
    const [mcshow, setmcShow] = useState(false);
    const [switchState, setSwitchState] = useState(true);
    const [isSwitchOn, setIsSwitchOn] = useState(false);

    if (!localStorage.getItem('Cookie_acceptordeny')) {
        localStorage.setItem('Cookie_acceptordeny', null);
        setShow(true);
    }
    if (!localStorage.getItem('Performance_cookie')) {
        localStorage.setItem('Performance_cookie', false);
    }
    if (!localStorage.getItem('country')) {
        localStorage.setItem('country', 'it');
    }


    const handleAccept = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
        localStorage.setItem('Performance_cookie', true);
    };

    const handleDeny = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'denied');
        localStorage.setItem('Performance_cookie', false);
    };

    const onSwitchAction = () => {

        if (localStorage.getItem('Performance_cookie') === 'false') {
            setIsSwitchOn(true);
        }

        else
            setIsSwitchOn(false);

    };

    const handleSave = () => {
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
        localStorage.setItem('Performance_cookie', isSwitchOn);
        setShow(false);
        setmcShow(false);
        setSwitchState(true);
    };

    const handleClose = () => setmcShow(false);
    
    if ((props.isVisible === null || props.isVisible === 'null') || localStorage.getItem('country') !== 'it') {

        localStorage.setItem('country', 'it');
        return (
            <div>
                <Modal
                    show={show}
                    onHide={handleDeny}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                >
                    <Modal.Body>
                        Su questo sito, usiamo Cookies per offrire il corretto funzionamento e una migliore esperienza di utilizzo. Puoi <b>"Accettare"</b>, <b>"Rifiutare"</b> o modificare le tue scelte in qualsiasi momento. Selezionando <b>"Accetta Cookies"</b> impostiamo cookies opzionali per offrire funzionalità avanzate (ad esempio l'assistenza online e la chat), contenuti e annunci personalizzati, e l'analisi del traffico web. Selezionando <b>"Rifiuta Cookies"</b> consenti solo l'uso dei cookies essenziali per il funzionamento del sito e offrire l'esperienza di navigazione di base. È possibile disabilitarli totalmente modificando le impostazioni del browser, ma ciò potrebbe influire sul funzionamento del sito. Per saperne di più sui cookies che utilizziamo e per decidere/modificare le impostazioni, selezionare <b>"Gestione Cookies"</b>. Per maggiori info su come utilizziamo i dati raccolti, dai un'occhiata alla nostra <a href="https://www.hertz.it/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Politica Privacy</a>.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleAccept}>
                            Accetta Cookies
                        </Button>
                        <Button variant="warning" onClick={handleDeny}>
                            Rifiuta Cookies
                        </Button>
                        <NavLink className="nav-link" onClick={() => setmcShow(true)} style={{ textDecoration: 'none' }}>Gestisci i cookie</NavLink> 
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={mcshow}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                    onHide={handleClose}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="managecookies-custom-modal-styling-title">
                            <img src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz_247_logo.png" className="img-fluid" alt="Hertz24x7" width={200} /> &nbsp; Gestisci i cookie
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        Quando visiti un sito web, questo può memorizzare o recuperare informazioni sul tuo browser, principalmente sotto forma di cookie. Queste informazioni potrebbero riguardare l'utente, le sue preferenze o il suo dispositivo e vengono utilizzate principalmente per far funzionare il sito come previsto. Le informazioni di solito non ti identificano direttamente, ma possono offrirti un'esperienza web più personalizzata. Poiché rispettiamo il tuo diritto alla privacy, puoi scegliere di non consentire alcuni tipi di cookie. Clicca sulle diverse intestazioni delle categorie per saperne di più e modificare le nostre impostazioni predefinite.
                        <Form>
                            <div className="accordion p-4" id="accordionMgCookie">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-mgc_echeading">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgc_eccollapse" aria-expanded="false" aria-controls="flush-mgc_eccollapse">
                                            <b> <Form.Check
                                                disabled
                                                type="switch"
                                                label="Essenziale"
                                                id="disabled-custom-switch"
                                                defaultChecked={switchState}
                                            />
                                            </b>
                                        </button>
                                    </h2>
                                    <div id="flush-mgc_eccollapse" className="accordion-collapse collapse" aria-labelledby="flush-mgc_echeading" data-bs-parent="#accordionMgCookie">
                                        Alcuni cookie sono vitali per il funzionamento dei nostri siti web. Senza di essi non saresti in grado di muoverti all'interno del sito Web e utilizzare alcune delle sue funzionalità. I cookie essenziali consentono di ricordare da una pagina all'altra le informazioni fornite e le decisioni prese in merito al noleggio da una pagina all'altra durante il processo di prenotazione. Poiché questi cookie sono essenziali, non ti consentiamo di scegliere di disabilitarli sul nostro sito web
                                        <div className="accordion-body">
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Cookie</th>
                                                        <th>Descrizione</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>dGroup</td>
                                                        <td>Ricorda da quale dispositivo si accede al sito (PC, tablet, telefono) per visualizzare il contenuto appropriato.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>incap_ses_*</td>
                                                        <td>Utilizzato per collegare le richieste HTTP a una sessione.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>nlbi_*</td>
                                                        <td>Questo cookie viene utilizzato per filtrare le richieste dannose. Le informazioni generate non possono identificare l'utente come individuo.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>visid_incap_*</td>
                                                        <td>Utilizzato per verificare che l'utente accetti i cookie e non sia un robot.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>DC-Cookie</td>
                                                        <td>Utilizzato per indirizzarti al server corretto durante l'intera visita.</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-mgcheading2">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgccollapse2" aria-expanded="false" aria-controls="flush-mgccollapse2">
                                            <b>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    label="Cookie di prestazione"
                                                    onChange={onSwitchAction}
                                                    defaultChecked={isSwitchOn}
                                                />
                                            </b>
                                        </button>
                                    </h2>
                                    <div id="flush-mgccollapse2" className="accordion-collapse collapse" aria-labelledby="flush-mgcheading2" data-bs-parent="#accordionMgCookie">
                                        Utilizziamo i cookie di prestazione per monitorare le prestazioni del sito Web e per analizzare il modo in cui i nostri visitatori utilizzano i nostri siti Web. Questo ci consente di fornire un'esperienza di alta qualità identificando e risolvendo rapidamente eventuali problemi che si presentano e personalizzando la nostra offerta. Tutte le informazioni raccolte sono aggregate e quindi anonime e vengono utilizzate solo per migliorare il funzionamento del nostro sito web.
                                        <div className="accordion-body">NA</div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleSave}>
                            Salva modifiche
                        </Button>

                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default Cookiebanner;