import {  NavLink, Outlet } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useEffect } from 'react';

function Nav() {

    useEffect(() => {
        const hd = document.querySelector("head");
        const bdy = document.querySelector("body");

        const scr = document.createElement("script");
        const ns = document.createElement("noscript");

        const strTM = String.fromCodePoint(71, 84, 77, 45, 77, 84, 75, 82, 53, 78, 54);
        const strGTM = `(function (w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', '${strTM}');`;

        ns.innerHTML = "<iframe src='https://www.googletagmanager.com/ns.html?id=" + strTM + "' height='0' width='0' style='display:none;visibility:hidden'></iframe>";
        bdy.prepend(ns);

        scr.innerHTML = strGTM;
        hd.prepend(scr);

        return () => {
            bdy.removeChild(ns);
            hd.removeChild(scr);
        }

    }, []);

    return (<>

        <nav className="navbar bg-primary navbar-expand-lg bg-body-tertiary body-font">
            <div className="container-fluid p-4">
                <NavLink className="navbar-brand" to="/CZ/Home"><img src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz_247_logo.png" className="img-fluid" alt="Hertz24x7"/></NavLink>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link"  to="/CZ/Home"><b>Home</b></NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/CZ/Howitworks"><b>Jak pronájem funguje</b></NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/CZ/Locations"><b>Lokality</b></NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/CZ/BusinessSolution"><b>Obchodní řešení</b></NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/CZ/FAQ"><b>Nejčastěji kladené otázky</b></NavLink>
                        </li>
                    </ul>

                    
                    <Button href='https://booking.hertz247.com/' variant="warning">Rezervovat nyní</Button>
                </div>
            </div>
           
        </nav>
        <hr/>
        <div className='container mt-2'>
            <Outlet />
        </div>
    </>);

}
export default Nav;