import React from 'react';


function FAQBody() {
    return (<>
        <div className="container-fluid  p-4 body-font">
            <div className='row p-4' style={{  width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/FAQ_top.png)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1">
                    </div>
                    <div className="col-xs-12 col-md-5">
                        <br />
                        <p className="h6"><b>FAQs</b></p>
                        <p className='h1'><b>Mehr erfahren</b></p>
                        <p>
                            Wir verstehen, dass Sie vielleicht Fragen zur Funktionsweise von Hertz 24/7, Bedenken bezüglich Ihrer Rechnungen haben oder einfach nur wissen möchten, was für die Registrierung erforderlich ist. Diese Seite wird diese Fragen beantworten.
                        </p>



                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-4">
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <br /><br />
                    
                    <p><a href="#reg" style={{textDecoration : 'none'}}>Registrieren</a></p>
                    <hr />
                    <p><a href="#book" style={{textDecoration : 'none'}}>Buchung</a></p>
                    <hr />
                    <p><a href="#yj" style={{textDecoration : 'none'}}>Ihre Anmietung</a></p>
                    <hr />
                    <p><a href="#bfi" style={{textDecoration : 'none'}}>Abrechnung, Gebühren und Versicherung</a></p>
                    <hr />
                </div>

                <div className="col-xs-12 col-md-9" style={{ textAlign: 'left' }}>
                    <br /><br />


                    <div className="accordion p-4" id="accordionFlushExample">
                        <br />
                        <p id="reg"><b>Registrieren</b></p>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading1">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                                    <b>Was ist Hertz 24/7?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse1" className="accordion-collapse collapse" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Hertz 24/7® bietet Ihnen den Komfort und die Flexibilität, auf eine breite Palette vonFahrzeugen zugreifen zu können, so lange oder so kurz wie Sie möchten. Sie können von nur einer Stunde bis zu einer Woche mieten. Außerdem können Sie den Wagen zu jeder Tages- und Nachtzeit abholen. </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                                    <b>Welche Angaben benötigen Sie?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse2" className="accordion-collapse collapse" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Um sich bei Hertz 24/7 anzumelden, müssen Sie die folgenden Angaben machen:<br />
                                    · Ein auf Ihren Namen ausgestellter gültiger Führerschein & Ausweis<br />
                                    · Eine Kredit- oder Debitkarte auf Ihren eigenen Namen<br />
                                    · Ihre eigene Handynummer und Ihre persönliche E-Mail-Adresse.<br />
                                    Wir führen dann die erforderlichen Ausweiskontrollen durch, bevor wir Ihre Anmeldung bestätigen.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading3">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                                    <b>Wie erfahre ich, ob meine Anmeldung angenommen wurde?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse3" className="accordion-collapse collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Wir schicken Ihnen eine E-Mail und/oder eine Push Nachricht, um zu bestätigen, dass wir Ihre Anmeldung vollständig ist. </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading4">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                                    <b>Was passiert, wenn sich meine Kontaktinformationen ändern?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse4" className="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Loggen Sie sich einfach in Ihr Hertz 24/7 Profil in der mobilen App ein und ändern Sie Ihre Daten.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading5">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                                    <b>Wird meine Mitgliedschaft ablaufen?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse5" className="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ihre Mitgliedschaft ist unbegrenzt, solange Ihr Führerschein gültig ist und Sie weiterhin unsere Mietbedingungen erfüllen. Andere Bedingungen können gelten.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading7">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse7" aria-expanded="false" aria-controls="flush-collapse7">
                                    <b>Wie alt muss ich sein, um ein Fahrzeug zu mieten?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse7" className="accordion-collapse collapse" aria-labelledby="flush-heading7" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Sie müssen mindestens 19 Jahre alt sein, um eines unserer Fahrzeuge mieten und fahren zu können. Außerdem müssen Sie seit mindestens einem Jahr im Besitz eines gültigen Führerscheins sein.</div>
                            </div>
                        </div>


                        <br />
                        <p id="book"><b>Buchung</b></p>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading9">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse9" aria-expanded="false" aria-controls="flush-collapse9">
                                    <b>Was ist, wenn das gewünschte Fahrzeug bereits gebucht ist?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse9" className="accordion-collapse collapse" aria-labelledby="flush-heading9" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Wenn das Fahrzeug, das Sie buchen möchten, an dem von Ihnen gewünschten Standort nicht verfügbar ist, wählen Sie bitte eine alternative Uhrzeit oder Datum, oder prüfen Sie die Verfügbarkeit an anderen Orten in der Nähe.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading10">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse10" aria-expanded="false" aria-controls="flush-collapse10">
                                    <b>Wie erfahre ich, ob meine Reservierung bestätigt ist?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse10" className="accordion-collapse collapse" aria-labelledby="flush-heading10" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Sie sehen Ihre Reservierung in der Hertz 24/7 App. Außerdem senden wir Ihnen die Reservierungsbestätigung per E-Mail.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading11">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse11" aria-expanded="false" aria-controls="flush-collapse11">
                                    <b>Gibt es Gebühren für die Änderung oder Stornierung meiner Buchung?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse11" className="accordion-collapse collapse" aria-labelledby="flush-heading11" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Sie können Ihre Buchung bis zu 6 Stunden vor der Abholzeit Ihres Fahrzeugs kostenlos ändern oder stornieren. Bei einer Stornierung innerhalb von 6 Stunden vor der Abholung wird Ihnen der reservierte Zeitraum in Rechnung gestellt, bis zu einem Maximum von eines Tagessatzes.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading12">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse12" aria-expanded="false" aria-controls="flush-collapse12">
                                    <b>Ist meine Zahlung sicher?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse12" className="accordion-collapse collapse" aria-labelledby="flush-heading12" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ja. Unser Zahlungsdienstleister ist Stripe. Wir befolgen auch alle geltenden Datenschutzgesetze in Bezug auf persönliche und finanzielle Daten. </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading13">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse13" aria-expanded="false" aria-controls="flush-collapse13">
                                    <b>Wie werden mir die Kosten berechnet, wenn ich eine Hertz 24/7-Miete buche?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse13" className="accordion-collapse collapse" aria-labelledby="flush-heading13" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">48 Stunden vor Beginn Ihrer Anmietung oder sofort, wenn Ihre Anmietung innerhalb von 48 Stunden beginnt fragen wir eine Genehmigung von bis zu 200% der ursprünglichen Kosten auf Ihrem hinterlegten Zahlungsmittel an. Die Buchung wird erst bestätigt, wenn die Genehmigung erfolgreich erteilt wurde. Die Vorautorisierung wird bei R e c h n u n g s s t e l l u n g freigegeben. Wir ziehen die Gebühren ab und erstatten den Restbetrag. Wenn die Mietkosten die Kaution übersteigen, werden wir den übersteigenden Betrag einbehalten. Bitte beachten Sie, dass jegliche Verzögerung bei der Rückerstattung der Kaution von Ihrer Kredit- oder Debitkarten ausstellenden Bank verursacht wird.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading14">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse14" aria-expanded="false" aria-controls="flush-collapse14">
                                    <b>Wie lange im Voraus kann ich ein Fahrzeug buchen?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse14" className="accordion-collapse collapse" aria-labelledby="flush-heading14" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Je nach Mietstation zwischen drei und sechs Monaten.</div>
                            </div>
                        </div>

                        <br />
                        <p id="yj"><b>Ihre Anmietung</b></p>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading15">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse15" aria-expanded="false" aria-controls="flush-collapse15">
                                    <b>Wie entriegelt man das Fahrzeug?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse15" className="accordion-collapse collapse" aria-labelledby="flush-heading15" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Die Hertz 24/7-App ist Ihr digitaler Schlüssel. Um Ihr Fahrzeug zu öffnen, drücken Sie während der Anmietung auf der Registerkarte "Fahren" die Taste "Öffnen". Hinweis: Sie können das Auto erst öffnen, wenn die Mietzeit offiziell begonnen hat, die Inspektion abgeschlossen ist und der Vertrag unterzeichnet wurde. Vergewissern Sie sich, dass **Bluetooth** aktiviert ist und sich innerhalb von 5 Metern vom Fahrzeug befinden. **Wenn Sie Bluetooth-Aktivierungsfehler auf iOS (&gt; 13) erhalten, obwohl Ihr Bluetooth eingeschaltet ist, gehen Sie bitte in die App "Einstellungen" und dann in HERTZ 24/7 und aktivieren Sie die Bluetooth-Option**</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading16">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse16" aria-expanded="false" aria-controls="flush-collapse16">
                                    <b>Wo finde ich die Schlüssel?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse16" className="accordion-collapse collapse" aria-labelledby="flush-heading16" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Sie finden die Schlüssel im Fahrzeug im Schlüsselhalter im Handschuhfach. Hier muss der Schlüssel beim Mietende auch wieder eingesteckt werden.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading17">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse17" aria-expanded="false" aria-controls="flush-collapse17">
                                    <b>Wie kann ich das Fahrzeug starten?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse17" className="accordion-collapse collapse" aria-labelledby="flush-heading17" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Bei Fahrzeugen mit einem Zündschloss finden Sie den Schlüssel im Schlüsselhalter im Handschuhfach. Treten Sie das Brems- und Kupplungspedal (bei Automatikgetrieben nur das Bremspedal) und drücken Sie den Startknopf oder starten Sie den Motor mit dem Schlüssel. Wenn das Fahrzeug nicht anspringt, ist möglicherweise die Wegfahrsperre aktiviert. Drücken Sie in der Hertz 24/7-App auf dem Fahrbildschirm die Power-Taste, um die Wegfahrsperre zu  deaktivieren. Das Fahrzeug sollte nun anspringen. HINWEIS: Bei den Modellen TESLA und POLESTAR gibt es keine separate Starttaste.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading18">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse18" aria-expanded="false" aria-controls="flush-collapse18">
                                    <b>Was ist, wenn das Fahrzeug nicht anspringt?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse18" className="accordion-collapse collapse" aria-labelledby="flush-heading18" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Das Fahrzeug verfügt über eine Sicherheitsfunktion, die die Wegfahrsperre aktiviert, wenn die Zündung für mehr als 2 Minuten ausgeschaltet ist. Um den Motor bei einer Wegfahrsperre wieder zu starten, ziehen Sie den Schlüssel aus dem Zündschloss und verwenden Sie die App, um die Wegfahrsperre zu deaktivieren, indem Sie auf das Symbol "Power" auf dem Fahrbildschirm drücken (das Symbol zwischen den Symbolen zum Ver- und Entriegeln). Alternativ können Sie das Fahrzeug auch über die App ver- und entriegeln. Wenn das Fahrzeug immer noch nicht anspringt, wenden Sie sich an unser Kundenservice Center. Es wird das Problem entweder aus der Ferne beheben oder ein anderes Fahrzeug in Ihrer Nähe für Sie finden.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading19">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse19" aria-expanded="false" aria-controls="flush-collapse19">
                                    <b>Wie kann ich das Fahrzeug finden?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse19" className="accordion-collapse collapse" aria-labelledby="flush-heading19" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ihre Buchungsbestätigung enthält Angaben dazu, wo Sie das Fahrzeug finden. Diese Informationen stehen Ihnen auch online über die App zur Verfügung. Bitte vergewissern Sie sich vor der Buchung Ihres Fahrzeugs über die Öffnungszeiten des Abholorts und eventuelle Parkbeschränkungen. Wenn Sie beabsichtigen, Ihr eigenes, privates Fahrzeug am Standort abzustellen, melden Sie dies bitte beim Partnerpersonal vor Ort an.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading20">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse20" aria-expanded="false" aria-controls="flush-collapse20">
                                    <b>Was ist, wenn das gebuchte Fahrzeug nicht da ist?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse20" className="accordion-collapse collapse" aria-labelledby="flush-heading20" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Bitte überprüfen Sie auch die unmittelbare Umgebung. Wenn Sie Ihr Fahrzeug immer noch nicht finden können, wenden Sie sich bitte an unser Kundenservice Center. Sie finden die Nummer in der App unter Hilfe.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading21">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse21" aria-expanded="false" aria-controls="flush-collapse21">
                                    <b>Was passiert, wenn ich das Fahrzeug zu spät zurückbringe?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse21" className="accordion-collapse collapse" aria-labelledby="flush-heading21" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Wenn Sie das von Ihnen reservierte Zeitfenster überschreiten, ohne die Miete vorher zu verlängern, wird Ihnen die zusätzliche Zeit berechnet, in der Sie das Fahrzeug nutzen.  Außerdem wird Ihnen eine Verspätungsgebühr berechnet.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading22">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse22" aria-expanded="false" aria-controls="flush-collapse22">
                                    <b>Macht es etwas aus, wenn ich das Fahrzeug zu spät abhole?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse22" className="accordion-collapse collapse" aria-labelledby="flush-heading22" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>Für jede Anmietung gibt es einen Zeitraum von 6 Stunden, in dem Sie das Fahrzeug abholen können. Nach 6 Stunden wird die Anmietung automatisch storniert, jedoch wird Ihnen der volle Mietbetrag bis zu 1 Tag berechnet.</p>
                                    <p>Wenn Sie Ihre Startzeit nicht innerhalb einer Stunde einhalten können, können Sie die Anmietung jederzeit anpassen*.<br />Sie können auch Buchungen bis zu 6 Stunden vor dem Start kostenlos stornieren. Wenn Sie jedoch weniger als 6 Stunden vor Mietbeginn stornieren, wird der volle Mietpreis bis zu 1 Tag in Rechnung gestellt.</p>
                                    <p>*Vorbehaltlich der Verfügbarkeit des Fahrzeugs.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading23">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse23" aria-expanded="false" aria-controls="flush-collapse23">
                                    <b>Wer kommt für die Treibstoffkosten auf?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse23" className="accordion-collapse collapse" aria-labelledby="flush-heading23" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Kraftstoff ist in unseren Tarifen nicht inkludiert. Das Fahrzeug wird voll übernommen und muss voll abgegeben werden. </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading24">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse24" aria-expanded="false" aria-controls="flush-collapse24">
                                    <b>Was ist, wenn der Tank des Fahrzeugs bei Abholung nicht voll ist?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse24" className="accordion-collapse collapse" aria-labelledby="flush-heading24" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Wenn der Tank der Abholung des Fahrzeugs nicht voll sein sollte, kontaktieren Sie bitte vor Abfahrt unser Kundenservice Center. </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading26">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse26" aria-expanded="false" aria-controls="flush-collapse26">
                                    <b>Darf ich im Inneren des Fahrzeugs rauchen?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse26" className="accordion-collapse collapse" aria-labelledby="flush-heading26" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Nein. Bitte rauchen Sie nicht in einem Hertz 24/7 Fahrzeug. Unsere Fahrzeuge sind seit dem 1. Mai 2013 als strikte Nichtraucherbereiche eingestuft. Dies ist Teil unseres Bestrebens, eine sichere und saubere Flotte für alle unsere Mitarbeiter und Kunden bereitzustellen. Vielen Dank für Ihre Rücksichtnahme. Eine Reinigungsgebühr wird Ihrer Rechnung hinzugefügt, wenn wir während Ihres Mietzeitraums Beweise für das Rauchen im Fahrzeug finden.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading27">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse27" aria-expanded="false" aria-controls="flush-collapse27">
                                    <b>Was passiert, wenn ich einen Strafzettel für zu schnelles Fahren oder einen Strafzettel für Falschparken bekomme?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse27" className="accordion-collapse collapse" aria-labelledby="flush-heading27" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Mautgebühren, Strafzettel, Bußgelder und Fährgebühren sind in Ihrer Verantwortung und von Ihnen zu zahlen.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading29">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse29" aria-expanded="false" aria-controls="flush-collapse29">
                                    <b>Was ist, wenn ich etwas im Fahrzeug finde oder etwas von mir zurücklasse?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse29" className="accordion-collapse collapse" aria-labelledby="flush-heading29" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Das ist kein Problem. Wenden Sie sich einfach an unser Team und wir kümmern uns um die Wiederbeschaffung Ihres Artikels ODER finden den Vormieter, der den Gegenstand zurückgelassen hat.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading30">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse30" aria-expanded="false" aria-controls="flush-collapse30">
                                    <b>Stellen Sie Kindersitze zur Verfügung?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse30" className="accordion-collapse collapse" aria-labelledby="flush-heading30" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Nein. Sie können aber gerne Ihre eigenen verwenden.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading31">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse31" aria-expanded="false" aria-controls="flush-collapse31">
                                    <b>Darf ich Haustiere im Fahrzeug mitnehmen?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse31" className="accordion-collapse collapse" aria-labelledby="flush-heading31" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Nein. Haustiere sind in unseren Fahrzeugen nicht erlaubt - mit Ausnahme von Assistenzhunden.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading32">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse32" aria-expanded="false" aria-controls="flush-collapse32">
                                    <b>Muss ich das Fahrzeug reinigen, wenn ich es zurückbringe?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse32" className="accordion-collapse collapse" aria-labelledby="flush-heading32" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ja. Bitte vergewissern Sie sich im Interesse des nächsten Kunden, dass das Innere des Fahrzeugs am Ende der Mietzeit in einem sauberen Zustand ist.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading33">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse33" aria-expanded="false" aria-controls="flush-collapse33">
                                    <b>Was ist, wenn das Fahrzeug innen oder außen nicht sauber ist?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse33" className="accordion-collapse collapse" aria-labelledby="flush-heading33" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Wir bitten unsere Kunden, das Fahrzeug am Ende jeder Anmietung sauber und aufgeräumt zu hinterlassen. Außerdem warten wir unseren Fuhrpark regelmäßig. Aber bitte lassen Sie uns wissen, wenn Sie mit dem Zustand des Fahrzeugs unzufrieden sind.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading34">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse34" aria-expanded="false" aria-controls="flush-collapse34">
                                    <b>Kann ich das Fahrzeug vorzeitig zurückgeben?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse34" className="accordion-collapse collapse" aria-labelledby="flush-heading34" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ja, Sie können das Fahrzeug jederzeit während Ihrer Mietdauer zurückgeben. Allerdings wird trotzdem der gesamte Buchungszeitraum berechnet.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading35">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse35" aria-expanded="false" aria-controls="flush-collapse35">
                                    <b>Wie kann ich meinen Mietvertrag verlängern?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse35" className="accordion-collapse collapse" aria-labelledby="flush-heading35" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Je nach Verfügbarkeit der Fahrzeuge können Sie Ihre Anmietung ganz einfach über die Hertz 24/7 App verlängern. Folgen Sie den Schritten  nach dem Sie auf die Schaltfläche "Miete verlängern" getippt haben.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading36">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse36" aria-expanded="false" aria-controls="flush-collapse36">
                                    <b>Wo soll ich das Fahrzeug zurückgeben?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse36" className="accordion-collapse collapse" aria-labelledby="flush-heading36" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Bitte geben Sie das Fahrzeug auf dem dafür vorgesehenen Hertz 24/7-Parkplatz am Standort ab.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading37">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse37" aria-expanded="false" aria-controls="flush-collapse37">
                                    <b>Was ist, wenn der mir zugewiesene Stellplatz bereits belegt ist?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse37" className="accordion-collapse collapse" aria-labelledby="flush-heading37" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Bitte parken Sie das Fahrzeug in der Parkbucht, die der Hertz 24/7-Bucht am nächsten liegt.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading38">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse38" aria-expanded="false" aria-controls="flush-collapse38">
                                    <b>Was ist, wenn das von mir gebuchte Fahrzeug vom Kunden vor mir verspätet zurückgegeben wird?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse38" className="accordion-collapse collapse" aria-labelledby="flush-heading38" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Wir werden immer versuchen, Sie rechtzeitig zu erreichen, um Ihnen Bescheid zu geben. Wenn Sie jedoch nichts von uns gehört haben, kontaktieren Sie uns bitte so schnell wie möglich und wir werden ein alternatives Fahrzeug organisieren.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading39">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse39" aria-expanded="false" aria-controls="flush-collapse39">
                                    <b>Sollte ich die Schlüssel jemals zum Ver- und Entriegeln des Fahrzeugs verwenden?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse39" className="accordion-collapse collapse" aria-labelledby="flush-heading39" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Nein. Wenn Sie dies tun, kann es zu Problemen während der Anmietung kommen. Die Schlüssel müssen zu jeder Zeit im Fahrzeug verbleiben. Bitte verwenden Sie die Hertz 24/7-App, um das Fahrzeug während Ihrer Anmietung zu ver- und entriegeln. HINWEIS: Bei Inaktivität kann sich die Wegfahrsperre des Fahrzeugs einschalten. Um diese zu deaktivieren, drücken Sie bitte die Power"-Taste auf dem "Fahren" Bildschirm in der App.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading40">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse40" aria-expanded="false" aria-controls="flush-collapse40">
                                    <b>Wie werden Ihre Fahrzeuge gewartet?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse40" className="accordion-collapse collapse" aria-labelledby="flush-heading40" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ein Team von engagierten Fachleuten kümmert sich um unsere Hertz 24/7-Fahrzeugflotte. Zusätzlich zu unserer üblichen planmäßigen Wartung führen wir monatlich einen 40-Punkte- Qualitätscheck an jedem Fahrzeug durch.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading41">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse41" aria-expanded="false" aria-controls="flush-collapse41">
                                    <b>Was tue ich, wenn ich eine Panne habe?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse41" className="accordion-collapse collapse" aria-labelledby="flush-heading41" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Bitte rufen Sie unser Kundenservice Center an. Sie finden unsere Telefonnummer auf der Kontaktseite in der App. </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading42">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse42" aria-expanded="false" aria-controls="flush-collapse42">
                                    <b>Was muss ich tun, wenn ich einen Unfall / Zwischenfall habe?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse42" className="accordion-collapse collapse" aria-labelledby="flush-heading42" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Im Falle eines Unfalls kontaktieren Sie bitte zwingend die Polizei. Danach wenden Sie sich bitte sofort an das Hertz 24/7-Team, indem Sie die Kontaktdaten im Bereich "Hilfe" der mobilen App verwenden. Wir werden uns dann um alles kümmern. Stellen Sie sicher, dass Sie die Details der dritten Partei, die in den Unfall verwickelt ist, aufnehmen und Fotos von den Schäden an beiden Fahrzeugen machen. Wenn Sie einen Vorfall nicht melden, kann Ihr Versicherungsschutz erlöschen.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading43">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse43" aria-expanded="false" aria-controls="flush-collapse43">
                                    <b>Was sollte ich tun, wenn das Fahrzeug gestohlen wird?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse43" className="accordion-collapse collapse" aria-labelledby="flush-heading43" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Sie müssen uns sofort telefonisch benachrichtigen. Die Telefonnummer finden Sie in der Rubrik "Kontakt".</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading44">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse44" aria-expanded="false" aria-controls="flush-collapse44">
                                    <b>ann ich Hertz 24/7 auch in anderen Ländern nutzen?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse44" className="accordion-collapse collapse" aria-labelledby="flush-heading44" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ja. Wenn Sie bei uns registriert sind, können Sie derzeit Hertz 24/7 an allen teilnehmenden Standorten in den USA, Vereinigtes Königreich, Frankreich, Deutschland, Belgien und Portugal nutzen.</div>
                            </div>
                        </div>

                        <br />
                        <p id="bfi"><b>Abrechnung, Gebühren und Versicherung</b></p>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading45">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse45" aria-expanded="false" aria-controls="flush-collapse45">
                                    <b>Muss ich eine Gebühr bezahlen, wenn das Fahrzeug abgeschleppt oder festgehalten wird?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse45" className="accordion-collapse collapse" aria-labelledby="flush-heading45" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Wir zahlen zunächst die Geldstrafe und setzen sie dann zusammen mit einer Verwaltungsgebühr auf Ihre Rechnung.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading46">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse46" aria-expanded="false" aria-controls="flush-collapse46">
                                    <b>Muss ich eine Gebühr für die verspätete Rückgabe zahlen, wenn das Fahrzeug eine Panne hat?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse46" className="accordion-collapse collapse" aria-labelledby="flush-heading46" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Nein. Im höchst unwahrscheinlichen Fall einer Panne müssen Sie keine zusätzlichen Kosten tragen (es sei denn, Sie haben die Panne verursacht). Die 24-Stunden-Notfall-Pannenhilfe ist in Ihrer Buchung inklusive.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading47">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse47" aria-expanded="false" aria-controls="flush-collapse47">
                                    <b>Gibt es irgendwelche zusätzlichen Kosten, die ich beachten muss?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse47" className="accordion-collapse collapse" aria-labelledby="flush-heading47" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Zusätzliche Gebühren wie z.B. Kosten für Mehrkilometer, Versicherungsgebühren werden Ihnen im Buchungsprozess angezeigt. Des Weiteren gelten unsere Zusatzgebühren wie z.B. für verspätete Rückgabe oder Nichtbetankung des Fahrzeugs, welche Sie in unseren Nutzungsbedingungen finden.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading48">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse48" aria-expanded="false" aria-controls="flush-collapse48">
                                    <b>Übernimmt meine private Kfz-Versicherung die Kosten für den Schaden?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse48" className="accordion-collapse collapse" aria-labelledby="flush-heading48" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Nein. Ihre persönliche Kfz-Versicherung gilt nicht für Hertz 24/7-Fahrzeuge. Unsere Fahrzeuge sind nur durch die Versicherungspolicen abgedeckt, die wir im Zusammenhang mit Ihrer Buchung anbieten oder zur Verfügung stellen.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading49">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse49" aria-expanded="false" aria-controls="flush-collapse49">
                                    <b>Muss ich eine Selbstbeteiligung zahlen?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse49" className="accordion-collapse collapse" aria-labelledby="flush-heading49" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Bei Beschädigung, Verlust oder Diebstahl des Fahrzeugs müssen Sie eine Selbstbeteiligung gemäß Ihrer bei Buchung abgeschlossenen Versicherung zahlen. Wenn die Reparatur weniger als die Selbstbeteiligung beträgt, erstatten wir Ihnen die Differenz abzüglich einer Verwaltungsgebühr.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading50">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse50" aria-expanded="false" aria-controls="flush-collapse50">
                                    <b>Was deckt die Versicherung meiner Buchung ab?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse50" className="accordion-collapse collapse" aria-labelledby="flush-heading50" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Die Versicherung kommt für Schäden am Fahrzeug auf, die über die Selbstbeteiligung hinausgehen. Die Selbstbeteiligung ist der Höchstbetrag, den Sie im Schadensfall zu zahlen haben. Vorausgesetzt, Sie halten sich an die Bedingungen Ihres Mietvertrags, sind Sie auch durch die Haftpflichtversicherung geschützt, die standardmäßig enthalten und gesetzlich vorgeschrieben ist. Diese deckt Schäden an anderen Fahrzeugen, anderem Eigentum und verletzten Personen im Falle eines Unfalls ab. Wenn der Verlust, die Beschädigung oder der Diebstahl des Fahrzeugs vorsätzlich verursacht wird, Sie den falschen Kraftstoff in das Fahrzeug einfüllen, den Kraftstofftank oder Ad-Blue Tank verunreinigen, werden alle diese Schäden als grobe Fahrlässigkeit eingestuft und Sie werden für die Reparaturkosten aufkommen. Bei Diebstahl des Fahrzeugs wird Ihnen nur die Selbstbeteiligung in Rechnung gestellt.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading51">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse51" aria-expanded="false" aria-controls="flush-collapse51">
                                    <b>Kann ich mich auf die Versicherung meines Kreditkartenanbieters verlassen, um einen Schaden zu decken?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse51" className="accordion-collapse collapse" aria-labelledby="flush-heading51" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Erkundigen Sie sich am besten bei Ihrem Kreditkartenanbieter, bevor Sie Ihre Hertz 24/7-Buchung vornehmen, denn verschiedene Kreditkarten haben unterschiedliche Versicherungsschutzsysteme.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row shadow-lg p-3 mb-5 bg-body rounded" >
                <a href='https:/l.ead.me/beWm77'><img className="img-fluid rounded-4" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/App_Banner-new-format%20DE.png" alt='' style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} /></a>
            </div>


        </div>


    </>
    );
}

export default FAQBody;