import React from 'react';
import Button from 'react-bootstrap/Button';


function BusinessSolutionBody() {
    return (<>
        <div className="container-fluid p-4 body-font">
            <div className='row p-4' style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/BusinessSolutions_top_desktop.png)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1">
                    </div>
                    <div className="col-xs-12 col-md-5">
                        <br />
                        <p className="h6"><b>Business Solutions</b></p>
                        <span>
                            <h1><i><b>YOUR COMPANY ON THE GO!</b></i></h1>
                        </span>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-4">
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br />
                    <p className="h2">Business Solutions</p>
                    <br />
                    <p>
                        Incorporating Hertz 24/7® Car Sharing into your corporate sustainability plan is more than just a smart business decision – it's a commitment to a brighter, more sustainable future. By partnering with Hertz, you're not only providing convenient transportation options for your employees but also taking meaningful steps towards reducing your company's carbon footprint and promoting environmental responsibility. Join the movement towards sustainable business practices with Hertz  24/7® Car Sharing today.
                    </p>

                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                    <img className='img-fluid' src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Corda_Mobile.png" alt='' />
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" sstyle={{ textAlign: 'left' }}>
                            <p className="h3">Cost efficient</p>
                            <p>By having a dedicated corporate fleet for ridesharing, businesses can potentially reduce costs compared to reimbursing employees for individual rides or using third-party services.</p>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" style={{ textAlign: 'left' }}>
                            <p className="h3">Transparent & In Control</p>
                            <p>Maintaining an in-house fleet provides businesses with greater transparency and control over their transportation operations. They can track usage patterns, monitor vehicle maintenance, and enforce company policies more effectively.</p>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" style={{ textAlign: 'left' }}>
                            <p className="h3">Kind to the environment</p>
                            <p>Adopting a corporate fleet for ridesharing can significantly reduce the environmental footprint of employee transportation. Businesses can prioritize the use of eco-friendly vehicles, such as hybrids or electric cars, within their fleet. By doing so, they contribute to lower carbon emissions and support sustainability initiatives.</p>

                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                
                    <br />
                    <p className="h2">Contact us to learn more</p>
                    <br />
                    <p>
                        Send us an email (<b>✉:hertz247ukpoolfleet@hertz.com</b>) if you are interested to learn more. A member of our business fleet team will be in touch with you.
                    </p>
                    
                    <p>
                        <Button href='mailto:hertz247ukpoolfleet@hertz.com' variant="warning"><b>Email Now</b></Button>
                    </p>
                </div>
            </div>
        </div>
    </>
    );
}

export default BusinessSolutionBody;

