// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-button {
   border-radius: 0px !important;
   background-color: white !important;  
}

.accordion-button:active, .accordion-button:focus{
  
   background-color: #FFEFB1 !important;    

}

.accordion-button:hover{
   background-color: #FFEFB1 !important;     

}

.body-font{
   font-family: "Open Sans", "Muli", "Helvetica", "Arial", "sans-serif";
}

.nav-link.active{
   text-decoration: underline;
}

.CookieConsent {
   max-width: 500px;
   border-radius: 5px;
   padding: 20px 30px;
   padding-right: 20px;
   background-color: white;
   position: fixed;
   top: 50%;
   left: 50%;
   right: auto;
   bottom: auto;
   transform: translate3d(-50%, -50%, 9999990px);
   width: 95%;
   box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.overlayclass {
   position: fixed;
   background-color: rgba(0, 0, 0, 0.5);
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/UK/Components/accordion.css"],"names":[],"mappings":"AAAA;GACG,6BAA6B;GAC7B,kCAAkC;AACrC;;AAEA;;GAEG,oCAAoC;;AAEvC;;AAEA;GACG,oCAAoC;;AAEvC;;AAEA;GACG,oEAAoE;AACvE;;AAEA;GACG,0BAA0B;AAC7B;;AAEA;GACG,gBAAgB;GAChB,kBAAkB;GAClB,kBAAkB;GAClB,mBAAmB;GACnB,uBAAuB;GACvB,eAAe;GACf,QAAQ;GACR,SAAS;GACT,WAAW;GACX,YAAY;GACZ,6CAA6C;GAC7C,UAAU;GACV,yCAAyC;AAC5C;;AAEA;GACG,eAAe;GACf,oCAAoC;GACpC,MAAM;GACN,OAAO;GACP,WAAW;GACX,YAAY;AACf","sourcesContent":[".accordion-button {\n   border-radius: 0px !important;\n   background-color: white !important;  \n}\n\n.accordion-button:active, .accordion-button:focus{\n  \n   background-color: #FFEFB1 !important;    \n\n}\n\n.accordion-button:hover{\n   background-color: #FFEFB1 !important;     \n\n}\n\n.body-font{\n   font-family: \"Open Sans\", \"Muli\", \"Helvetica\", \"Arial\", \"sans-serif\";\n}\n\n.nav-link.active{\n   text-decoration: underline;\n}\n\n.CookieConsent {\n   max-width: 500px;\n   border-radius: 5px;\n   padding: 20px 30px;\n   padding-right: 20px;\n   background-color: white;\n   position: fixed;\n   top: 50%;\n   left: 50%;\n   right: auto;\n   bottom: auto;\n   transform: translate3d(-50%, -50%, 9999990px);\n   width: 95%;\n   box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);\n}\n\n.overlayclass {\n   position: fixed;\n   background-color: rgba(0, 0, 0, 0.5);\n   top: 0;\n   left: 0;\n   width: 100%;\n   height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
