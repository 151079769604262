import React from 'react';


function FAQBody() {
    return (<>
        <div className="container-fluid  p-4 body-font">
            <div className='row p-4' style={{  width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/FAQ_top.png)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1">
                    </div>
                    <div className="col-xs-12 col-md-5">
                        <br />
                        <p className="h6"><b>Nejčastěji kladené otázky</b></p>
                        <p className='h1'><b>Zjistěte více</b></p>
                        <p>
                            Chápeme, že se můžete ptát, jak Hertz 24/7 funguje, máte obavy ohledně fakturace nebo prostě chcete vědět, co je potřeba k registraci. Tato stránka na tyto otázky odpoví.
                        </p>



                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-4">
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <br /><br />
                    
                    <p><a href="#reg" style={{textDecoration : 'none'}}>Registrace</a></p>
                    <hr />
                    <p><a href="#book" style={{textDecoration : 'none'}}>Rezervace</a></p>
                    <hr />
                    <p><a href="#yj" style={{textDecoration : 'none'}}>Vaše cesta</a></p>
                    <hr />
                    <p><a href="#bfi" style={{textDecoration : 'none'}}>Fakturace, poplatky a pojištění</a></p>
                    <hr />
                </div>

                <div className="col-xs-12 col-md-9" style={{ textAlign: 'left' }}>
                    <br /><br />


                    <div className="accordion p-4" id="accordionFlushExample">
                        <br />
                        <p id="reg"><b>Registrace</b></p>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading1">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                                    <b>Co je Hertz 24/7?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse1" className="accordion-collapse collapse" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Hertz 24/7® vám poskytuje pohodlí a flexibilitu, jelikož máte přístup k široké škále dodávek na libovolně dlouhou nebo krátkou dobu. Můžete si vozidlo pronajmout na pouhou hodinu nebo až týden. Vyzvednout si je můžete v kteroukoli denní či noční hodinu. V ceně pronájmu je zahrnuto pojištění a údržba.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                                    <b>Jaké údaje potřebujete?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse2" className="accordion-collapse collapse" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Chcete-li se připojit ke službě Hertz 24/7, musíte poskytnout následující údaje:<br />
                                    · platný řidičský průkaz na vaše jméno<br />
                                    · Kreditní nebo debetní karta na vaše jméno<br />
                                    · Vaše mobilní telefonní číslo a e-mailovou adresu. <br />
                                    Před potvrzením registrace provedeme nezbytné kontroly totožnosti.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading3">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                                    <b>Jak se dozvím, zda byla moje registrace přijata?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse3" className="accordion-collapse collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Zašleme vám e-mail nebo textovou zprávu s potvrzením, že jsme obdrželi vaše registrační dokumenty, a po zpracování vaší žádosti vás budeme kontaktovat.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading4">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                                    <b>Co se stane, když se změní mé kontaktní údaje?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse4" className="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Stačí se přihlásit do svého profilu Hertz 24/7 v mobilní aplikaci a změnit údaje.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading5">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                                    <b>Vyprší platnost mého členství?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse5" className="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Vaše členství bude trvat 1 rok a bude automaticky obnoveno v den výročí členství. Stačí, pokud je váš řidičský průkaz platný a nadále splňujete naše požadavky na pronájem.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading6">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse6" aria-expanded="false" aria-controls="flush-collapse6">
                                    <b>Jak starý musím být, abych si mohl/a pronajmout vozidlo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse6" className="accordion-collapse collapse" aria-labelledby="flush-heading6" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Pro pronájem a řízení našich vozidel musíte být starší 19 let. Musíte také být držitelem řidičského průkazu po dobu nejméně jednoho roku a v posledních 5 letech vám nesmí být pozastaveno jeho platnost.</div>
                            </div>
                        </div>

                        <br />
                        <p id="book"><b>Rezervace</b></p>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading9">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse9" aria-expanded="false" aria-controls="flush-collapse9">
                                    <b>Co když je vozidlo, o které mám zájem, již rezervováno?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse9" className="accordion-collapse collapse" aria-labelledby="flush-heading9" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Pokud vozidlo, které si chcete rezervovat, není na vámi preferovaném místě k dispozici, vyberte si prosím jiné. Případně vyberte alternativní čas nebo datum, nebo zkontrolujte dostupnost na jiných místech v okolí.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading10">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse10" aria-expanded="false" aria-controls="flush-collapse10">
                                    <b>Jak se dozvím, zda je moje rezervace potvrzena?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse10" className="accordion-collapse collapse" aria-labelledby="flush-heading10" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Potvrzení rezervace vám zašleme e-mailem a potvrzená rezervace je také viditelná v mobilní aplikace.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading11">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse11" aria-expanded="false" aria-controls="flush-collapse11">
                                    <b>Jsou účtovány nějaké poplatky za změnu nebo zrušení rezervace?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse11" className="accordion-collapse collapse" aria-labelledby="flush-heading11" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Svou rezervaci můžete bezplatně změnit nebo zrušit nejpozději 6 hodin před vyzvednutím vozidla. Pokud je změna 6 hodin do vyzvednutí vozidla, bude vám účtován poplatek za rezervovanou dobu, maximálně však za jednu hodinu.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading12">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse12" aria-expanded="false" aria-controls="flush-collapse12">
                                    <b>Je moje platba bezpečná?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse12" className="accordion-collapse collapse" aria-labelledby="flush-heading12" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ano. Naším zpracovatelem plateb je společnost Stripe. Dodržujeme také všechny příslušné právní předpisy o ochraně osobních údajů a finančních údajů.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading13">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse13" aria-expanded="false" aria-controls="flush-collapse13">
                                    <b>Jak mi bude účtován poplatek při rezervaci pronájmu Hertz 24/7?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse13" className="accordion-collapse collapse" aria-labelledby="flush-heading13" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Předběžnou autorizaci provedeme až do výše 200 % počátečních poplatků (48) hodin před zahájením pronájmu nebo okamžitě, pokud pronájem začne z méně než 48 hodin. Rezervace bude potvrzena až po úspěšném získání předautorizace. Předautorizace bude uvolněna po vystavení faktury. Odečteme poplatky a rozdíl vrátíme. Pokud náklady na pronájem přesáhnou předautorizovanou částku, nad rámec předautorizace doúčtujeme rozdíl. Vezměte prosím na vědomí, že případné zpoždění vrácení zálohy bude způsobeno bankou, která vydala vaši platební kartu.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading14">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse14" aria-expanded="false" aria-controls="flush-collapse14">
                                    <b>Jak dlouho dopředu si mohu vozidlo rezervovat?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse14" className="accordion-collapse collapse" aria-labelledby="flush-heading14" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">V závislosti na lokaci pronájmu tři až šest měsíců.</div>
                            </div>
                        </div>

                        <br />
                        <p id="yj"><b>Vaše cesta</b></p>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading15">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse15" aria-expanded="false" aria-controls="flush-collapse15">
                                    <b>Jak odemknout vozidlo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse15" className="accordion-collapse collapse" aria-labelledby="flush-heading15" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Aplikace Hertz 24/7 bude vaším digitálním klíčem. Chcete-li odemknout vozidlo, stiskněte během pronájmu tlačítko "Odemknout" na kartě "Cesta". Poznámka: vůz budete moci odemknout až po oficiálním zahájení pronájmu, dokončení kontroly vozidla a podpisu smlouvy. Ujistěte se, že máte zapnutý **Bluetooth** a nacházíte se ve vzdálenosti do 5 metrů od vozidla. **Pokud se vám v systému iOS (&gt; 13) zobrazí chyba aktivace Bluetooth, přestože máte Bluetooth zapnuté, přejděte do aplikace "Nastavení" a poté do sekce HERTZ 24/7 a povolte jej.možnost Bluetooth**</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading16">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse16" aria-expanded="false" aria-controls="flush-collapse16">
                                    <b>Kde najdu klíče?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse16" className="accordion-collapse collapse" aria-labelledby="flush-heading16" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Klíče najdete uvnitř vozidla v přihrádce u spolujezdce.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading17">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse17" aria-expanded="false" aria-controls="flush-collapse17">
                                    <b>Jak mohu vozidlo nastartovat?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse17" className="accordion-collapse collapse" aria-labelledby="flush-heading17" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">U vozidel se zámkem zapalování najdete klíč od zapalování v přihrádce nebo na řetízku u volantu. Sešlápněte brzdový pedál (u manuální převodovky brzdový a spojkový pedál) a stiskněte startovací tlačítko nebo klíčkem nastartujte motor. Pokud vozidlo nenastartuje, může být zapnutý imobilizér. V aplikaci Hertz 24/7 na obrazovce "Cesta" stiskněte tlačítko Power, čímž imobilizér uvolníte. Vozidlo by nyní mělo nastartovat. POZNÁMKA: Vozidlo TESLA a POLESTAR nemá samostatné tlačítko pro spuštění.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading18">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse18" aria-expanded="false" aria-controls="flush-collapse18">
                                    <b>Co když vozidlo nenastartuje?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse18" className="accordion-collapse collapse" aria-labelledby="flush-heading18" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Vozidlo je vybaveno bezpečnostní funkcí, která zapne imobilizér, pokud je zapalování vypnuté déle než 2 minuty . Chcete-li znovu nastartovat vozidlo, pokud je aktivovaný imobilizér, použijte aplikaci k vypnutí imobilizéru stisknutím ikony "power" na obrazovce "Cesta" (ikona mezi ikonami zamykání/odemykání). Pokud vozidlo stále nestartuje, obraťte se na náš tým Centra péče o členy. Ti buď problém vyřeší na dálku nebo vyhledají v okolí jiné vozidlo, které můžete použít.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading19">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse19" aria-expanded="false" aria-controls="flush-collapse19">
                                    <b>Jak najdu vozidlo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse19" className="accordion-collapse collapse" aria-labelledby="flush-heading19" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Potvrzení rezervace obsahuje informace o tom, kde vozidlo najdete. Tyto informace jsou vám k dispozici také online prostřednictvím aplikace. Před rezervací vozidla si prosím ověřte otevírací dobu místa vyzvednutí a případná omezení na parkovišti.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading20">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse20" aria-expanded="false" aria-controls="flush-collapse20">
                                    <b>Co když vozidlo, které jsem si rezervoval, není k dispozici?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse20" className="accordion-collapse collapse" aria-labelledby="flush-heading20" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Nezapomeňte zkontrolovat bezprostřední okolí. Pokud stále vozidlo nemůžete najít, obraťte se na naše středisko péče o členy. Telefonní číslo naleznete v mobilní aplikaci.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading21">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse21" aria-expanded="false" aria-controls="flush-collapse21">
                                    <b>Co se stane, když vozidlo vrátím pozdě?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse21" className="accordion-collapse collapse" aria-labelledby="flush-heading21" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Pokud překročíte přidělený časový interval, aniž byste si pronájem předem prodloužili, bude vám účtováno vyšší hodinovou sazbou za čas, kdy vozidlo používáte, která se liší podle vozidla a místa. Bude vám také účtován standardní poplatek za zpoždění.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading22">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse22" aria-expanded="false" aria-controls="flush-collapse22">
                                    <b>Vadí, když si vozidlo vyzvednu později?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse22" className="accordion-collapse collapse" aria-labelledby="flush-heading22" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>Na každý pronájem je stanovena doba 6 hodin, během níž si můžete vozidlo vyzvednout. Po uplynutí 6 hodin se pronájem automaticky zruší, avšak bude vám účtována plná částka za pronájem až na 1 den.</p>
                                    <p>Pokud nestihnete začátek pronájmu do hodiny, můžete si pronájem kdykoli upravit*.<br />Rezervaci pronájmu můžete také zrušit až 6 hodin před začátkem bez poplatku. Pokud však rezervaci zrušíte blíže než 6 hodin před začátkem pronájmu, bude vám účtována plná cena pronájmu až do 1 dne.</p>
                                    <p>*V závislosti na dostupnosti vozidla.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading23">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse23" aria-expanded="false" aria-controls="flush-collapse23">
                                    <b>Kdo platí náklady na palivo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse23" className="accordion-collapse collapse" aria-labelledby="flush-heading23" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Pohonné hmoty se účtují za ujeté kilometry, které jsou uvedeny při rezervaci. Všechna vozidla by měla být vybavena palivovou kartou v přihrádce u spolujezdce a na začátku pronájmu by měla mít alespoň čtvrt nádrže paliva. Tímto způsobem byste měli vozidlo také vrátit. Pokud palivová karta na začátku pronájmu chybí, kontaktujte nás na telefonním čísle uvedeném v části "Nápověda" v aplikaci. Palivové karty lze používat na jakékoliv čerpací stanici a při platbě za palivo bude vyžadován pin kód zobrazený v aplikaci.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading24">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse24" aria-expanded="false" aria-controls="flush-collapse24">
                                    <b>Co když je palivová nádrž vozidla prázdná nebo je plná méně než ze čtvrtiny?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse24" className="accordion-collapse collapse" aria-labelledby="flush-heading24" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Pokud je nádrž plná méně než ze čtvrtiny, dejte nám prosím vědět a doplňte palivo pomocí poskytnuté palivové karty. Palivovou kartu najdete ve schránce před spolujezdcem a PIN kód k palivové kartě v mobilní aplikaci. Pokud ve vozidle není vůbec žádné palivo nebo pokud jste nemohli najít palivovou kartu, obraťte se na naši službu péče o členy.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading25">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse25" aria-expanded="false" aria-controls="flush-collapse25">
                                    <b>Co mám dělat, když jsem překročil limit palivové karty nebo zaplatil za palivo bez karty?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse25" className="accordion-collapse collapse" aria-labelledby="flush-heading25" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Při tankování dbejte na to, abyste nepřekročili limit uvedený na palivové kartě. Pokud limit překročíte, budete muset palivo zaplatit sami. Aby vám byla vrácena částka za palivo, které jste si zaplatili sami, je třeba zaslat e-mailem účtenku do centra péče o členy Hertz 24/7. Vezměte prosím na vědomí, že vyřízení této žádosti může trvat několik dní.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading26">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse26" aria-expanded="false" aria-controls="flush-collapse26">
                                    <b>Mohu ve vozidle kouřit?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse26" className="accordion-collapse collapse" aria-labelledby="flush-heading26" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ne. V žádném vozidle Hertz 24/7 nekuřte. Naše vozidla jsou klasifikována jako přísně nekuřácká. Je to součást naší snahy poskytovat bezpečný a čistý vozový park pro všechny naše zaměstnance a zákazníky. Děkujeme vám za vaši ohleduplnost. Pokud během doby pronájmu zjistíme ve vozidle stopy po kouření, bude k vaší faktuře připočten poplatek za úklid.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading27">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse27" aria-expanded="false" aria-controls="flush-collapse27">
                                    <b>Co se stane, když dostanu pokutu za překročení rychlosti nebo za parkování?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse27" className="accordion-collapse collapse" aria-labelledby="flush-heading27" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Jak byste očekávali, pokuty za mýtné, překročení rychlosti, parkování jsou vaší povinností zaplatit.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading28">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse28" aria-expanded="false" aria-controls="flush-collapse28">
                                    <b>Musím platit nějaké poplatky za dopravní zácpy?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse28" className="accordion-collapse collapse" aria-labelledby="flush-heading28" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ne, o to už jsme se postarali.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading29">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse29" aria-expanded="false" aria-controls="flush-collapse29">
                                    <b>Co když ve vozidle něco najdu nebo tam něco zapomenu?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse29" className="accordion-collapse collapse" aria-labelledby="flush-heading29" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Kontaktujte Servisní centrum a my se o to postaráme.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading30">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse30" aria-expanded="false" aria-controls="flush-collapse30">
                                    <b>Poskytujete dětské sedačky?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse30" className="accordion-collapse collapse" aria-labelledby="flush-heading30" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ne, ale můžete použít vlastní.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading31">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse31" aria-expanded="false" aria-controls="flush-collapse31">
                                    <b>Mohu ve vozidle přepravovat domácí zvířata?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse31" className="accordion-collapse collapse" aria-labelledby="flush-heading31" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ne. Domácí zvířata nejsou v našich vozidlech povolena - s výjimkou asistenčních psů.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading32">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse32" aria-expanded="false" aria-controls="flush-collapse32">
                                    <b>Musím vozidlo při vrácení vyčistit?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse32" className="accordion-collapse collapse" aria-labelledby="flush-heading32" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ano. Ve prospěch dalšího zákazníka se ujistěte, že na konci pronájmu je vozidlo uvnitř čisté.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading33">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse33" aria-expanded="false" aria-controls="flush-collapse33">
                                    <b>Co když vozidlo není čisté zvenku ani zevnitř?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse33" className="accordion-collapse collapse" aria-labelledby="flush-heading33" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Žádáme naše zákazníky, aby na konci každého pronájmu nechali vozidlo čisté a uklizené. Pravidelně také udržujeme náš vozový park. Pokud však nejste spokojeni se stavem vozidla, dejte nám prosím vědět.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading34">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse34" aria-expanded="false" aria-controls="flush-collapse34">
                                    <b>Mohu vozidlo vrátit dříve?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse34" className="accordion-collapse collapse" aria-labelledby="flush-heading34" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ano. Vozidlo můžete vrátit kdykoli během doby pronájmu. Bude vám však stále účtován poplatek za celou dobu rezervace.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading35">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse35" aria-expanded="false" aria-controls="flush-collapse35">
                                    <b>Jak si mohu prodloužit pronájem?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse35" className="accordion-collapse collapse" aria-labelledby="flush-heading35" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">V závislosti na dostupnosti vozidel si můžete pronájem snadno prodloužit prostřednictvím aplikace.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading36">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse36" aria-expanded="false" aria-controls="flush-collapse36">
                                    <b>Kde mám vozidlo vrátit?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse36" className="accordion-collapse collapse" aria-labelledby="flush-heading36" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Vraťte prosím vozidlo na vyhrazené parkovací místo společnosti Hertz, které je v místě k dispozici 24 hodin denně, 7 dní v týdnu.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading37">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse37" aria-expanded="false" aria-controls="flush-collapse37">
                                    <b>Co když je moje vyhrazené parkovací místo již obsazené?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse37" className="accordion-collapse collapse" aria-labelledby="flush-heading37" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Vozidlo zaparkujte na parkovacím místě, které je nejblíže parkovacím místům označeným "Hertz".</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading38">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse38" aria-expanded="false" aria-controls="flush-collapse38">
                                    <b>Co když zákazník přede mnou vrátí rezervované vozidlo se zpožděním?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse38" className="accordion-collapse collapse" aria-labelledby="flush-heading38" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Vždy se vás pokusíme včas kontaktovat a dát vám vědět. Pokud se vám však neozveme, kontaktujte nás co nejdříve a my vám zajistíme náhradní vozidlo.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading39">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse39" aria-expanded="false" aria-controls="flush-collapse39">
                                    <b>Mám vůbec používat klíče k zamykání a odemykání vozidla?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse39" className="accordion-collapse collapse" aria-labelledby="flush-heading39" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Nikoliv. To by mohlo způsobit problémy během pronájmu. Klíče musí být po celou dobu ponechány uvnitř vozidla. K zamykání a odemykání vozidla během pronájmu použijte aplikaci Hertz 24/7. POZNÁMKA: Během nečinnosti se může zapnout imobilizér vozidla. Chcete-li jej odemknout, stiskněte prosím tlačítko "power" na obrazovce "Cesta" v mobilní aplikaci.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading40">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse40" aria-expanded="false" aria-controls="flush-collapse40">
                                    <b>Jak jsou vozidla udržována?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse40" className="accordion-collapse collapse" aria-labelledby="flush-heading40" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">O náš vozový park Hertz se stará tým profesionálů, kteří se o něj starají 24 hodin denně, 7 dní v týdnu. Kromě obvyklé plánované údržby provádíme u každého vozidla měsíční 40bodovou kontrolu kvality.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading41">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse41" aria-expanded="false" aria-controls="flush-collapse41">
                                    <b>Co mám dělat, když se vozidlo porouchá?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse41" className="accordion-collapse collapse" aria-labelledby="flush-heading41" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Obraťte se na náš tým zákaznické podpory. Naše telefonní číslo najdete na stránce kontaktů v aplikaci. Global Assistance lze také kontaktovat přímo na telefonním čísle (0)800 443 789.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading42">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse42" aria-expanded="false" aria-controls="flush-collapse42">
                                    <b>Co mám dělat, když se stanu součástí dopravní nehody?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse42" className="accordion-collapse collapse" aria-labelledby="flush-heading42" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Neprodleně kontaktujte tým Hertz 24/7 pomocí kontaktních údajů v sekci "nápověda" v mobilní aplikaci. Vaší povinností je vyplnit záznam o dopravní nehodě, který naleznete v dokladech pronajatého vozidla. Neohlášení jakékoli události může mít za následek neplatnost vašeho krytí.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading43">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse43" aria-expanded="false" aria-controls="flush-collapse43">
                                    <b>Co mám dělat v případě odcizení vozidla?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse43" className="accordion-collapse collapse" aria-labelledby="flush-heading43" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">V případě odcizení vozidla nás neprodleně telefonicky kontakujte. Telefonní číslo najdete v sekci kontakty.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading44">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse44" aria-expanded="false" aria-controls="flush-collapse44">
                                    <b>Mohu využít službu Hertz 24/7 v jiných zemích?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse44" className="accordion-collapse collapse" aria-labelledby="flush-heading44" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ano. Pokud jste u nás zaregistrováni, můžete v současné době využívat službu Hertz 24 hodin denně, 7 dní v týdnu na jakémkoli místě v České republice. Spojeném království, Francii, Německa, Belgie a Portugalska.</div>
                            </div>
                        </div>

                        <br />
                        <p id="bfi"><b>Fakturace, poplatky a pojištění</b></p>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading45">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse45" aria-expanded="false" aria-controls="flush-collapse45">
                                    <b>Platím poplatek, pokud je vozidlo odtaženo nebo zajištěno?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse45" className="accordion-collapse collapse" aria-labelledby="flush-heading45" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Pokutu nejprve zaplatíme my a poté ji přefakturujeme na vás včetně administrativního poplatku.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading46">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse46" aria-expanded="false" aria-controls="flush-collapse46">
                                    <b>Budu muset zaplatit poplatek za pozdní vrácení, pokud se vozidlo porouchá?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse46" className="accordion-collapse collapse" aria-labelledby="flush-heading46" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ne. V případě poruchy vozidla nebudete muset platit žádné další poplatky (v případě nazaviněné poruchy vozidla). Součástí rezervace je 24/7 nouzová silniční asistence Global Assistance.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading47">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse47" aria-expanded="false" aria-controls="flush-collapse47">
                                    <b>Je třeba počítat s nějakými dalšími poplatky?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse47" className="accordion-collapse collapse" aria-labelledby="flush-heading47" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ano, na každou rezervaci se vztahuje sazba za ujeté kilometry.* Pokud vozidlo poškodíte, musíte uhradit škodu do výše spoluúčasti. Hrubá nedbalost (např. poškození střechy, znečistění Ad-blue nádrže nebo špatně natankované palivo) a zneužití vozidla není kryto proti škodám.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading48">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse48" aria-expanded="false" aria-controls="flush-collapse48">
                                    <b>Pokryje moje osobní pojištění vozidla poplatek za škodu?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse48" className="accordion-collapse collapse" aria-labelledby="flush-heading48" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Ne. Vaše osobní pojištění vozidla neplatí pro vozidla Hertz 24/7. Na naše vozidla se vztahuje pouze snížení odpovědnosti CDW nebo SC, které si můžete přidat v souvislosti s vaší rezervací.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading49">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse49" aria-expanded="false" aria-controls="flush-collapse49">
                                    <b>Musím platit nějakou spoluúčast?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse49" className="accordion-collapse collapse" aria-labelledby="flush-heading49" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">V případě poškození, ztráty nebo odcizení vozidla jste povinni uhradit spoluúčast. Výše spoluúčasti je odvislá od kategorie pronajatého vozidla.Pokud je oprava nižší než spoluúčast, vrátíme vám rozdíl po odečtení administrativního poplatku.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading50">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse50" aria-expanded="false" aria-controls="flush-collapse50">
                                    <b>Na co se vztahuje krytí v rámci mé rezervace?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse50" className="accordion-collapse collapse" aria-labelledby="flush-heading50" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Krytí se vztahuje na škody na vozidle nad rámec spoluúčasti. Spoluúčast je maximální částka, kterou jste povinni zaplatit v případě pojistné události. Za předpokladu, že dodržíte podmínky smlouvy o pronájmu, budete také chráněni pojištěním odpovědnosti za škodu způsobenou třetím osobám, které je standardně součástí pojištění a je vyžadováno zákonem. To vás kryje za škody způsobené třetím stranám a zranění osob v případě nehody.Pokud se jedná o totální škodu, poškození nebo krádež vozidla způsobenou úmyslně. Pokud do vozidla nalijete nesprávné palivo, kontaminujete palivovou nádrž nebo kontaminujete nádrž Ad blue, všechna taková poškození budou kvalifikována jako hrubá nedbalost a budete odpovědní za náklady na opravu. Pokud je vozidlo odcizeno, bude vám účtována pouze spoluúčast.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading51">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse51" aria-expanded="false" aria-controls="flush-collapse51">
                                    <b>Mohu se spolehnout, že případné škody pokryje pojištění poskytovatele kreditní karty?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse51" className="accordion-collapse collapse" aria-labelledby="flush-heading51" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Před provedením rezervace Hertz 24/7 se raději poraďte s poskytovatelem své kreditní karty, protožerůzné kreditní karty mají různé systémy pojistné plány.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row shadow-lg p-3 mb-5 bg-body rounded" >
                <a href='https:/l.ead.me/beWm77'><img className="img-fluid rounded-4" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/App_Banner-new-format%20CZ.png" alt='' style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} /></a>
            </div>


        </div>


    </>
    );
}

export default FAQBody;