import React from 'react';

function HomeBody() {
    return (<>
        <div className="container-fluid p-4 body-font">
            <div className="row">
                <div className="col-xs-12 col-md-6 py-md-5">
                    <p className="h6"><b>Carsharing leicht gemacht</b></p>
                    <p className="h1"><b>BUCHEN. EINSTEIGEN.</b><font className="text-warning"><b>FAHREN!</b></font></p>
                    <p className="h6">Wir haben die Anmietung mit Hertz24/7® so einfach und schnell gemacht, <br />dass Sie innerhalb von 15 Minuten in Ihrem Fahrzeug sitzen können.</p>
                    <div className="row">
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }} >
                            <div className="row">
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }} >
                                    <a href='https://apps.apple.com/de/app/hertz-24-7-mobility/id6453605078'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} style={{  backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }} >
                                    <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=de_de&gl=de'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} style={{  backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-6">
                        </div> 
                    </div>
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Mercedes_24sprinter_top.png" alt='' />
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <h1 className="display-5"><b>Wie es funktioniert</b></h1>
                    <br/>
                    <p className="h6">
                    Wir haben die Anmietung mit Hertz24/7® so einfach und schnell gemacht, dass Sie innerhalb von 15 Minuten in Ihrem Fahrzeug sitzen können. Und so funktioniert es.
                    </p>
                    <br/>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_large.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>01</b></p><br/>
                    <p className="h2">Die App herunterladen</p>
                    <p>Sie können die Hertz 24/7 App schnell und einfach von Ihrem App-Store herunterladen, indem Sie den QR-Code scannen.</p>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Register.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>02</b></p><br/>
                    <p className="h2">Registrierung</p>
                    <p>Erstellen Sie einfach Ihr Mitgliedschaftsprofil und senden Ihre Dokumente zur Überprüfung mit der In-App-Fotofunktion.</p>
                    
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Select_Location_DE.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>03</b></p><br/>
                    <p className="h2">Buchen Sie Ihr Fahrzeug</p>
                    <p>Melden Sie sich in der App an und buchen Sie Ihr Fahrzeug, indem Sie den gewünschten Ort und die Abholzeit auswählen. Sie benötigen eine Kredit-/Debitkarte oder eine PayPal Konto.</p>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Unlock.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>04</b></p><br/>
                    <p className="h2">Start Miete</p>
                    <p>Kurz vor Beginn Ihrer Anmietung wird Ihnen das Fahrzeug zugewiesen. Bei der Abholung erhalten Sie den digitalen Schlüssel in der App, um das Fahrzeug zu entriegeln.</p>
                </div>
            </div>

            <div className="row" style={{ backgroundColor: '#F3F3F3' }}>
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br/>
                    <h1 className="display-5"><b>Unsere Flotte</b></h1>
                    <br/>
                    <p className="h6">
                        Hertz 24/7® hat für jede Gelegenheit das richtige Fahrzeug. Wählen Sie einfach das passende Fahrzeug und schon sind Sie auf der Straße. 
                    </p>
                    <br/>
                    <div className="row">
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'center'}}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/FordTransitLWB%20.png" alt='' />
                            <br />  <br />
                            <div className="row">
                                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                                </div>
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'center' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <p className="h2">Ford Transit L3H2</p>
                                        <p className="h6">oder ähnlich</p>
                                        <span>
                                            <ul>
                                                <li>Türen:4</li>
                                                <li>Laderaum:2,1 x 1,8 x 3,3 m (H x B x L)</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                                </div>
                            </div>



                        </div>
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/FordCustomSWB.png" alt='' />
                            <br />  <br />
                            <div className="row">
                                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                                </div>
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'center' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <p className="h2">Ford Custom</p>
                                        <p className="h6">oder ähnlich</p>
                                        <span>
                                            <ul>
                                                <li>Türen: 5</li>
                                                <li>Laderaum:  1,50 x 1,70 x 2,60 m (H x B x L)</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Mobile_DE.png" alt='' />
                </div>
                <div className="col-xs-12 col-md-6 py-md-5" style={{ textAlign: 'center' }}>
                    <h1 className="display-5"><b>Unsere Standorte</b></h1>
                    <p>Mit über 700 Standorten in ganz Europa, ist ein Hertz 24/7® Fahrzeug näher als Sie denken.</p>
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Vereinigtes Königreich (300 Standorte)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600020009&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Manchester</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902024006&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>London</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Frankreich (20 Standorte)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-fr19100400001005&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Paris</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-fr19110500007004&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Marseilles</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Deutschland (280 Standorte)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-de18112800001004&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Berlin</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-de15102900001000&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Frankfurt</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Italien (75 Standorte)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-it17072800007000&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Rome</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-it17072800005002&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Milan</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Portugal (39 Standorte)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-pt21051200001008&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Lisbon</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-pt17062200003001&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Porto</a></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12" style={{ textAlign: 'left' }}>
                            <b><u>Erkunden Sie Standorte in Ihrer Nähe&gt;</u></b><br/><br/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }}>
                            <div className="row">
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }}>
                                    <a href='https://apps.apple.com/de/app/hertz-24-7-mobility/id6453605078'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }}>
                                    <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=de_de&gl=de'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }}>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-4" style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/Transit_city.png)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1" >
                    </div>
                    <div className="col-xs-12 col-md-3" >
                        <br />
                        <br />
                        <br />
                        <span>
                            <h1 className="display-4"><b>Warum sollten Sie Hertz 24/7® wählen?</b></h1>
                        </span>
                        <p>
                            Mit Hertz 24/7® können Sie Fahrzeuge zu einem günstigen Preis anmieten, und zwar zu Stunden- und Tagessätzen je nach Fahrzeugtyp und Standort. Ihr voraussichtlicher Mietpreis wird zum Zeitpunkt der Buchung* angezeigt.</p>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-8" >
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center', backgroundColor: '#fff7cc' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Contact.png" alt='' />
                    <p className="h4">Kundenbetreuung</p>
                    <p>Wir sind während unserer Öffnungszeiten persönlich für Sie da, falss es Probleme mit Ihrer Anmietung oder dem Fahrzeug gibt. </p>
                </div>
                <div className="col-xs-12 col-md-4 " style={{ textAlign: 'center', backgroundColor: '#fff099' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Price.png" alt='' />
                    <p className="h4">Einfache Preisgestaltung</p>
                    <p>Buchen Sie Ihr Fahrzeug zu Stunden- oder Tagessätzen. Nutzen Sie Mitgliedernummern unserer Partner für ermäßigte Angebote.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center', backgroundColor: '#ffe866' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Location.png" alt='' />
                    <p className="h4">Immer in der Nähe</p>
                    <p>Mit über 700 Standorten in ganz Europa ist immer ein Fahrzeug in der Nähe. </p>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br />
                    <span><h6>*Die Kilometertarife können je nach ausgewähltem Fahrzeugtyp variieren. </h6></span>
                    <span><h6>Überprüfen Sie immer die geltenden Kilometerpreise zum Zeitpunkt Ihrer Buchung.</h6></span>
                </div>
            </div>
        </div>
    </>);
}
export default HomeBody;