import ReactDom from 'react-dom/client';
import Main from './Main'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';



const root = ReactDom.createRoot(document.getElementById('root'));

root.render(
 
   <Main />


);