import React from 'react';


function HomeBody() {

    return (<>
       
        <div className="container-fluid p-4 body-font"  >
            <div className="row" >
                <div className="col-xs-12 col-md-6 py-md-5">
                    <p className="h6"><b>Car sharing made easy</b></p>
                    <p className="h1"><b>BOOK. UNLOCK.</b> <font className="text-warning"><b>GO!</b></font></p>
                    <p className="h6">Convenient hourly or daily rentals whenever you need them. There is no <br />counter, just step into your vehicle within 15 minutes of registration.</p>
                    <div className="row">
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }} >
                            <div className="row">
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left'}} >
                                    <a href='https://apps.apple.com/gb/app/hertz-24-7-mobility/id6453605078'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250}  style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }} >
                                    <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=en_GB&gl=GB'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }}>
                        </div>

                    </div>
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Mercedes_24sprinter_top.png" alt='' />
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <h1 className="display-5"><b>How it works</b></h1>
                    <br />
                    <p className="h6">
                        We’ve made renting with Hertz24/7®  so easy and fast that you can be in your vehicle within 15 minutes. Here’s how we do it.
                    </p>
                    <br />
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_large.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>01</b></p><br />
                    <p className="h2">Download the app</p>
                    <p>It's quick and easy to download the Hertz 24/7 app from your favourite app store, just scan the QR code.</p>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Register.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>02</b></p><br />
                    <p className="h2">Register</p>
                    <p>Simply create your membership profile  and submit your documents for verification with the in-app photo function.</p>
                    <p>A £5 annual membership fee applies.</p>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Select_Location_UK.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>03</b></p><br />
                    <p className="h2">Book your vehicle</p>
                    <p>Log into the app and book your van by selecting your desired location and pickup time. You’ll need a credit/debit card.</p>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Unlock.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>04</b></p><br />
                    <p className="h2">Start rental</p>
                    <p>Close to the start of your rental you vehicle will be assigned. Upon pickup you’ll receive the digital  key in the app to unlock the vehicle</p>
                </div>
            </div>

            <div className="row" style={{ backgroundColor: '#F3F3F3' }}>
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br />
                    <h1 className="display-5"><b>Our Fleet</b></h1>
                    <br />
                    <p className="h6" style={{ textAlign: 'center' }}>
                        Hertz 24/7® has the perfect car or van for every occasion. Simply select the vehicle that you wish to drive and get on the road.
                    </p>
                    <br />
                    <div className="row">
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/FordTransitLWB%20.png" alt='' />
                            <br />  <br />
                            <div className="row">
                                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                                </div>
                                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <p className="h2">Ford Transit LWB</p>
                                        <p className="h6">or similar</p>
                                        <span>
                                            <ul>
                                                <li>Doors:4</li>
                                                <li>Space:2.1 x 1.8 x 3.3m(H x W x L)</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/FordCustomSWB.png" alt='' />
                            <br />  <br />
                            <div className="row">
                                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                                </div>
                                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <p className="h2">Ford Custom SWB</p>
                                        <p className="h6">or similar</p>
                                        <span>
                                            <ul>
                                                <li>Doors:5</li>
                                                <li>Space:1.5 x 1.7 x 2.6m(H x W x L)</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/VauxhallCombo.png" alt='' />
                            <br />  <br />
                            <div className="row">
                                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                                </div>
                                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <p className="h2">Vauxhall Combo</p>
                                        <p className="h6">or similar</p>
                                        <span>
                                            <ul>
                                                <li>Doors:3</li>
                                                <li>Space:1.2 x 1.2 x 1.5 (H x W x L)</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Mobile_UK.png" alt='' style={{ height: '100%' }} />
                </div>
                <div className="col-xs-12 col-md-6 py-md-5" style={{ textAlign: 'center' }}>
                    <h1 className="display-5"><b>Our Locations</b></h1>
                    <p>With over 700 locations across Europe,a Hertz 24/7® car or van  is closer than you think.</p>
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>United Kingdom (300 Locations)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600020009&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Manchester</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902024006&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>London</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>France (20 Locations)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-fr19100400001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Paris</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-fr19110500007004&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Marseilles</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Germany (280 Locations)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-de18112800001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Berlin</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-de15102900001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Frankfurt</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Italy (75 Locations)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-it17072800007000&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Rome</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-it17072800005002&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Milan</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Portugal (39 Locations)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-pt21051200001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Lisbon</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-pt17062200003001&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Porto</a></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12" style={{ textAlign: 'left' }}>
                            <b><u>Explore locations near you</u></b><br /><br />
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }}>
                            <div className="row">
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }}>
                                    <a href='https://apps.apple.com/gb/app/hertz-24-7-mobility/id6453605078'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }}>
                                    <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=en_GB&gl=GB'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                            </div>

                        </div>

                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                   
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-4" style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="row img-fluid p-4" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/Transit_city.png)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1" >
                    </div>
                    <div className="col-xs-12 col-md-3" >
                        <br />
                        <br />
                        <br />
                        <span>
                            <h1 className="display-4"><b>Why choose Hertz 24/7®  ?</b></h1>
                        </span>
                        <p>With Hertz 24/7®  you can rent vehicles for a competitive price at hourly and daily rates depending on the vehicle type and location. Your estimated rental price will be displayed at the time of booking.*</p>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-8" >
                    </div>
                </div>
            </div>

            <div className="row" >
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center', backgroundColor: '#fff7cc' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Contact.png" alt='' />
                    <p className="h4">Customer Support</p>
                    <p>We are here for you in case there are any issues with your rental or vehicle.Opening hours apply</p>
                </div>
                <div className="col-xs-12 col-md-4 " style={{ textAlign: 'center', backgroundColor: '#fff099' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Price.png" alt='' />
                    <p className="h4">Simple Pricing</p>
                    <p>Book vans at hourly or daily rates. Apply loyalty membership cards for discounted offers.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center', backgroundColor: '#ffe866' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Location.png" alt='' />
                    <p className="h4">Always Nearby</p>
                    <p>With over 700 locations across UK and Europe there is always a vehicle nearby. </p>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br />
                    <span><h6>*Mileage Rates may vary depending on the vehicle type selected and are subject to change in accordance with the current fuel prices.</h6></span>
                    <span><h6>Always ensure that you check the applicable Milage Rates on the date of your booking.</h6></span>
                </div>
            </div>
        </div>
    </>);
}
export default HomeBody;