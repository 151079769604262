import React from "react";
import Nav from "./Components/Nav";
import BodyLHHowitWorks from "./Components/bodylhhowitworks";
import Footer from "./Components/Footer";



class LHCarpoolDEHowitWorks extends React.Component {
    render() {
        return (<>
            <Nav />
            <BodyLHHowitWorks/>
            <Footer />
        </>
        );

    }
}

export default LHCarpoolDEHowitWorks;