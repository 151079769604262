import React from 'react';
import Map from './Map';
import { useJsApiLoader } from '@react-google-maps/api';

function BodyLocations() {

    const strGMK = String.fromCodePoint(65, 73, 122, 97, 83, 121, 65, 73, 118, 57, 113, 78, 88, 72, 105, 115, 79, 72, 88, 113, 50, 103, 106, 69, 120, 56, 120, 85, 81, 107, 121, 102, 122, 121, 74, 87, 100, 113, 115);

    const { isLoaded } = useJsApiLoader({
        id: strGMK,
        googleMapsApiKey: strGMK
      });
    return (<>
        <div className="container-fluid body-font">
            <div className="row">
                <div className="col-xs-12 col-md-6 py-md-5">
                    <p className="h6"><b>Localisation des sites</b></p>
                    <p className="h1"><b>Toujours à proximité</b></p>
                    <p>Avec plus de 700 sites en Europe et au Royaume-Uni, il y a toujours un véhicule Hertz 24/7® à proximité. Trouvez le véhicule le plus proche grâce à cette carte. </p>
                    
                </div>
            </div>
            <Map isLoaded={isLoaded}/>
            <div className="row">
                <div className="col-xs-12 py-md-5" style={{ textAlign: 'left' }}>
                    <h1 className="display-5"><b>Nos implantations</b></h1>
                    <p>Avec plus de 700 sites à travers l'Europe, une voiture ou un utilitaire Hertz 24/7® est plus proche que vous ne le pensez.</p>
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Royaume-Uni (300 Localisation des sites)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600020009&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Manchester</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902024006&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>London</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>France (20 Localisation des sites)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-fr19100400001005&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Paris</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-fr19110500007004&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Marseilles</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Allemagne (280 Localisation des sites)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-de18112800001004&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Berlin</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-de15102900001000&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Frankfurt</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Italie (75 Localisation des sites)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-it17072800007000&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Rome</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-it17072800005002&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Milan</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Portugal (39 Localisation des sites)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-pt21051200001008&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Lisbon</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-pt17062200003001&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Porto</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row shadow-lg p-3 mb-5 bg-body rounded" >
                <a href='https:/l.ead.me/beWm77'><img className="img-fluid rounded-4" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/LocationBannerFR.png" alt='' style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} /></a>
            </div> 
        </div>
    </>
    );
}
export default BodyLocations;