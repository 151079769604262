import React from 'react';
import { NavLink } from 'react-router-dom';
import Cookiebanner from './cookiebanner';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

function Footer() {

    const [mcshow, setmcShow] = useState(false);
    const [switchState, setSwitchState] = useState(true);
    const [isSwitchOn, setIsSwitchOn] = useState(false);


    const onSwitchAction = () => {

        if (localStorage.getItem('Performance_cookie') === 'false') {
            setIsSwitchOn(true);
        }

        else
            setIsSwitchOn(false);

    };

    const handleSave = () => {
        localStorage.setItem('Performance_cookie', isSwitchOn);
        setSwitchState(true);
        setmcShow(false);
    };

    const handleClose = () => setmcShow(false);

    const GoToTop = () =>{
        window.scrollTo({top : 0, left : 0, behavior : "smooth"});
    };
   

    return (<>
        
        <Modal
            show={mcshow}
            backdrop="static"
            keyboard={false}
            centered
            size='xl'
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title id="managecookies-custom-modal-styling-title">
                    <img src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz_247_logo.png" className="img-fluid" alt="Hertz24x7" width={200} /> &nbsp; Gerir Cookies
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                Quando você visita qualquer site, ele pode armazenar ou recuperar informações em seu navegador, principalmente na forma de cookies. Essas informações podem ser sobre você, suas preferências ou seu dispositivo e são usadas principalmente para fazer o site funcionar como você espera. As informações geralmente não o identificam diretamente, mas podem dar-lhe uma experiência web mais personalizada. Uma vez que respeitamos o seu direito à privacidade, pode optar por não permitir alguns tipos de cookies. Clique nos diferentes títulos de categoria para saber mais e alterar as nossas predefinições.
                <Form>
                    <div className="accordion p-4" id="accordionMgCookie">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-mgc_echeading">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgc_eccollapse" aria-expanded="false" aria-controls="flush-mgc_eccollapse">
                                    <b> <Form.Check
                                        disabled
                                        type="switch"
                                        label="Essencial"
                                        id="disabled-custom-switch"
                                        defaultChecked={switchState}
                                    />
                                    </b>
                                </button>
                            </h2>
                            <div id="flush-mgc_eccollapse" className="accordion-collapse collapse" aria-labelledby="flush-mgc_echeading" data-bs-parent="#accordionMgCookie">
                                Alguns cookies são vitais para o funcionamento de nossos sites. Sem eles, você não seria capaz de navegar pelo site e usar alguns de seus recursos. Os cookies essenciais permitem que as informações que você fornece e as decisões que você toma em relação ao seu aluguel sejam lembradas de página em página no processo de reserva. Como esses cookies são essenciais, não permitimos que você opte por desativá-los em nosso site
                                <div className="accordion-body">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Cookie Name</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>dGroup</td>
                                                <td>Lembra de qual dispositivo o site está sendo acessado (PC, tablet, telefone) para exibir o conteúdo apropriado.</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>incap_ses_*</td>
                                                <td>Usado para vincular solicitações HTTP a uma sessão.</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>nlbi_*</td>
                                                <td>Este cookie é usado para filtrar solicitações maliciosas. As informações geradas não podem identificá-lo como indivíduo.</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>visid_incap_*</td>
                                                <td>Usado para verificar se o usuário aceita cookies e não é um robô.</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>DC-Cookie</td>
                                                <td>Usado para encaminhá-lo para o servidor correto durante toda a sua visita.</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-mgcheading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgccollapse2" aria-expanded="false" aria-controls="flush-mgccollapse2">
                                    <b>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Cookies de desempenho"
                                            onChange={onSwitchAction}
                                            defaultChecked={isSwitchOn}
                                        />
                                    </b>
                                </button>
                            </h2>
                            <div id="flush-mgccollapse2" className="accordion-collapse collapse" aria-labelledby="flush-mgcheading2" data-bs-parent="#accordionMgCookie">
                                Usamos cookies de desempenho para monitorar o desempenho do site e analisar como nossos visitantes usam nossos sites. Isso nos permite fornecer uma experiência de alta qualidade, identificando e corrigindo rapidamente quaisquer problemas que surjam e personalizando nossa oferta. Todas as informações recolhidas são agregadas e, portanto, anónimas e são apenas utilizadas para melhorar o funcionamento do nosso website.
                                <div className="accordion-body">NA</div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={handleSave}>
                    Guardar alterações
                </Button>

            </Modal.Footer>
        </Modal>

        <Cookiebanner isVisible={localStorage.getItem('Cookie_acceptordeny')}  />
        <div className="container-fluid p-4 body-font">
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <div className="col-xs-12 col-md-5 py-md-5" style={{ textAlign: 'left' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz-247-Horizontal-CMYK 1.png" alt='' />
                    <p>Procura uma solução de mobilidade à hora?<br /> A Hertz 24/7® oferece uma seleção de viaturas ideais para transportes de média e grande dimensão. <br />  Disponível em lojas IKEA, Leroy Merlin e Brico Depôt.</p>
                </div>
                <div className="col-xs-12 col-md-7 py-md-5" >
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Sobre a Hertz 24/7®  </b></p>
                            <p><NavLink className="nav-link" to="/PT/Howitworks" onClick={GoToTop}>Sobre a Hertz 24/7®</NavLink></p>
                            <p><NavLink className="nav-link" to="/PT/Locations" onClick={GoToTop}>Localizações</NavLink></p>                            
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Ajuda</b></p>
                            <p><NavLink className="nav-link" to="/PT/FAQ" onClick={GoToTop}>FAQ</NavLink></p>
                            <p><NavLink className="nav-link" to="mailto: customersupport.247@hertz.pt">Contacto</NavLink></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " >
                            <p><b>Mais</b></p>
                            <p><a className="nav-link" href="https://www.hertz.pt/rentacar/navigation/templates/legalView.jsp" target="_blank" rel='noreferrer'>Termos de utilização</a></p>
                            <p><NavLink className="nav-link" onClick={() => {
                                setmcShow(true);
                                if (localStorage.getItem('Performance_cookie').toString() === 'false')
                                    setIsSwitchOn(false);
                                else
                                    setIsSwitchOn(true);
                            }} style={{ textDecoration: 'none' }}>Gerir os meus Cookies</NavLink></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ backgroundColor: 'lightgray' }}>
                <span className="border border-2"></span>
                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'left' }}>
                    <b>©2024 Hertz 24/7®  . Todos os direitos reservados</b>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-4">
                            <a href='https://apps.apple.com/pt/app/hertz-24-7-mobility/id6453605078'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-1" >
                            &nbsp;
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=pt_pt&gl=pt'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                            </a>
                        </div>
                        <div className="col-xs-12 col-md-3" >
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_Small.png" alt='' />
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://www.hertz.pt/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Privacidade e política</NavLink></b>
                            
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <b><NavLink className="nav-link" to="https://resources.hertz247.ufofleet.com/publishing/documents/247TCPTPT.pdf" target='_blank' rel='noreferrer'>Termos e condições</NavLink></b>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </>);
}
export default Footer;