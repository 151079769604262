import React from "react";
import Nav from "./Components/Nav";
import HomeBody from "./Components/BodyHome";
import Footer from "./Components/Footer";

class DEHome extends React.Component {
    render() {
        return (<>
            <Nav />
            <HomeBody />
            <Footer />
        </>
        );
    }
}

export default DEHome;