import React from 'react';

function BodyHowitWorks() {
    return (<>
        <div className="container-fluid body-font">
            <div className="row">
                <div className="col-xs-12 col-md-6 py-md-5">
                    <p className="h6"><b>Como funciona</b></p>
                    <p className="h1"><i><b>Familiarizar-se com o serviço Hertz 24/7</b></i></p>
                    <p className="h6">O serviço Hertz 24/7 oferece-lhe maior independência para reservar uma carrinha sem recorrer a terceiros ou papelada.</p>
                    <p className="h6">Basta transferir a app Hertz 24/7, criar o seu perfil e seguir os passos que aparecem no ecrã.</p>
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/howitworks-top.png" alt='' />
                </div>
            </div>

            <div className="row" style={{ backgroundColor: '#F3F3F3' }}>
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br />
                    <h1 className="display-5"><b>Como funciona o serviço Hertz 24/7®</b></h1>
                    <br />
                    <p className="h6">
                        Alugar com a Hertz 24/7® é tão fácil e eficiente que pode entrar na sua viatura poucos minutos depois de ter criado a sua reserva. É simples!
                    </p>
                    <br />
                </div>
            </div>

            <div className="row" style={{ backgroundColor: '#F3F3F3' }}>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_large.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>01</b></p>
                    <br />
                    <p className="h2">Descarregar a App</p>
                    <p>É rápido e fácil transferir a app Hertz 24/7® para Android ou iOS, basta digitalizar o Código QR.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Register.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>02</b></p>
                    <br />
                    <p className="h2">Registar</p>
                    <p>Crie o seu perfil de membro e submeta os seus documentos para validação. Tudo através da app!</p>

                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Book_vehicle.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>03</b></p>
                    <br />
                    <p className="h2">Reservar a sua viatura</p>
                    <p>Inicie a sessão na App e reserve a sua viatura, selecionando o local e a hora de recolha pretendidos. Precisará de um cartão de crédito/débito e carta de condução válidos.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step4-Start.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>04</b></p>
                    <br />
                    <p className="h2">Iniciar o aluguer</p>
                    <p>Antes de iniciar o aluguer, ser-lhe-á atribuída uma viatura. No levantamento, receberá a chave digital, através da App, para se ligar à viatura e destrancar as portas.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step5-During.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>05</b></p>
                    <br />
                    <p className="h2">Durante o aluguer</p>
                    <p>Caso note que precisa de mais tempo, mesmo durante o aluguer, pode prolongar e terá sempre acesso ao seu contrato para aconsultar todos os detalhes! Em caso de inatividade prolongada, o imobilizador pode ligar-se. Na App, utilize o botão do meio para desativá-lo.</p>

                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step6-End.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>06</b></p>
                    <br />
                    <p className="h2">Fim do aluguer</p>
                    <p>Quando terminar o aluguer, basta selecionar o botão para o efeito, fazer uma breve inspeção da viatura e confirmar que deixou tudo em ordem para o próximo condutor. A fatura será enviada até 48h após o término e o montante bloqueado para a franquia será libertado.</p>
                </div>
            </div>


            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br />
                    <h1 className="display-5"><b>FAQs</b></h1>
                    <p className="h6">

                    </p>
                </div>

                <div className="col-xs-12" style={{ textAlign: 'left' }}>
                    <br /><br />

                    <div className="accordion p-4" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading1">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                                    <b>O que é a Hertz 24/7®?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse1" className="accordion-collapse collapse" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">A Hertz 24/7® oferece-lhe conveniência e flexibilidade para alugar uma carrinha comercial à hora. É um serviço disponível nas lojas parceiras IKEA, Leroy Merlin e Brico Depôt, eliminando o stress e custos desproporcionais que surgem com a necessidade de transporte de bens muito pesados ou de grandes dimensões.<br /> O serviço está inteiramente disponível na app Hertz 24/7®. Evite as filas e o tempo de espera!</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                                    <b>Quais são os requisitos mínimos?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse2" className="accordion-collapse collapse" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Para usar o serviço Hertz 24/7®, terá de ter na sua posse:<br />
                                    · Uma carta de condução em seu nome, válida há mais de um ano<br />
                                    · Um documento de identificação (cartão de cidadão, passaporte)<br />
                                    · Um cartão de crédito ou de débito em seu nome (consulte as condições)<br />
                                    · O seu próprio número de telemóvel e endereço de correio eletrónico<br />
                                    · Um smartphone Android ou iPhone com ligação à internet, Bluetooth e geolocalização<br />
                                    Após submeter a documentação necessária, a nossa equipa irá validar tudo para poder dar início à sua primeira reserva o quanto antes!
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading3">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                                    <b>A que encargos estarei sujeito ao alugar uma viatura Hertz 24/7®?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse3" className="accordion-collapse collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Convidamo-lo a consultar os nossos <a href='https://resources.hertz247.ufofleet.com/publishing/documents/247TCPTPT.pdf' target='_blank' rel="noreferrer">Termos e Condições</a> para toda a informação mais atual. </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading4">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                                    <b>Como desbloquear a viatura?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse4" className="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">A App Hertz 24/7® será a sua chave digital. Com o Bluetooth e geolocalização ligados, basta selecionar "Obter Chave Digital" no separador "Conduzir" e a app comunicará com a carrinha, a qual poderá trancar e destrancar caso efetue a ligação com sucesso. <br />Nota: não poderá abrir a viatura após a data/hora programada para a reserva.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading5">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                                    <b>Como é cobrado o combustível?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse5" className="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">O combustível está incluído no valor do aluguer. No entanto, deverá assegurar-se que deixa um registo igual ou superior a ¼ do depósito cheio para o próximo cliente. Consulte os <a href='https://resources.hertz247.ufofleet.com/publishing/documents/247TCPTPT.pdf' target='_blank' rel="noreferrer">Termos e Condições</a> para mais informação. </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>
    );
}
export default BodyHowitWorks;
