import React from 'react';
import Button from 'react-bootstrap/Button';

function BusinessSolutionBody() {
    return (<>
        <div className="container-fluid p-4 body-font">
            <div className='row p-4' style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/BusinessSolutions_top_desktop.png)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1">
                    </div>
                    <div className="col-xs-12 col-md-5">
                        <br />
                        <p className="h6"><b>Soluzioni aziendali</b></p>
                        <span>
                            <h1><i><b>La vostra azienda in movimento!</b></i></h1>
                        </span>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-4">
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <p className="h2">Soluzioni aziendali</p>
                    <p>
                        Incorporare il Car Sharing Hertz 24/7® nel vostro piano di sostenibilità aziendale è più di una decisione commerciale intelligente: è un impegno per un futuro più luminoso e sostenibile. Collaborando con Hertz, non solo fornirete comode opzioni di trasporto ai vostri dipendenti, ma farete anche passi significativi verso la riduzione di anidride carbonica della vostra azienda e la promozione della responsabilità ambientale. Unitevi oggi al movimento verso pratiche commerciali sostenibili con Hertz 24/7® Car Sharing.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                    <img className='img-fluid' src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Corda_Mobile.png" alt='' />
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" sstyle={{ textAlign: 'left' }}>
                            <p className="h3">Efficienza dei costi</p>
                            <p>Disponendo di una flotta aziendale dedicata al ridesharing, le aziende possono potenzialmente ridurre i costi rispetto al rimborso dei dipendenti per le corse individuali o all'utilizzo di servizi di terzi.</p>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" style={{ textAlign: 'left' }}>
                            <p className="h3">Trasparente e sotto controllo</p>
                            <p>La gestione di una flotta interna offre alle aziende una maggiore trasparenza e controllo sulle operazioni di trasporto. Voi potrete tracciare i modelli di utilizzo, monitorare la manutenzione dei veicoli e far rispettare le norme di sicurezza e le politiche aziendali in modo più efficace.</p>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" style={{ textAlign: 'left' }}>
                            <p className="h3">Rispetto dell'ambiente</p>
                            <p>L'adozione di una flotta aziendale per il ridesharing può ridurre significativamente l'impatto ambientale del trasporto dei dipendenti. Le aziende possono dare priorità all'uso di veicoli ecologici, come le auto ibride o elettriche, all'interno della loro flotta. In questo modo, contribuiscono a ridurre le emissioni di anidride carbonica e incentivano iniziative di sostenibilità.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br/>
                    <p className="h2">Contattateci per saperne di più</p>
                    <br/>
                    <p>
                        Inviateci un'e-mail (<b>✉:hertzit247@hertz.it</b>) se siete interessati a saperne di più. Un membro del nostro team di flotte aziendali sarà in contatto con voi.
                    </p>
                    <p>
                        <Button href='mailto:hertzit247@hertz.it' variant="warning"><b>Invia un'e-mail ora</b></Button>
                    </p>
                </div>
            </div>
        </div>
    </>
    );
}

export default BusinessSolutionBody;

