import React from 'react';
import Button from 'react-bootstrap/Button';

function BusinessSolutionBody() {
    return (<>
        <div className="container-fluid  p-4 body-font">
            <div className='row p-4' style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
            <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/BusinessSolutions_top_desktop.png)', backgroundSize: 'cover' }}>
                <div className="col-xs-12 col-md-1">
                </div>
                <div className="col-xs-12 col-md-5">
                    <br />
                    <p className="h6"><b>Solutions pour les entreprises</b></p>
                    <span>
                        <h1><i><b>Votre entreprise en mouvement !</b></i></h1>
                    </span>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
                <div className="col-xs-12 col-md-4">
                </div>
            </div>
            </div>
            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br/>
                    <p className="h2">Solutions pour les entreprises</p>
                    <br/>
                    <p>
                    Intégrer l'autopartage Hertz 24/7® dans votre plan de développement durable d'entreprise est plus qu'une simple décision commerciale intelligente - c'est un engagement en faveur d'un avenir plus radieux et plus durable. En vous associant à Hertz, vous offrez non seulement des options de transport pratiques à vos employés, mais vous prenez également des mesures significatives pour réduire l'empreinte carbone de votre entreprise et promouvoir la protection de l'environnement. Rejoignez dès aujourd'hui le mouvement vers des pratiques commerciales durables avec l'autopartage Hertz 24/7®.
                    </p>
                    <br/>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                    <img className='img-fluid' src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Corda_Mobile.png" alt='' />
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" sstyle={{ textAlign: 'left' }}>
                            <p className="h3">Rentabilité</p>
                            <p>En disposant d'une flotte d'entreprise dédiée au covoiturage, les entreprises peuvent potentiellement réduire leurs coûts par rapport au remboursement des employés pour des trajets individuels ou à l'utilisation de services tiers.</p>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" style={{ textAlign: 'left' }}>
                            <p className="h3">Transparence et contrôle</p>
                            <p>Le maintien d'une flotte interne offre aux entreprises une plus grande transparence et un meilleur contrôle de leurs opérations de transport. Elles peuvent suivre les modes d'utilisation, contrôler l'entretien des véhicules et faire respecter les règles de sécurité et les politiques de l'entreprise de manière plus efficace.</p>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" style={{ textAlign: 'left' }}>
                            <p className="h3">Respect de l'environnement</p>
                            <p>L'adoption d'une flotte d'entreprise pour le covoiturage peut réduire de manière significative l'empreinte environnementale de la mobilité des collaborateurs. Les entreprises peuvent donner la priorité à l'utilisation de véhicules plus éco-responsables, tels que les voitures hybrides ou électriques, au sein de leur flotte. Ce faisant, elles contribuent à la réduction des émissions de carbone et soutiennent les efforts de l'Union européenne pour réduire les émissions de gaz à effet de serre et les initiatives en faveur du développement durable.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <p className="h2">Contactez-nous pour en savoir plus</p>
                    <br/>
                    <p>
                        Envoyez-nous un courriel (<b>✉:HertzFR247@hertz.com</b>) si vous souhaitez en savoir plus. Un membre de notre équipe chargée de la flotte d'entreprises sera à votre disposition pour répondre à vos questions.
                    </p>
                    <p>
                        <Button href='mailto:HertzFR247@hertz.com' variant="warning"><b>Envoyer un email</b></Button>
                    </p>
                </div>
            </div>
        </div>
    </>
    );
}

export default BusinessSolutionBody;

