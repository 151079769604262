import React from "react";
import Nav from "./Components/Nav";
import BodyLHFAQ from "./Components/bodylhfaq";
import Footer from "./Components/Footer";

class LHCarpoolDEFAQ extends React.Component {
    render() {
        return (<>
            <Nav />
            <BodyLHFAQ />
            <Footer />
        </>
        );

    }
}

export default LHCarpoolDEFAQ;