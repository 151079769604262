import React from "react";
import Nav from "./Components/Nav";
import BodyLHHome from "./Components/bodylhhome";
import Footer from "./Components/Footer";

class LHCarpoolDEHome extends React.Component {
    render() {
        return (<>
            <Nav />
            <BodyLHHome/>
            <Footer />
        </>
        );
    }
}

export default LHCarpoolDEHome;