import React from 'react';
import Button from 'react-bootstrap/Button';

function BusinessSolutionBody() {
    return (<>
        <div className="container-fluid body-font">
            <div className='row' style={{ backgroundColor: 'lightgray' }}>
                <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/BusinessSolutions_top_desktop.png)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1">
                    </div>
                    <div className="col-xs-12 col-md-5">
                        <br />
                        <p className="h6">Soluções para empresas</p>
                        <p>
                            <h1>A sua empresa em movimento!</h1>
                        </p>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-4">
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <p className="h2">Soluções para empresas</p>
                    <p>
                        Incorporar o Hertz 24/7® Car Sharing no seu plano de sustentabilidade empresarial é mais do que uma decisão de negócio inteligente - é um compromisso para um futuro mais brilhante e sustentável. Ao estabelecer uma parceria com a Hertz, não e s t á   apenas a fornecer opções de transporte convenientes para os seus funcionários, mas também a tomar medidas significativas para reduzir a pegada de carbono da sua empresa e promover o ambiente responsabilidade. Junte-se ao movimento para práticas empresariais sustentáveis com a Hertz 24/7® Car Partilhar hoje.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                    <img className='img-fluid' src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Corda_Mobile.png" alt='' />
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" sstyle={{ textAlign: 'left' }}>
                            <p className="h3">Eficiência de custos</p>
                            <p>Ao dispor de uma frota empresarial dedicada à partilha de boleias, as empresas podem reduzir potencialmente os custos em comparação com o reembolso de boleias individuais aos empregados ou a utilização de serviços de terceiros.</p>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" style={{ textAlign: 'left' }}>
                            <p className="h3">Transparente e em controlo</p>
                            <p>A manutenção de uma frota própria proporciona às empresas uma maior transparência e controlo sobre as suas operações de transporte. Podem seguir os padrões de utilização, monitorizar a manutenção dos veículos e aplicar políticas da empresa de forma mais eficaz.</p>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" style={{ textAlign: 'left' }}>
                            <p className="h3">Respeita o ambiente</p>
                            <p>A adoção de uma frota empresarial para partilha de boleias pode reduzir significativamente a pegada ambiental do transporte dos funcionários. As empresas podem dar prioridade à utilização de veículos ecológicos, como os híbridos ou eléctricos, na sua frota. Ao fazê-lo, contribuem para a redução das emissões de carbono e apoiam iniciativas de sustentabilidade.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <p className="h2">Contacte-nos para saber mais</p>
                    <p>
                        Envie-nos um e-mail se estiver interessado em saber mais. Um membro da nossa equipa de frota comercial estará em contacto consigo.
                    </p>
                    <p>
                        <Button href='mailto: customersupport.247@hertz.pt' variant="warning"><b>nviar por c  rreio eletrón</b></Button>
                    </p>
                </div>
            </div>
        </div>
    </>
    );
}

export default BusinessSolutionBody;

