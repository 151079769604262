import React from 'react';


function BodyHowitWorks() {
    return (<>
        <div className="container-fluid p-4 body-font" > 
            <div className="row" >
                <div className="col-xs-12 col-md-6 ">
                    <p className="h6"><b>How it works</b></p>
                    <p className="h1"><i><b>Getting started with Hertz 24/7</b></i></p>
                    <p className="h6">Using Hertz 24/7® is easy. Simply create your membership profile at an annual fee of £5 and gain access to all Hertz 24/7® vehicles across our various partners in the UK and Europe.</p>
                    <p className="h6">The quickest and easiest way to do this is with our free Hertz 24/7® App. Enter your details once and with our photo function in the app, send your documents in for verification and off you go!</p>
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }} >
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/howitworks-top.png" alt='' style={{  backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}/>
                </div>
            </div>

            <div className="row" style={{backgroundColor:'#F3F3F3'}}>
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br/>
                    <h1 className="display-5"><b>How Hertz 24/7® works</b></h1>
                    <br/>
                    <p className="h6">
                        We’ve made renting with Hertz24/7®  so easy and fast that you can be in your vehicle within 15 minutes. Here’s how we do it.
                    </p>
                    <br/>
                </div>
            </div>

            <div className="row" style={{backgroundColor:'#F3F3F3'}}>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_large.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>01</b></p>
                    <br/>
                    <p className="h2">Download the app</p>
                    <p>It's quick and easy to download the Hertz 24/7 app from your favourite app store. </p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Register.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>02</b></p>
                    <br/>
                    <p className="h2">Register</p>
                    <p>Simply create your membership profile  and submit your documents for verification with the in-app photo function.</p>
                    <p><b>A £5 annual membership fee applies.</b></p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Book_vehicle.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>03</b></p>
                    <br/>
                    <p className="h2">Book your vehicle</p>
                    <p>Log into the app and book your van by selecting your desired location and pickup time. You’ll need a credit/debit card.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step4-Start.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>04</b></p>
                    <br/>
                    <p className="h2">Start rental</p>
                    <p>Close to the start of your rental you vehicle will be assigned. After the initial  vehicle inspection, you’ll receive the digital  key in the app to unlock the vehicle.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step5-During.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>05</b></p>
                    <br/>
                    <p className="h2">During rental</p>
                    <p>During your rental, if you need more time, you can extend your rental. You can also always access the rental agreement.During inactivity, the immobiliser can come on. Use the power button in the app to release it.</p>

                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step6-End.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>06</b></p>
                    <br/>
                    <p className="h2">End rental</p>
                    <p>When you are done, close the rental by going through the final inspection steps and checklist. The invoice will be shared with you in 48hrs and the deposit released.</p>
                </div>
            </div>


            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                <br/>
                    <h1 className="display-5"><b>FAQs</b></h1>
                    <p className="h6">
                        We’ve made renting with Hertz24/7®  so easy and fast that you can be in your vehicle within 15 minutes. Here’s how we do it.
                    </p>
                    <br/>
                </div>

                <div className="col-xs-12" style={{ textAlign: 'left' }}>
                    <br /><br />
                    
                    <div className="accordion p-4" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading1">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                                    <b>What is Hertz 24/7?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse1" className="accordion-collapse collapse" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>Hertz 24/7™ gives you the convenience and flexibility of having access to a wide range of vans, for as long or as little as you like. You can rent from just an hour right up to three days. You can also pick up at any hour of day or night.</p>
                                    <p>In the UK, Join for just a £5 annual membership fee, then pay only for your rental and mileage. What's more, insurance and maintenance are included in the rate.</p>
                                    <p>The annual membership fee will be charged upon enrolling for new members and contributes to the provision of security validation at the start of your membership and sanitisation of vehicles hired throughout your membership.</p>
                                    <p>NOTE: Hertz 24/7 customers who are a valid Costco member do not have to pay the annual membership fee.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                                    <b>What details do you require?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse2" className="accordion-collapse collapse" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">In order to join Hertz 24/7 you’ll need to provide the following details:<br/>
                                    · A valid driving licence in your own name<br/>
                                    · A credit or debit card in your own name<br/>
                                    · Your own mobile phone number and your own personal email address.<br/>
                                    We then carry out the necessary ID checks before confirming your registration.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading3">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                                    <b>How will I be charged when I book a Hertz 24/7 rental?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse3" className="accordion-collapse collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">We will preauthorise up to 200% of the initial charges (48) hours before you start your rental or immediately if your rental commences within 48 hours. The booking will only be confirmed once authorisation has been successfully obtained. Preauthorisation’s will be released when the invoice is issued. We will deduct the charges and return the remainder. If the rental cost exceeds the deposit, we will capture the exceeded amount. Please note, any delay in the deposit being returned will be from your credit or debit card issuing bank.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading4">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                                    <b>How to unlock the vehicle?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse4" className="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">The Hertz 24/7 app will be your digital key. To open your car, press the 'Unlock' button on the drive tab during your rental. Note: you will not be able to open the car until the rental period has officially started, inspection has been completed and the contract signed.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading5">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                                    <b>Who pays for the fuel cost?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse5" className="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Fuel will be charged on a pence per mileage basis, displayed at the time of booking. All vehicles should come with a fuel card under the sun visor and at least a quarter tank of fuel at the beginning of the rental. You should return the vehicle this way too. If the fuel card is missing at the beginning of the rental please contact us using the telephone number in the Help section of the app. There is a £50 per transaction limit on the fuel card provided. Fuel cards can be used at Shell, Esso or Texaco fuel stations and the pin code displayed in the app will be required when paying for fuel.</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>
    );
}
export default BodyHowitWorks;
