import React from "react";
import Nav from "./Components/Nav";
import Footer from "./Components/Footer";
import FAQBody from "./Components/BodyFAQ";

class ITFAQ extends React.Component {
    render() {
        return (<>
            <Nav />
            <FAQBody />
            <Footer />
        </>
        );

    }
}

export default ITFAQ;