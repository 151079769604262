import React from 'react';

function HomeBody() {
    return (<>
        <div className="container-fluid p-4 body-font">
            <div className="row">
                <div className="col-xs-12 col-md-6 py-md-5">
                    <p className="h6"><b>Le covoiturage en toute simplicité</b></p>
                    <p className="h1"><b>RESERVEZ. DÉVERROUILLEZ.</b> <font className="text-warning"><b>PARTEZ !</b></font></p>
                    <p className="h6">Des locations pratiques à l'heure ou à la journée, lorsque vous en avez besoin. Il n'y a pas de comptoir, vous pouvez prendre place dans votre véhicule dans les 15 minutes suivant votre inscription.</p>
                    <div className="row">
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }} >
                            <div className="row">
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }} >
                                    <a href='https://apps.apple.com/fr/app/hertz-24-7-mobility/id6453605078'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }} >
                                    <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=fr_fr&gl=fr'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-6">
                        </div>

                    </div>
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Mercedes_24sprinter_top.png" alt='' />
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    
                    <h1 className="display-5"><b>Comment cela fonctionne-t-il ?</b></h1>
                    <br/>
                    <p className="h6">
                    Avec Hertz24/7®, nous avons rendu la location si facile et si rapide que vous pouvez être à bord de votre véhicule dans les 15 minutes. Voici comment nous procédons.
                    </p>
                    <br/>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_large.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>01</b></p><br/>
                    <p className="h2">Télécharger l'application</p>
                    <p>Il est facile et rapide de télécharger l'application Hertz 24/7 à partir de votre magasin d'applications préféré. Vous pouvez simplement scanner le QR Code.</p>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Register.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>02</b></p><br/>
                    <p className="h2">Inscription</p>
                    <p>Il vous suffit de créer votre profil de membre et soumettre vos documents pour vérification à l'aide de la fonction photo de l'application.</p>                   
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Select_Location_FR.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>03</b></p><br/>
                    <p className="h2">Réservez votre véhicule</p>
                    <p>Connectez-vous à l'application et réservez votre véhicule en sélectionnant le lieu et l'heure de prise en charge souhaités. Vous pourrez avoir besoin d'une carte de crédit/débit.</p>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Unlock.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>04</b></p><br/>
                    <p className="h2">Début de la location</p>
                    <p>Peu de temps avant le début de votre location, un  véhicule vous sera attribué. Au moment de sa prise en charge, vous recevrez une clé virtuelle dans l'application pour déverrouiller le véhicule.</p>
                </div>
            </div>

            <div className="row" style={{ backgroundColor: '#F3F3F3' }}>
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br/>
                    <h1 className="display-5"><b>Notre flotte</b></h1>
                    <br/>
                    <p className="h6">
                    Hertz 24/7® a la voiture ou l'utilitaire parfait pour chaque occasion. Il vous suffit de sélectionner le véhicule que vous souhaitez conduire et de prendre la route.
                    </p>
                    <br/>
                    <div className="row">
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/FordTransitLWB .png" alt='' />

                            <div style={{ textAlign: 'left' }}>
                                <p className="h2">Ford Transit ou similaire</p>                                
                                <span>
                                    <ul>
                                        <li>Portes : 4</li>
                                        <li>Espace : 2,1 x 1,8 x 3,3m (H x L x L)</li>
                                    </ul>
                                </span>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/FordCustomSWB.png" alt='' />
                            <div style={{ textAlign: 'left' }}>
                                <p className="h2">Fiat Ducato ou similaire</p>                              
                                <span>
                                    <ul>
                                        <li>Portes : 4</li>
                                        <li>Espace : 2,1 x 1,8 x 3,3m (H x L x L)</li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/FiatDucato.png" alt='' />
                            <div style={{ textAlign: 'left' }}>
                                <p className="h2">Fiat Ducato ou similaire</p>                               
                                <span>
                                    <ul>
                                        <li>Portes : 2</li>                                        
                                    </ul>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Mobile_FR.png" alt='' />
                </div>
                <div className="col-xs-12 col-md-6 py-md-5" style={{ textAlign: 'center' }}>
                    <h1 className="display-5"><b>Nos  implantations</b></h1>
                    <p>Avec plus de 700 sites à travers l'Europe, une voiture ou un utilitaire Hertz 24/7® est plus proche que vous ne le pensez.</p>
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Royaume-Uni (300 Localisation des sites)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600020009&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Manchester</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902024006&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>London</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>France (20 Localisation des sites)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-fr19100400001005&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Paris</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-fr19110500007004&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Marseilles</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Allemagne (280 Localisation des sites)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-de18112800001004&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Berlin</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-de15102900001000&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Frankfurt</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Italie (75 Localisation des sites)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-it17072800007000&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Rome</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-it17072800005002&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Milan</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Portugal (39 Localisation des sites)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-pt21051200001008&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Lisbon</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-pt17062200003001&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Porto</a></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12" style={{ textAlign: 'left' }}>
                            <b><u>Découvrez les sites les plus proches de vous &gt;</u></b><br/><br/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }}>
                            <div className="row">
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }}>
                                    <a href='https://apps.apple.com/fr/app/hertz-24-7-mobility/id6453605078'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }}>
                                    <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=fr_fr&gl=fr'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }}>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-4" style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/Transit_city.png)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1" >
                    </div>
                    <div className="col-xs-12 col-md-3" >
                        <br />
                        <br />
                        <br />
                        <span>
                            <h1 className="display-4"><b>Pourquoi choisir Hertz 24/7®  ?</b></h1>
                        </span>
                        <p>Avec Hertz 24/7®, vous pouvez louer à prix compétitif des véhicules à des tarifs horaires et journaliers, en fonction du type de véhicule et de l'emplacement. Le prix estimé de la location sera affiché au moment de la réservation*.</p>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-8" >
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center', backgroundColor: '#fff7cc' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Contact.png" alt='' />
                    <p className="h4">Service client</p>
                    <p>Nous sommes là pour vous en cas de problème avec votre location ou votre véhicule, aux heures d'ouverture.</p>
                </div>
                <div className="col-xs-12 col-md-4 " style={{ textAlign: 'center', backgroundColor: '#fff099' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Price.png" alt='' />
                    <p className="h4">Tarification simple</p>
                    <p>Réservez des utilitaires à l'heure ou à la journée.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center', backgroundColor: '#ffe866' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Location.png" alt='' />
                    <p className="h4">Toujours à proximité</p>
                    <p>Avec plus de 700 sites en Europe et au Royaume-Uni, il y a toujours un véhicule à proximité. </p>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br />
                    <span><h6>*Le prix du kilomètre peut varier en fonction du type de véhicule sélectionné et est susceptible d'être modifié en fonction de l'évolution des prix actuels du carburant.</h6></span>
                    <span><h6>Vérifiez toujours le prix du kilomètre applicable à la date de votre réservation.</h6></span>
                </div>
            </div>
        </div>
    </>);
}
export default HomeBody;