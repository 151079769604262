import React from "react";
import Nav from "./Components/Nav";
import Footer from "./Components/Footer";
import BodyLocations from "./Components/BodyLocations";

class DELocations extends React.Component {
    render() {
        return (<>
            <Nav />
            <BodyLocations />
            <Footer />
        </>
        );

    }
}

export default DELocations;