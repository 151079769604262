import React from 'react';

function HomeBody() {
    return (<>
        <div className="container-fluid p-4 body-font">
            <div className="row">
                <div className="col-xs-12 col-md-6 py-md-5">
                    <p className="h6"><b>Snadné sdílení automobilů</b></p>
                    <p className="h1"><b>REZERVUJ. ODEMKNI.</b> <font className="text-warning"><b>VYRAŽ!</b></font></p>
                    <p className="h6">Výhodné hodinové nebo denní pronájmy, kdykoli je potřebujete. Není zapotřebí pobočky. Do vozidla můžete vstoupit během 15 minut!</p>
                    <div className="row">
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }} >
                            <div className="row">
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }} >
                                    <a href='https://apps.apple.com/cz/app/hertz-24-7-mobility/id6453605078'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }} >
                                    <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=cs_CZ&gl=CZ'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-6">
                        </div>

                    </div>
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Mercedes_24sprinter_top.png" alt='' />
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <h1 className="display-5"><b>Jak pronájem funguje</b></h1>
                    <br />
                    <p className="h6">
                        Pronájem s Hertz24/7® je díky nám snadný a rychlý. Ve vozidle můžete být již během 15 minut. Zde je návod, jak to udělat.
                    </p>
                    <br />
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_large.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>01</b></p><br />
                    <p className="h2">Stáhněte si aplikaci</p>
                    <p>Aplikaci Hertz 24/7 si můžete snadno a rychle stáhnout z oblíbeného obchodu s aplikacemi. Stačí načíst tento QR kód.</p>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Register.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>02</b></p><br />
                    <p className="h2">Registrace</p>
                    <p>Jednoduše si vytvořte svůj členský profil a předložit dokumenty k ověření pomocí funkce fotoaparátu v aplikaci.</p>

                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Book_vehicle.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>03</b></p><br />
                    <p className="h2">Rezervujte si vozidlo</p>
                    <p>Přihlaste se do aplikace a rezervujte si dodávku výběrem požadovaného místa a času vyzvednutí. Budete potřebujete kreditní/debetní kartu.</p>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Unlock.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>04</b></p><br />
                    <p className="h2">Zahájení pronájmu</p>
                    <p>Před začátkem pronájmu vám bude vozidlo přiděleno. Při vyzvednutí obdržíte digitální klíč v aplikaci pro odemknutí vozidla.</p>
                </div>
            </div>

            <div className="row" style={{ backgroundColor: '#F3F3F3' }}>
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br/>
                    <h1 className="display-5"><b>Naše flotila</b></h1>
                    <br/>
                    <p className="h6">
                        Hertz 24/7® má pro každou příležitost ideální osobní nebo dodávkový vůz. Stačí si pouze vybrat vozidlo, které si přijete řídit.
                    </p>
                    <br/>
                    <div className="row">
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/FordTransitLWB%20.png" alt='' />

                            <div style={{ textAlign: 'left' }}>
                                <p className="h2">Ford Transit LWB nebo podobné</p>
                                <span>
                                    <ul>
                                        <li>Dveře: 4</li>
                                        <li>Prostor: 2,1 x 1,8 x 3,3 m (v x š x d)</li>
                                    </ul>
                                </span>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/FordCustomSWB.png" alt='' />
                            <div style={{ textAlign: 'left' }}>
                                <p className="h2">Ford Custom SWB nebo podobné</p>
                                <span>
                                    <ul>
                                        <li>Dveře: 5</li>
                                        <li>Prostor: 1,50 x 1,70 x 2,60 m (v x š x d)</li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/VauxhallCombo.png" alt='' />
                            <div style={{ textAlign: 'left' }}>
                                <p className="h2">Vauxhall Combo nebo podobné</p>
                                <span>
                                    <ul>
                                        <li>Dveře: 3</li>
                                        <li>Prostor: 1,2 x 1,2 x 1,5 (v x š x d)</li>
                                    </ul>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Phone_247app.png" alt='' />
                </div>
                <div className="col-xs-12 col-md-6 py-md-5" style={{ textAlign: 'center' }}>
                    <h1 className="display-5"><b>Naše lokace</b></h1>
                    <p>Více než 700 poboček po celé Evropě, automobil nebo dodávka Hertz 24/7® je blíž, než si myslíte.</p>
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Spojené království (300 Lokality)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600020009&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Manchester</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902024006&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>London</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Francie (20 Lokality)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-fr19100400001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Paris</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-fr19110500007004&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Marseilles</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Německo (280 Lokality)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-de18112800001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Berlin</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-de15102900001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Frankfurt</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Itálie (75 Lokality)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-it17072800007000&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Rome</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-it17072800005002&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Milan</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Portugalsko (39 Lokality)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-pt21051200001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Lisbon</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-pt17062200003001&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Porto</a></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12" style={{ textAlign: 'left' }}>
                            <b><u>Prozkoumejte místa ve vašem okolí &gt;</u></b><br /><br />
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }} >
                            <div className="row">
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }}>
                                    <a href='https://apps.apple.com/cz/app/hertz-24-7-mobility/id6453605078'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }} >
                            <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=cs_CZ&gl=CZ'>
                                <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-4" style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/Transit_city.png)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1" >
                    </div>
                    <div className="col-xs-12 col-md-3" >
                        <br />
                        <br />
                        <br />
                        <span>
                            <h1 className="display-4"><b>Proč si vybrat Hertz 24/7®  ?</b></h1>
                        </span>
                        <p>Se službou Hertz 24/7® si můžete pronajmout vozidla za výhodné hodinové i denní sazby. V závislosti na typu a umístění vozidla. Předpokládaná cena pronájmu se zobrazí v okamžiku rezervace.*</p>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-8" >
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center', backgroundColor: '#fff7cc' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Contact.png" alt='' />
                    <p className="h4">Zákaznická podpora</p>
                    <p>Jsme tu pro vás v případě jakýchkoli problémů s pronájmem nebo vozidlem v otevírací době.</p>
                </div>
                <div className="col-xs-12 col-md-4 " style={{ textAlign: 'center', backgroundColor: '#fff099' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Price.png" alt='' />
                    <p className="h4">Jednoduchá tvorba cen</p>
                    <p>Rezervujte si dodávky za hodinovou nebo denní sazbu. Použijte věrnostní členské karty pro získání zvýhodněných nabídek.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center', backgroundColor: '#ffe866' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Location.png" alt='' />
                    <p className="h4">Vždy nablízku</p>
                    <p>S více než 700 pobočkami ve Velké Británii a Evropě je vždy poblíž nějaké vozidlo. </p>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br />
                    <span><h6>*Sazby za ujeté kilometry se mohou lišit v závislosti na zvoleném typu vozidla a mohou se měnit.  podle aktuálních cen pohonných hmot. </h6></span>
                    <span><h6>Vždy se ujistěte, že jste si ověřili platné sazby za ujeté kilometry v den rezervace.</h6></span>
                </div>
            </div>
        </div>
    </>);
}
export default HomeBody;