import React from 'react';

function HomeBody() {
    return (<>
        <div className="container-fluid p-4 body-font">
            <div className="row">
                <div className="col-xs-12 col-md-6 py-md-5">
                    <p className="h6"><b>Il car sharing reso semplice</b></p>
                    <p className="h1"><b>PRENOTA. SBLOCCA.</b> <font className="text-warning"><b>VAI!</b></font></p>
                    <p className="h6">Comodi noleggi orari o giornalieri ogni volta che ne hai bisogno. Non c'è uno sportello, <br/>basta entrare nel tuo veicolo entro 15 minuti dalla registrazione.</p>
                    <div className="row">
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }} >
                            <div className="row">
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }} >
                                    <a href='https://apps.apple.com/it/app/hertz-24-7-mobility/id6453605078'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }} >
                                    <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=it_it&gl=it'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-6">

                        </div>

                    </div>
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Mercedes_24sprinter_top.png" alt='' />
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <h1 className="display-5"><b>Come funziona</b></h1>
                    <br/>
                    <p className="h6">
                        Abbiamo reso il noleggio con Hertz24/7® così facile e veloce che potrete essere a bordo del vostro veicolo entro 15 minuti. Ecco come fare .
                    </p>
                    <br/>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_large.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>01</b></p><br/>
                    <p className="h2">Scarica l'app</p>
                    <p>Scaricare l'app Hertz 24/7 dal vostro app store preferito è facile e veloce, basta scannerizzare il QR code.</p>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Register.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>02</b></p><br/>
                    <p className="h2">Registrazione</p>
                    <p>È sufficiente creare il proprio profilo e inviare i documenti per la verifica con la funzione foto dell'app.</p>                    
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Select_Location_IT.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>03</b></p><br/>
                    <p className="h2">Prenota il tuo veicolo</p>
                    <p>Accedete all'app e prenotate il vostro veicolo selezionando la località e l'orario di ritiro desiderati.</p>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Unlock.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>04</b></p><br/>
                    <p className="h2">Inizio noleggio</p>
                    <p>Prima dell'inizio del noleggio vi verrà assegnato il veicolo. Al momento del ritiro potrete ottenere la chiave virtuale nell'app per sbloccare il veicolo.</p>
                </div>
            </div>

            <div className="row" style={{ backgroundColor: '#F3F3F3' }}>
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br/>
                    <h1 className="display-5"><b>La nostra flotta</b></h1>
                    <br/>
                    <p className="h6">
                        Hertz 24/7® ha l'auto o il furgone perfetto per ogni occasione. È sufficiente selezionare il veicolo che desiderate, guidare e mettersi in viaggio.
                    </p>
                    <br/>
                    <div className="row">
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/MovanoOpel.png" alt='' />
                            <br />  <br />
                            <div className="row">
                                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                                </div>
                                <div className="col-xs-12 col-md-8" style={{ textAlign: 'center' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <p className="h2">Opel Movano o simili</p>
                                        <span>
                                            <ul>
                                                <li>Posti: 3</li>
                                                <li>Misure interne: 1,7 x 1,8 x 3,0 m (A x L x L)</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                </div>
                            </div>


                        </div>
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/VivaroOpel.png" alt='' />
                            <br />  <br />
                            <div className="row">
                                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                                </div>
                                <div className="col-xs-12 col-md-8" style={{ textAlign: 'center' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <p className="h2">Opel Vivaro o simili</p>
                                        <span>
                                            <ul>
                                                <li>Posti: 3</li>
                                                <li>Misure interne: 1,2 x 1,2 x 2,1 m (H x L x L)</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                </div>
                            </div>
                           
                        </div>
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Polestar2.png" alt='' />
                            <br />  <br />
                            <div className="row">
                                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                                </div>
                                <div className="col-xs-12 col-md-8" style={{ textAlign: 'center' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <p className="h2">Polestar 2 o simili</p>
                                        <span>
                                            <ul>
                                                <li>Posti: 5</li>
                                                <li>Autonomia: 450 km</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Mobile_IT.png" alt='' />
                </div>
                <div className="col-xs-12 col-md-6 py-md-5" style={{ textAlign: 'center' }}>
                    <h1 className="display-5"><b>Le nostre sedi</b></h1>
                    <p>Con oltre 700 sedi in tutta Europa, un'auto o un furgone Hertz 24/7® è più vicino di quanto pensiate.</p>
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Regno Unito (300 Sedi)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600020009&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Manchester</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902024006&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>London</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Francia (20 Sedi)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-fr19100400001005&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Paris</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-fr19110500007004&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Marseilles</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Germania (280 Sedi)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-de18112800001004&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Berlin</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-de15102900001000&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Frankfurt</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Italia (75 Sedi)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-it17072800007000&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Rome</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-it17072800005002&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Milan</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Portogallo (39 Sedi)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-pt21051200001008&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Lisbon</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-pt17062200003001&product=HOURLY_RENTAL' style={{textDecoration:'none'}}>Porto</a></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12" style={{ textAlign: 'left' }}>
                            <b><u>Esplora le sedi più vicine a te &gt;</u></b><br/><br/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }}>
                            <div className="row">
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }}>
                                    <a href='https://apps.apple.com/it/app/hertz-24-7-mobility/id6453605078'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }}>
                                    <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=it_it&gl=it'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }}>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-4" style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/Transit_city.png)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1" >
                    </div>
                    <div className="col-xs-12 col-md-3" >
                        <br />
                        <br />
                        <br />
                        <span>
                            <h1 className="display-4"><b>Perché scegliere Hertz 24/7® ?</b></h1>
                        </span>
                        <p>Con Hertz 24/7® potete noleggiare veicoli a prezzi competitivi con tariffe orarie e giornaliere a seconda del tipo di veicolo e della sede di ritiro. Il prezzo stimato del noleggio sarà visualizzato al momento della prenotazione.*</p>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-8" >
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center', backgroundColor: '#fff7cc' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Contact.png" alt='' />
                    <p className="h4">Assistenza clienti</p>
                    <p>Siamo qui per voi in caso di problemi con il noleggio o il veicolo. Orari di apertura applicati.</p>
                </div>
                <div className="col-xs-12 col-md-4 " style={{ textAlign: 'center', backgroundColor: '#fff099' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Price.png" alt='' />
                    <p className="h4">Prezzi semplici</p>
                    <p>Prenota veicoli a tariffe orarie o giornaliere.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center', backgroundColor: '#ffe866' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Location.png" alt='' />
                    <p className="h4">Sempre nelle vicinanze</p>
                    <p>Con oltre 700 sedi in Europa, c'è sempre un veicolo nelle vicinanze. </p>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br />
                    <span><h6>*Le tariffe chilometriche possono variare a seconda del tipo di veicolo selezionato e sono soggette a modifiche in base ai prezzi attuali del carburante.</h6></span>
                    <span><h6>Assicuratevi sempre di controllare i tassi di chilometraggio applicabili alla data della prenotazione.</h6></span>
                </div>
            </div>
        </div>
    </>);
}
export default HomeBody;