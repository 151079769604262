import { useState } from 'react';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { NavLink } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';


function Cookiebanner(props) {

    const [show, setShow] = useState(true);
    const [mcshow, setmcShow] = useState(false);
    const [switchState, setSwitchState] = useState(true);
    const [isSwitchOn, setIsSwitchOn] = useState(false);

    if (!localStorage.getItem('Cookie_acceptordeny')) {
        localStorage.setItem('Cookie_acceptordeny', null);
        setShow(true);
    }
    if (!localStorage.getItem('Performance_cookie')) {
        localStorage.setItem('Performance_cookie', false);
    }

    if (!localStorage.getItem('country')) {
        localStorage.setItem('country', 'fr');
    }


    const handleAccept = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
        localStorage.setItem('Performance_cookie', true);
    };

    const handleDeny = () => {
        setShow(false);
        localStorage.setItem('Cookie_acceptordeny', 'denied');
        localStorage.setItem('Performance_cookie', false);
    };

    const onSwitchAction = () => {

        if (localStorage.getItem('Performance_cookie') === 'false') {
            setIsSwitchOn(true);
        }

        else
            setIsSwitchOn(false);

    };

    const handleSave = () => {
        localStorage.setItem('Cookie_acceptordeny', 'accepted');
        localStorage.setItem('Performance_cookie', isSwitchOn);
        setShow(false);
        setmcShow(false);
        setSwitchState(true);
    };

    const handleClose = () => setmcShow(false);

    if ((props.isVisible === null || props.isVisible === 'null') || localStorage.getItem('country') !== 'fr') {

        localStorage.setItem('country', 'fr');
        return (
            <div>
                <Modal
                    show={show}
                    onHide={handleDeny}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                >
                    <Modal.Body>
                        En sélectionnant <b>"Refuser les cookies"</b>, vous autorisez uniquement l'utilisation des cookies essentiels que nous avons configurés pour faire fonctionner notre site et vous offrir une expérience client exceptionnelle. Vous pouvez les désactiver en modifiant les paramètres de votre navigateur, mais cela peut affecter le fonctionnement du site Web. Nous aimerions également définir des cookies facultatifs qui nous aident à analyser le trafic, à offrir des fonctionnalités améliorées (par exemple, notre assistance par chat), ainsi qu'un contenu et des publicités personnalisés. Ceux-ci ne seront définis que si vous les <b>"acceptez"</b>. Si vous changez d'avis, vous pouvez vous désinscrire à tout moment. Pour en savoir plus sur les cookies que nous utilisons et définir vos paramètres de cookies, veuillez sélectionner <b>"Gérer mes cookies"</b>. Pour plus d'informations sur la manière dont nous utilisons les données que nous collectons, veuillez consulter notre <a href="https://www.hertz.fr/rentacar/privacypolicy/index.jsp?targetPage=privacyPolicyView.jsp" target='_blank' rel='noreferrer'>Politique de confidentialité</a>.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleAccept}>
                            Acceptez
                        </Button>
                        <Button variant="warning" onClick={handleDeny}>
                            Refuser
                        </Button>
                        <NavLink className="nav-link" onClick={() => setmcShow(true)} style={{ textDecoration: 'none' }}>Gérer mes cookies</NavLink> 
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={mcshow}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size='xl'
                    onHide={handleClose}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="managecookies-custom-modal-styling-title">
                            <img src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Hertz_247_logo.png" className="img-fluid" alt="Hertz24x7" width={200} /> &nbsp; Gérer mes cookies
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        Lorsque vous consultez un site Web, des données peuvent être stockées dans votre navigateur ou récupérées à partir de celui-ci, généralement sous la forme de cookies. Ces informations peuvent porter sur vous, sur vos préférences ou sur votre appareil et sont principalement utilisées pour s'assurer que le site Web fonctionne correctement. Les informations ne permettent généralement pas de vous identifier directement, mais peuvent vous permettre de bénéficier d'une expérience Web personnalisée. Parce que nous respectons votre droit à la vie privée, nous vous donnons la possibilité de ne pas autoriser certains types de cookies. Cliquez sur les différentes catégories pour obtenir plus de détails sur chacune d'entre elles, et modifier les paramètres par défaut.
                        <Form>
                            <div className="accordion p-4" id="accordionMgCookie">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-mgc_echeading">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgc_eccollapse" aria-expanded="false" aria-controls="flush-mgc_eccollapse">
                                            <b> <Form.Check
                                                disabled
                                                type="switch"
                                                label="Essentiel"
                                                id="disabled-custom-switch"
                                                defaultChecked={switchState}
                                            />
                                            </b>
                                        </button>
                                    </h2>
                                    <div id="flush-mgc_eccollapse" className="accordion-collapse collapse" aria-labelledby="flush-mgc_echeading" data-bs-parent="#accordionMgCookie">
                                        Certains cookies sont indispensables au fonctionnement de nos sites Internet. Sans eux, vous ne pourriez pas naviguer sur le site et utiliser certaines de ses fonctionnalités. Les cookies essentiels permettent de mémoriser les informations que vous fournissez et les décisions que vous prenez concernant votre location d'une page à l'autre du processus de réservation. Étant donné que ces cookies sont essentiels, nous ne vous permettons pas de choisir de les désactiver sur notre site Internet.
                                        <div className="accordion-body">
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Cookie</th>
                                                        <th>Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>dGroup</td>
                                                        <td>Se souvient de l'appareil à partir duquel le site est consulté (PC, tablette, téléphone) pour afficher le contenu approprié.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>incap_ses_*</td>
                                                        <td>Utilisé pour lier les requêtes HTTP à une session.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>nlbi_*</td>
                                                        <td>Ce cookie est utilisé pour filtrer les requêtes malveillantes. Les informations générées ne permettent pas de vous identifier en tant qu'individu.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>visid_incap_*</td>
                                                        <td>Utilisé pour vérifier que l'utilisateur accepte les cookies et n'est pas un robot.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>DC-Cookie</td>
                                                        <td>Utilisé pour vous diriger vers le bon serveur pendant toute votre visite.</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-mgcheading2">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-mgccollapse2" aria-expanded="false" aria-controls="flush-mgccollapse2">
                                            <b>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    label="Cookies de performance"
                                                    onChange={onSwitchAction}
                                                    defaultChecked={isSwitchOn}
                                                />
                                            </b>
                                        </button>
                                    </h2>
                                    <div id="flush-mgccollapse2" className="accordion-collapse collapse" aria-labelledby="flush-mgcheading2" data-bs-parent="#accordionMgCookie">
                                        Nous utilisons des cookies de performance pour surveiller les performances du site Web et analyser la manière dont nos visiteurs utilisent nos sites Web. Cela nous permet de fournir une expérience de haute qualité en identifiant et en résolvant rapidement les problèmes qui surviennent et en personnalisant notre offre. Toutes les informations collectées sont agrégées et donc anonymes et ne sont utilisées que pour améliorer le fonctionnement de notre site Web.
                                        <div className="accordion-body">NA</div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={handleSave}>
                            Enregistrer les modifications
                        </Button>

                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default Cookiebanner;