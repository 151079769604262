import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { useState } from "react";

const Map = (props) => {
    const { isLoaded } = props;
    const [selectedMarker, setSelectedMarker] = useState("");
    const containerStyle = {
        width: '100%',
        height: '800px'
    };

    const center = {
        lat: 49.8175,
        lng: 15.4730
    };
    const points = [
        {
            id: 21,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19042900002004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Home24 Outlet Hannover </a>,
            location: { lat: 52.4302095, lng: 9.83755936 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19042900002004&product=HOURLY_RENTAL'
        },
        {
            id: 22,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19011100006004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Home24 Outlet Köln</a>,
            location: { lat: 50.9219345, lng: 6.85505167 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19011100006004&product=HOURLY_RENTAL'
        },
        {
            id: 23,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19011100005005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Home24 Outlet Neu-Ulm</a>,
            location: { lat: 48.3947944, lng: 10.0155203 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19011100005005&product=HOURLY_RENTAL'
        },
        {
            id: 24,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21080600002009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>OBI Gelnhausen</a>,
            location: { lat: 50.2030589999999, lng: 9.17380418505816 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21080600002009&product=HOURLY_RENTAL'
        },
        {
            id: 25,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23021500002009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>OBI Köln/Dellbrück</a>,
            location: { lat: 50.9763156, lng: 7.0557897 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23021500002009&product=HOURLY_RENTAL'
        },
        {
            id: 26,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21080600001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>OBI Lauda-Königshofen</a>,
            location: { lat: 49.5404698, lng: 9.73792715 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21080600001000&product=HOURLY_RENTAL'
        },
        {
            id: 27,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21113000002003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>OBI Laufenburg</a>,
            location: { lat: 47.5642038999999, lng: 8.07790718330012 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21113000002003&product=HOURLY_RENTAL'
        },
        {
            id: 28,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23021500001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>OBI Rösrath</a>,
            location: { lat: 50.88333, lng: 7.1886789 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23021500001000&product=HOURLY_RENTAL'
        },
        {
            id: 29,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20121800002008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>OBI Sömmerda</a>,
            location: { lat: 51.1566552, lng: 11.1422975912758 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20121800002008&product=HOURLY_RENTAL'
        },
        {
            id: 30,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20100100001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>OBI Waldshut-Tiengen</a>,
            location: { lat: 47.617937, lng: 8.2492221155266 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20100100001009&product=HOURLY_RENTAL'
        },
        {
            id: 31,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19082600001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Globus Baumarkt Hofheim</a>,
            location: { lat: 50.0928328, lng: 8.45887915045002 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19082600001002&product=HOURLY_RENTAL'
        },
        {
            id: 32,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21051100001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>bbk baucentrum Konz</a>,
            location: { lat: 49.6944318, lng: 6.55821115040447 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21051100001006&product=HOURLY_RENTAL'
        },
        {
            id: 33,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21062800005004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Mainz Hauptbahnhof</a>,
            location: { lat: 49.9983403, lng: 8.26236035650117 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21062800005004&product=HOURLY_RENTAL'
        },
        {
            id: 34,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15090100001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Augsburg</a>,
            location: { lat: 48.4054138, lng: 10.8710971 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15090100001001&product=HOURLY_RENTAL'
        },
        {
            id: 35,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15091400004001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Bielefeld</a>,
            location: { lat: 51.9819873, lng: 8.4924193 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15091400004001&product=HOURLY_RENTAL'
        },
        {
            id: 36,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15102900006005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Braunschweig</a>,
            location: { lat: 52.3092512, lng: 10.5048372 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15102900006005&product=HOURLY_RENTAL'
        },
        {
            id: 37,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15092800002003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Brinkum</a>,
            location: { lat: 53.0271548, lng: 8.8031073 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15092800002003&product=HOURLY_RENTAL'
        },
        {
            id: 38,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15091400003002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Duisburg</a>,
            location: { lat: 51.4825202, lng: 6.7644332 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15091400003002&product=HOURLY_RENTAL'
        },
        {
            id: 39,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18091000006001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Düsseldorf</a>,
            location: { lat: 51.1882276, lng: 6.8493997 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18091000006001&product=HOURLY_RENTAL'
        },
        {
            id: 40,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19112500001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Eching</a>,
            location: { lat: 48.303782, lng: 11.633904 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19112500001008&product=HOURLY_RENTAL'
        },
        {
            id: 41,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15102900010009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Erfurt</a>,
            location: { lat: 50.959483, lng: 10.9489467388539 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15102900010009&product=HOURLY_RENTAL'
        },
        {
            id: 42,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15091400002003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Essen</a>,
            location: { lat: 51.4593823999999, lng: 7.00164280490067 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15091400002003&product=HOURLY_RENTAL'
        },
        {
            id: 43,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15102900001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Frankfurt ͛ </a>,
            location: { lat: 50.1959406, lng: 8.6588954 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15102900001000&product=HOURLY_RENTAL'
        },
        {
            id: 44,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15101900002000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Freiburg</a>,
            location: { lat: 48.0284053, lng: 7.8326696 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15101900002000&product=HOURLY_RENTAL'
        },
        {
            id: 45,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de16041800002003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Großburgwedel</a>,
            location: { lat: 52.492381, lng: 9.83779204229009 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de16041800002003&product=HOURLY_RENTAL'
        },
        {
            id: 46,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15102900004007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Halle/Leipzig</a>,
            location: { lat: 51.3483881, lng: 12.1824367 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15102900004007&product=HOURLY_RENTAL'
        },
        {
            id: 47,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de14121900001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Hamburg-Altona</a>,
            location: { lat: 53.5508324, lng: 9.9424869 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de14121900001000&product=HOURLY_RENTAL'
        },
        {
            id: 48,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de14070700003003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Hamburg-Moorfleet</a>,
            location: { lat: 53.5108092, lng: 10.093094 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de14070700003003&product=HOURLY_RENTAL'
        },
        {
            id: 49,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15102900002009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Hanau</a>,
            location: { lat: 50.1499295, lng: 8.9431535 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15102900002009&product=HOURLY_RENTAL'
        },
        {
            id: 50,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de17092200001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Kaarst ͛ </a>,
            location: { lat: 51.2105775, lng: 6.642031 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de17092200001003&product=HOURLY_RENTAL'
        },
        {
            id: 51,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15080300002008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Kaiserslautern</a>,
            location: { lat: 49.4402899, lng: 7.7013945 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15080300002008&product=HOURLY_RENTAL'
        },
        {
            id: 52,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15092100003007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Kamen ͛ </a>,
            location: { lat: 51.5698737, lng: 7.6689253 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15092100003007&product=HOURLY_RENTAL'
        },
        {
            id: 53,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20091600001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Karlsruhe ͛ </a>,
            location: { lat: 49.0053046, lng: 8.4402065 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20091600001009&product=HOURLY_RENTAL'
        },
        {
            id: 54,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15093000001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Kassel</a>,
            location: { lat: 51.2776534, lng: 9.5245168 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15093000001000&product=HOURLY_RENTAL'
        },
        {
            id: 55,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15082700001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Kiel</a>,
            location: { lat: 54.3146305, lng: 10.1067463 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15082700001008&product=HOURLY_RENTAL'
        },
        {
            id: 56,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15092200002006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Koblenz ͛ </a>,
            location: { lat: 50.3816179, lng: 7.568765 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15092200002006&product=HOURLY_RENTAL'
        },
        {
            id: 57,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15092100004006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Köln-Godorf</a>,
            location: { lat: 50.8613996, lng: 6.9714473 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15092100004006&product=HOURLY_RENTAL'
        },
        {
            id: 58,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15082700002007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Lübeck</a>,
            location: { lat: 53.9143316, lng: 10.7364054 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15082700002007&product=HOURLY_RENTAL'
        },
        {
            id: 59,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de14092400001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Ludwigsburg ͛ </a>,
            location: { lat: 48.9197554, lng: 9.1542307 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de14092400001006&product=HOURLY_RENTAL'
        },
        {
            id: 60,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de17082300002002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Magdeburg</a>,
            location: { lat: 52.169024, lng: 11.6115653641605 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de17082300002002&product=HOURLY_RENTAL'
        },
        {
            id: 61,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15102900007004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Mannheim</a>,
            location: { lat: 49.5557369, lng: 8.4442914 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15102900007004&product=HOURLY_RENTAL'
        },
        {
            id: 62,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de14070700002004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA München-Brunnthal</a>,
            location: { lat: 48.040435, lng: 11.6577076136342 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de14070700002004&product=HOURLY_RENTAL'
        },
        {
            id: 63,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15090200001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Nürnberg</a>,
            location: { lat: 49.4847378, lng: 11.0089251 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15090200001009&product=HOURLY_RENTAL'
        },
        {
            id: 64,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15102900008003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Oldenburg</a>,
            location: { lat: 53.1394045, lng: 8.2553268 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15102900008003&product=HOURLY_RENTAL'
        },
        {
            id: 65,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15091400005000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Osnabrück</a>,
            location: { lat: 52.2634046, lng: 7.970395 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15091400005000&product=HOURLY_RENTAL'
        },
        {
            id: 66,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15082900003002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Regensburg</a>,
            location: { lat: 49.0016002, lng: 12.1774422 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15082900003002&product=HOURLY_RENTAL'
        },
        {
            id: 67,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15102100001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Saarlouis</a>,
            location: { lat: 49.2934807, lng: 6.7595919 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15102100001007&product=HOURLY_RENTAL'
        },
        {
            id: 68,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15093000002009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Siegen</a>,
            location: { lat: 50.8623002, lng: 8.0026574 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15093000002009&product=HOURLY_RENTAL'
        },
        {
            id: 69,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15082900002003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Sindelfingen</a>,
            location: { lat: 48.7011136, lng: 9.0053317 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15082900002003&product=HOURLY_RENTAL'
        },
        {
            id: 70,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15082900001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Ulm</a>,
            location: { lat: 48.4035754, lng: 9.9762498 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15082900001004&product=HOURLY_RENTAL'
        },
        {
            id: 71,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15102900003008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Wallau ͛ </a>,
            location: { lat: 50.0580183, lng: 8.37144655439489 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15102900003008&product=HOURLY_RENTAL'
        },
        {
            id: 72,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15102900005006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Walldorf</a>,
            location: { lat: 49.305389, lng: 8.6294402 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15102900005006&product=HOURLY_RENTAL'
        },
        {
            id: 73,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de17050800001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Wetzlar</a>,
            location: { lat: 50.56892745, lng: 8.49715144 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de17050800001000&product=HOURLY_RENTAL'
        },
        {
            id: 74,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de16090700001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Wuppertal ͛ </a>,
            location: { lat: 51.3101585, lng: 7.2546967 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de16090700001006&product=HOURLY_RENTAL'
        },
        {
            id: 75,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de15101900001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Würzburg</a>,
            location: { lat: 49.8219671, lng: 9.9831722 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de15101900001001&product=HOURLY_RENTAL'
        },
        {
            id: 76,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de22121900001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leistritz Extrusionstechnik GmbH</a>,
            location: { lat: 49.4321584, lng: 11.074937 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de22121900001003&product=HOURLY_RENTAL'
        },
        {
            id: 77,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de17050200001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>CarPool Köln</a>,
            location: { lat: 50.8816777, lng: 7.11290867029769 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de17050200001003&product=HOURLY_RENTAL'
        },
        {
            id: 78,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19021300001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>CarPool Rostock</a>,
            location: { lat: 53.9122199, lng: 12.2917869 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19021300001003&product=HOURLY_RENTAL'
        },
        {
            id: 79,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20033100001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>MÖBEL MARTIN Ensdorf</a>,
            location: { lat: 49.2932614, lng: 6.77768884783975 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20033100001001&product=HOURLY_RENTAL'
        },
        {
            id: 80,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20033100002000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>MÖBEL MARTIN Kaiserslautern</a>,
            location: { lat: 49.4561197, lng: 7.8091533 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20033100002000&product=HOURLY_RENTAL'
        },
        {
            id: 81,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20033100003009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>MÖBEL MARTIN Konz</a>,
            location: { lat: 49.7065623, lng: 6.5799977 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20033100003009&product=HOURLY_RENTAL'
        },
        {
            id: 82,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20033100004008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>MÖBEL MARTIN Mainz</a>,
            location: { lat: 49.94781045, lng: 8.25192835607931 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20033100004008&product=HOURLY_RENTAL'
        },
        {
            id: 83,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20033100005007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>MÖBEL MARTIN Saarbrücken</a>,
            location: { lat: 49.2245811, lng: 7.0238222 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20033100005007&product=HOURLY_RENTAL'
        },
        {
            id: 84,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20033100006006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>MÖBEL MARTIN Zweibrücken</a>,
            location: { lat: 49.2512006, lng: 7.3436565 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20033100006006&product=HOURLY_RENTAL'
        },
        {
            id: 85,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23062100001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>OBI Bad Godesberg</a>,
            location: { lat: 50.6948073, lng: 7.1390949 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23062100001009&product=HOURLY_RENTAL'
        },
        {
            id: 86,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23070500001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>OBI Bonn Nord</a>,
            location: { lat: 50.7384349999999, lng: 7.08090909198717 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23070500001000&product=HOURLY_RENTAL'
        },
        {
            id: 87,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23031700002003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>OBI Köln Dellbrück</a>,
            location: { lat: 50.9763156, lng: 7.0557897 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23031700002003&product=HOURLY_RENTAL'
        },
        {
            id: 88,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23031700001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>OBI Rösrath</a>,
            location: { lat: 50.88333, lng: 7.1886789 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23031700001004&product=HOURLY_RENTAL'
        },
        {
            id: 89,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de17101100001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>OBI Carlswerkstr. </a>,
            location: { lat: 50.96586, lng: 7.017763 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de17101100001004&product=HOURLY_RENTAL'
        },
        {
            id: 90,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de17082500002007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>OBI Schanzenstr</a>,
            location: { lat: 50.970149, lng: 7.016299 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de17082500002007&product=HOURLY_RENTAL'
        },
        {
            id: 91,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23091500001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>OBI Services GmbH </a>,
            location: { lat: 51.1459642, lng: 7.248679 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23091500001005&product=HOURLY_RENTAL'
        },
        {
            id: 92,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19071700003001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Ostermann Bottrop</a>,
            location: { lat: 51.5329418, lng: 6.98207443528802 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19071700003001&product=HOURLY_RENTAL'
        },
        {
            id: 93,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19071700002002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Ostermann Haan</a>,
            location: { lat: 51.2020303, lng: 7.0350987 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19071700002002&product=HOURLY_RENTAL'
        },
        {
            id: 94,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19071700005009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Ostermann Leverkusen</a>,
            location: { lat: 51.0277189, lng: 6.9897931 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19071700005009&product=HOURLY_RENTAL'
        },
        {
            id: 95,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19071700004000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Ostermann Recklinghausen</a>,
            location: { lat: 51.5963753, lng: 7.24869721674133 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19071700004000&product=HOURLY_RENTAL'
        },
        {
            id: 96,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19071700001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Ostermann Witten Abhollager</a>,
            location: { lat: 51.4553287, lng: 7.39154 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19071700001003&product=HOURLY_RENTAL'
        },
        {
            id: 97,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23082300001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>pack zu Altenkirchen</a>,
            location: { lat: 50.6944975499999, lng: 7.64269480426993 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23082300001001&product=HOURLY_RENTAL'
        },
        {
            id: 98,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23082300002000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>pack zu Betzdorf</a>,
            location: { lat: 50.7982917, lng: 7.86097079075261 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23082300002000&product=HOURLY_RENTAL'
        },
        {
            id: 99,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23082300003009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>pack zu Limburg/Ahlbach</a>,
            location: { lat: 50.4370886, lng: 8.0919998 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23082300003009&product=HOURLY_RENTAL'
        },
        {
            id: 100,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23020600008002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Ahlen Außenlager</a>,
            location: { lat: 51.7659551499999, lng: 7.9095868506357 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23020600008002&product=HOURLY_RENTAL'
        },
        {
            id: 101,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20051400001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Altötting</a>,
            location: { lat: 48.2261387, lng: 12.6711407 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20051400001002&product=HOURLY_RENTAL'
        },
        {
            id: 102,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23020600001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Arnsberg</a>,
            location: { lat: 51.4294406, lng: 8.0037226 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23020600001009&product=HOURLY_RENTAL'
        },
        {
            id: 103,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de22120500001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Augsburg</a>,
            location: { lat: 48.319428, lng: 10.8893274 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de22120500001002&product=HOURLY_RENTAL'
        },
        {
            id: 104,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21011800003008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Bardowick</a>,
            location: { lat: 53.2878029, lng: 10.3877511511124 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21011800003008&product=HOURLY_RENTAL'
        },
        {
            id: 105,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23020600007003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Bergkamen</a>,
            location: { lat: 51.6421237499999, lng: 7.672106563693 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23020600007003&product=HOURLY_RENTAL'
        },
        {
            id: 106,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21011800004007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Berlin-Adlershof</a>,
            location: { lat: 52.4310461, lng: 13.5501732 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21011800004007&product=HOURLY_RENTAL'
        },
        {
            id: 107,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de22110700001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Berlin-Britz</a>,
            location: { lat: 52.423598, lng: 13.436705 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de22110700001000&product=HOURLY_RENTAL'
        },
        {
            id: 108,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de22031700004003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Biberach</a>,
            location: { lat: 48.1136973, lng: 9.8032452 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de22031700004003&product=HOURLY_RENTAL'
        },
        {
            id: 109,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21031500002001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Bielefeld</a>,
            location: { lat: 52.0016623, lng: 8.57647847589662 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21031500002001&product=HOURLY_RENTAL'
        },
        {
            id: 110,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de17062100001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Böblingen</a>,
            location: { lat: 48.685987, lng: 9.003882 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de17062100001001&product=HOURLY_RENTAL'
        },
        {
            id: 111,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19022000001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Bochum (Herne)</a>,
            location: { lat: 51.5142035, lng: 7.1897087 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19022000001008&product=HOURLY_RENTAL'
        },
        {
            id: 112,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21102700006007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Braunschweig</a>,
            location: { lat: 52.3118097499999, lng: 10.4988782475383 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21102700006007&product=HOURLY_RENTAL'
        },
        {
            id: 113,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20022400001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Bremerhaven</a>,
            location: { lat: 53.4924913, lng: 8.5997591 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20022400001008&product=HOURLY_RENTAL'
        },
        {
            id: 114,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20062300005007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Dinklage</a>,
            location: { lat: 52.6582784, lng: 8.1188671 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20062300005007&product=HOURLY_RENTAL'
        },
        {
            id: 115,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20051400002001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Dortmund</a>,
            location: { lat: 51.53754075, lng: 7.47108040426799 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20051400002001&product=HOURLY_RENTAL'
        },
        {
            id: 116,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20102000004006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Dresden-Nickern</a>,
            location: { lat: 50.9937621, lng: 13.7985412 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20102000004006&product=HOURLY_RENTAL'
        },
        {
            id: 117,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19112800001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Eching</a>,
            location: { lat: 48.308163, lng: 11.6301833 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19112800001002&product=HOURLY_RENTAL'
        },
        {
            id: 118,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21083100003006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Erfurt</a>,
            location: { lat: 50.9747873, lng: 10.954587010419 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21083100003006&product=HOURLY_RENTAL'
        },
        {
            id: 119,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de22031700001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Fellbach</a>,
            location: { lat: 48.776467, lng: 9.171896 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de22031700001006&product=HOURLY_RENTAL'
        },
        {
            id: 120,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de16072900001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Frankfurt</a>,
            location: { lat: 50.1305418, lng: 8.74008757315058 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de16072900001004&product=HOURLY_RENTAL'
        },
        {
            id: 121,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de17071900003001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Fulda-Petersberg </a>,
            location: { lat: 50.5529495, lng: 9.7192567 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de17071900003001&product=HOURLY_RENTAL'
        },
        {
            id: 122,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23020600010008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Gelsenkirchen</a>,
            location: { lat: 51.5444047, lng: 7.0789097559661 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23020600010008&product=HOURLY_RENTAL'
        },
        {
            id: 123,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23060800001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Gera</a>,
            location: { lat: 50.8920258, lng: 12.0793958662979 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23060800001006&product=HOURLY_RENTAL'
        },
        {
            id: 124,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21080300002006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Gersthofen</a>,
            location: { lat: 48.4252886999999, lng: 10.8645129247816 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21080300002006&product=HOURLY_RENTAL'
        },
        {
            id: 125,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20062300002000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Halstenbek</a>,
            location: { lat: 53.6258571, lng: 9.86380332 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20062300002000&product=HOURLY_RENTAL'
        },
        {
            id: 126,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21011200004000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Hamburg-Harburg</a>,
            location: { lat: 53.4558583, lng: 10.0089046533716 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21011200004000&product=HOURLY_RENTAL'
        },
        {
            id: 127,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de22031700007000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Hanau</a>,
            location: { lat: 50.1157103, lng: 8.92663952905363 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de22031700007000&product=HOURLY_RENTAL'
        },
        {
            id: 128,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19030500001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Hannover</a>,
            location: { lat: 52.4044133, lng: 9.7111029313327 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19030500001007&product=HOURLY_RENTAL'
        },
        {
            id: 129,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23020600002008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Hannover-Linden</a>,
            location: { lat: 52.3673123, lng: 9.7012656 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23020600002008&product=HOURLY_RENTAL'
        },
        {
            id: 130,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19120300001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Heilbronn</a>,
            location: { lat: 49.1493863, lng: 9.19575955520946 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19120300001003&product=HOURLY_RENTAL'
        },
        {
            id: 131,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20072300001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Hildesheim</a>,
            location: { lat: 52.1594893, lng: 9.93899236673121 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20072300001009&product=HOURLY_RENTAL'
        },
        {
            id: 132,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19091800001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Homburg</a>,
            location: { lat: 49.3250261, lng: 7.33490706325162 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19091800001007&product=HOURLY_RENTAL'
        },
        {
            id: 133,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20111600006000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Ingolstadt</a>,
            location: { lat: 48.78009905, lng: 11.43127258873 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20111600006000&product=HOURLY_RENTAL'
        },
        {
            id: 134,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de22112100001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Kaiserslautern</a>,
            location: { lat: 49.442499, lng: 7.7199173 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de22112100001001&product=HOURLY_RENTAL'
        },
        {
            id: 135,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21121500001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Kassel</a>,
            location: { lat: 51.2790388, lng: 9.53000736737121 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21121500001003&product=HOURLY_RENTAL'
        },
        {
            id: 136,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de17041200007009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Kiel</a>,
            location: { lat: 54.2885602, lng: 10.2223848759596 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de17041200007009&product=HOURLY_RENTAL'
        },
        {
            id: 137,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de22031700006001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Kitzingen</a>,
            location: { lat: 49.7556683, lng: 10.1791804 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de22031700006001&product=HOURLY_RENTAL'
        },
        {
            id: 138,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23032200001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Koblenz</a>,
            location: { lat: 50.382479, lng: 7.566061 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23032200001004&product=HOURLY_RENTAL'
        },
        {
            id: 139,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de16112800001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Kreuztal</a>,
            location: { lat: 50.9673933, lng: 8.04051695953671 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de16112800001008&product=HOURLY_RENTAL'
        },
        {
            id: 140,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21031500003000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Lübeck </a>,
            location: { lat: 53.8608876, lng: 10.6244296 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21031500003000&product=HOURLY_RENTAL'
        },
        {
            id: 141,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21070500001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Lüdenscheid</a>,
            location: { lat: 51.2084315, lng: 7.66703870823237 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21070500001004&product=HOURLY_RENTAL'
        },
        {
            id: 142,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20102000001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Magdeburg</a>,
            location: { lat: 52.1550988, lng: 11.6485332 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20102000001009&product=HOURLY_RENTAL'
        },
        {
            id: 143,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20062300001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Mainz-Bretzenheim</a>,
            location: { lat: 49.9685839, lng: 8.24181885854833 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20062300001001&product=HOURLY_RENTAL'
        },
        {
            id: 144,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21102700002001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Mannheim</a>,
            location: { lat: 49.4540152, lng: 8.5132615 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21102700002001&product=HOURLY_RENTAL'
        },
        {
            id: 145,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18021400001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO München-Freimann</a>,
            location: { lat: 48.1932697, lng: 11.5936629677025 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18021400001003&product=HOURLY_RENTAL'
        },
        {
            id: 146,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21121500003001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO München-Trudering </a>,
            location: { lat: 48.1276251, lng: 11.6596561 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21121500003001&product=HOURLY_RENTAL'
        },
        {
            id: 147,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23020600009001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Münster</a>,
            location: { lat: 51.9259284, lng: 7.5813098 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23020600009001&product=HOURLY_RENTAL'
        },
        {
            id: 148,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19091700003007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Neumünster</a>,
            location: { lat: 54.0455162, lng: 9.99766800460112 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19091700003007&product=HOURLY_RENTAL'
        },
        {
            id: 149,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21031500001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Nobitz</a>,
            location: { lat: 50.9804, lng: 12.484367 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21031500001002&product=HOURLY_RENTAL'
        },
        {
            id: 150,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21011800006005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Nordhausen</a>,
            location: { lat: 51.4822041, lng: 10.8110048274521 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21011800006005&product=HOURLY_RENTAL'
        },
        {
            id: 151,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20111200004001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Nürnberg Außenlager</a>,
            location: { lat: 49.4234052, lng: 11.1373898 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20111200004001&product=HOURLY_RENTAL'
        },
        {
            id: 152,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21032500002000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Nürnberg-Sündersbühl</a>,
            location: { lat: 49.4472738, lng: 11.0448888 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21032500002000&product=HOURLY_RENTAL'
        },
        {
            id: 153,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19091700002008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Oldenburg</a>,
            location: { lat: 53.1371180999999, lng: 8.2289625 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19091700002008&product=HOURLY_RENTAL'
        },
        {
            id: 154,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de22110700002009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Osnabrück</a>,
            location: { lat: 52.2622356, lng: 8.07428170923875 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de22110700002009&product=HOURLY_RENTAL'
        },
        {
            id: 155,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23020600003007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Paderborn</a>,
            location: { lat: 51.70671, lng: 8.72139193013987 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23020600003007&product=HOURLY_RENTAL'
        },
        {
            id: 156,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21061000002005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Pforzheim</a>,
            location: { lat: 48.9030852, lng: 8.66010944 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21061000002005&product=HOURLY_RENTAL'
        },
        {
            id: 157,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de22081500001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Regensburg</a>,
            location: { lat: 49.0272371, lng: 12.113222835114 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de22081500001009&product=HOURLY_RENTAL'
        },
        {
            id: 158,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23020600004006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Rheda-Wiedenbrück</a>,
            location: { lat: 51.8469633, lng: 8.29521027792359 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23020600004006&product=HOURLY_RENTAL'
        },
        {
            id: 159,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18102300002006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Saarlouis</a>,
            location: { lat: 49.3218132, lng: 6.77298473798553 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18102300002006&product=HOURLY_RENTAL'
        },
        {
            id: 160,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23020600005005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Salzbergen</a>,
            location: { lat: 52.3330517, lng: 7.41694321953779 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23020600005005&product=HOURLY_RENTAL'
        },
        {
            id: 161,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20022600001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Singen</a>,
            location: { lat: 47.7534376499999, lng: 8.87155652767938 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20022600001003&product=HOURLY_RENTAL'
        },
        {
            id: 162,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20051400003000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Stuttgart-Bad Cannstatt</a>,
            location: { lat: 48.8116538999999, lng: 9.21841072293349 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20051400003000&product=HOURLY_RENTAL'
        },
        {
            id: 163,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20062300003009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Viernheim</a>,
            location: { lat: 49.5249680999999, lng: 8.55780575553741 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20062300003009&product=HOURLY_RENTAL'
        },
        {
            id: 164,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21011800007004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Weiterstadt</a>,
            location: { lat: 49.8980875, lng: 8.61304001596892 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21011800007004&product=HOURLY_RENTAL'
        },
        {
            id: 165,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18100900001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Wetzlar Außenlager</a>,
            location: { lat: 50.5707315, lng: 8.48656708 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18100900001006&product=HOURLY_RENTAL'
        },
        {
            id: 166,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21051000001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Wilhelmshaven</a>,
            location: { lat: 53.5758204499999, lng: 8.1077209 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21051000001008&product=HOURLY_RENTAL'
        },
        {
            id: 167,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23020600006004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Wülfrath</a>,
            location: { lat: 51.2813269, lng: 7.02782050131655 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23020600006004&product=HOURLY_RENTAL'
        },
        {
            id: 168,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de22031700005002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>POCO Würzburg</a>,
            location: { lat: 49.8035325, lng: 9.89406711 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de22031700005002&product=HOURLY_RENTAL'
        },
        {
            id: 169,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19110500001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Möbel RIEGER Aalen</a>,
            location: { lat: 48.8261207, lng: 10.0664281 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19110500001000&product=HOURLY_RENTAL'
        },
        {
            id: 170,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19101400001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Möbel RIEGER Abhollager Esslingen</a>,
            location: { lat: 48.7162117999999, lng: 9.35254203320905 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19101400001004&product=HOURLY_RENTAL'
        },
        {
            id: 171,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18112700001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Möbel RIEGER Abhollager Göppingen </a>,
            location: { lat: 48.6904377, lng: 9.6696916 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18112700001006&product=HOURLY_RENTAL'
        },
        {
            id: 172,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19031800004007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Möbel RIEGER Gera</a>,
            location: { lat: 50.9061188, lng: 12.066617 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19031800004007&product=HOURLY_RENTAL'
        },
        {
            id: 173,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19052300005001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Möbel RIEGER Heilbronn</a>,
            location: { lat: 49.1533627, lng: 9.20216979584424 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19052300005001&product=HOURLY_RENTAL'
        },
        {
            id: 174,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19031800003008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Möbel RIEGER Mönchenholzhausen</a>,
            location: { lat: 50.9712136, lng: 11.1454759 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19031800003008&product=HOURLY_RENTAL'
        },
        {
            id: 175,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19110500002009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Möbel RIEGER Reutlingen</a>,
            location: { lat: 48.5000538, lng: 9.19995 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19110500002009&product=HOURLY_RENTAL'
        },
        {
            id: 176,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18083100008007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Alsfeld</a>,
            location: { lat: 50.74278225, lng: 9.25402379084999 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18083100008007&product=HOURLY_RENTAL'
        },
        {
            id: 177,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18073100001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Alzey</a>,
            location: { lat: 49.747171, lng: 8.13956490581734 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18073100001006&product=HOURLY_RENTAL'
        },
        {
            id: 178,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18100400009009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Annaberg-Buchholz</a>,
            location: { lat: 50.598371, lng: 13.0204379 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18100400009009&product=HOURLY_RENTAL'
        },
        {
            id: 179,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18083100011002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Augsburg</a>,
            location: { lat: 48.4006706, lng: 10.8785346204227 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18083100011002&product=HOURLY_RENTAL'
        },
        {
            id: 180,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110500013008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Bautzen</a>,
            location: { lat: 51.18962575, lng: 14.4548968564249 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110500013008&product=HOURLY_RENTAL'
        },
        {
            id: 181,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18091000014001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Bayreuth</a>,
            location: { lat: 49.9683083, lng: 11.6070040666292 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18091000014001&product=HOURLY_RENTAL'
        },
        {
            id: 182,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18083100007008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Bebra</a>,
            location: { lat: 50.9588904999999, lng: 9.79944721525173 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18083100007008&product=HOURLY_RENTAL'
        },
        {
            id: 183,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18112800001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Berlin Adlershof</a>,
            location: { lat: 52.438018, lng: 13.538613 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18112800001004&product=HOURLY_RENTAL'
        },
        {
            id: 184,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de17122000004008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Berlin Mahlsdorf</a>,
            location: { lat: 52.5052517, lng: 13.6126308 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de17122000004008&product=HOURLY_RENTAL'
        },
        {
            id: 185,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de17122000003009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Berlin-Steglitz</a>,
            location: { lat: 52.4158485, lng: 13.2870549 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de17122000003009&product=HOURLY_RENTAL'
        },
        {
            id: 186,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18073100002005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Bernburg</a>,
            location: { lat: 51.7768192, lng: 11.7355799 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18073100002005&product=HOURLY_RENTAL'
        },
        {
            id: 187,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110500014007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Beuna</a>,
            location: { lat: 51.3181787, lng: 11.947773 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110500014007&product=HOURLY_RENTAL'
        },
        {
            id: 188,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18091400002007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Bischofsheim</a>,
            location: { lat: 49.975923, lng: 8.3790263 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18091400002007&product=HOURLY_RENTAL'
        },
        {
            id: 189,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18101500004000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Bous</a>,
            location: { lat: 49.2839161499999, lng: 6.78213319999999 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18101500004000&product=HOURLY_RENTAL'
        },
        {
            id: 190,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18083100001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Brandenburg</a>,
            location: { lat: 52.4331674, lng: 12.5464656 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18083100001004&product=HOURLY_RENTAL'
        },
        {
            id: 191,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18092400001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Bremen</a>,
            location: { lat: 53.1371733, lng: 8.7440222 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18092400001007&product=HOURLY_RENTAL'
        },
        {
            id: 192,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18091400005004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Celle</a>,
            location: { lat: 52.6366115, lng: 10.073728 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18091400005004&product=HOURLY_RENTAL'
        },
        {
            id: 193,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110500008005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Chemnitz</a>,
            location: { lat: 50.8037596, lng: 12.9188537070653 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110500008005&product=HOURLY_RENTAL'
        },
        {
            id: 194,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110500002001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Cottbus</a>,
            location: { lat: 51.7092834, lng: 14.3545268169372 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110500002001&product=HOURLY_RENTAL'
        },
        {
            id: 195,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18100400016000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Crailsheim</a>,
            location: { lat: 49.142526, lng: 10.028644 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18100400016000&product=HOURLY_RENTAL'
        },
        {
            id: 196,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18052200001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Cuxhaven</a>,
            location: { lat: 53.8544390499999, lng: 8.71167184 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18052200001009&product=HOURLY_RENTAL'
        },
        {
            id: 197,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18012200007002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Darmstadt</a>,
            location: { lat: 49.8894099, lng: 8.6465234 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18012200007002&product=HOURLY_RENTAL'
        },
        {
            id: 198,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18100400010006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Dinslaken</a>,
            location: { lat: 51.5584248499999, lng: 6.74100035540654 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18100400010006&product=HOURLY_RENTAL'
        },
        {
            id: 199,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18101500005009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Dormagen</a>,
            location: { lat: 51.09369055, lng: 6.8068959715546 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18101500005009&product=HOURLY_RENTAL'
        },
        {
            id: 200,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18100400004004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Dortmund</a>,
            location: { lat: 51.501231, lng: 7.570674 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18100400004004&product=HOURLY_RENTAL'
        },
        {
            id: 201,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110500004009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Dortmund-Oespel</a>,
            location: { lat: 51.5001803, lng: 7.4144707550756 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110500004009&product=HOURLY_RENTAL'
        },
        {
            id: 202,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110500012009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Dresden-Heidenau</a>,
            location: { lat: 50.9793304, lng: 13.8514192 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110500012009&product=HOURLY_RENTAL'
        },
        {
            id: 203,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110800003004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Düsseldorf</a>,
            location: { lat: 51.2194921, lng: 6.8087714 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110800003004&product=HOURLY_RENTAL'
        },
        {
            id: 204,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18083100010003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Eching</a>,
            location: { lat: 48.3053923, lng: 11.6357974199755 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18083100010003&product=HOURLY_RENTAL'
        },
        {
            id: 205,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18091000010005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Eisenhüttenstadt</a>,
            location: { lat: 52.1536608, lng: 14.6327800272402 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18091000010005&product=HOURLY_RENTAL'
        },
        {
            id: 206,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18012200001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Elmshorn</a>,
            location: { lat: 53.7408983499999, lng: 9.70797991633679 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18012200001008&product=HOURLY_RENTAL'
        },
        {
            id: 207,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18100400003005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Erfurt</a>,
            location: { lat: 51.0199735, lng: 11.0096356 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18100400003005&product=HOURLY_RENTAL'
        },
        {
            id: 208,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18100400011005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Essen</a>,
            location: { lat: 51.4611476, lng: 6.9999173 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18100400011005&product=HOURLY_RENTAL'
        },
        {
            id: 209,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110800001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Euskirchen</a>,
            location: { lat: 50.6484995, lng: 6.80305397 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110800001006&product=HOURLY_RENTAL'
        },
        {
            id: 210,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18091000016009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Finowfurt-Schorfheide</a>,
            location: { lat: 52.8501771, lng: 13.6820744 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18091000016009&product=HOURLY_RENTAL'
        },
        {
            id: 211,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18092400004004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Flensburg</a>,
            location: { lat: 54.7621762, lng: 9.4311389 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18092400004004&product=HOURLY_RENTAL'
        },
        {
            id: 212,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110800008009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Freiburg</a>,
            location: { lat: 48.0297098, lng: 7.8428299404392 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110800008009&product=HOURLY_RENTAL'
        },
        {
            id: 213,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110500001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Fuldabrück</a>,
            location: { lat: 51.27283015, lng: 9.4983385732396 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110500001002&product=HOURLY_RENTAL'
        },
        {
            id: 214,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18091000009008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Fürstenwalde</a>,
            location: { lat: 52.3354789, lng: 14.0842011 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18091000009008&product=HOURLY_RENTAL'
        },
        {
            id: 215,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18091400006003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Garbsen</a>,
            location: { lat: 52.429251, lng: 9.623896 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18091400006003&product=HOURLY_RENTAL'
        },
        {
            id: 216,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18101500010002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Gelsenkirchen</a>,
            location: { lat: 51.5483040999999, lng: 7.07759213825856 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18101500010002&product=HOURLY_RENTAL'
        },
        {
            id: 217,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18101500003001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Georgsmarienhütte</a>,
            location: { lat: 52.2194244, lng: 8.06188159271965 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18101500003001&product=HOURLY_RENTAL'
        },
        {
            id: 218,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18100400005003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Gera</a>,
            location: { lat: 50.8981367, lng: 12.0992947 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18100400005003&product=HOURLY_RENTAL'
        },
        {
            id: 219,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18100400015001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Görlitz</a>,
            location: { lat: 51.1707176, lng: 14.9658105491992 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18100400015001&product=HOURLY_RENTAL'
        },
        {
            id: 220,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18090400002008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Goslar</a>,
            location: { lat: 51.9317253, lng: 10.41113189956 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18090400002008&product=HOURLY_RENTAL'
        },
        {
            id: 221,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18100400006002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Gotha</a>,
            location: { lat: 50.9270094, lng: 10.7165122450518 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18100400006002&product=HOURLY_RENTAL'
        },
        {
            id: 222,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18101500006008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Gronau</a>,
            location: { lat: 52.207816, lng: 7.03739316058187 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18101500006008&product=HOURLY_RENTAL'
        },
        {
            id: 223,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18091000012003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Güstrow</a>,
            location: { lat: 53.7993121, lng: 12.2162777 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18091000012003&product=HOURLY_RENTAL'
        },
        {
            id: 224,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18083100009006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Halberstadt</a>,
            location: { lat: 51.8833862, lng: 11.0737062923044 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18083100009006&product=HOURLY_RENTAL'
        },
        {
            id: 225,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18012200003006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Halstenbek - Außenlager</a>,
            location: { lat: 53.6304289, lng: 9.8586729 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18012200003006&product=HOURLY_RENTAL'
        },
        {
            id: 226,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de17122000001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Hamburg Hummelsbüttel</a>,
            location: { lat: 53.6501507, lng: 10.0360666709313 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de17122000001001&product=HOURLY_RENTAL'
        },
        {
            id: 227,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18012200002007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Hamburg-Harburg</a>,
            location: { lat: 53.4606721, lng: 10.0095910854611 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18012200002007&product=HOURLY_RENTAL'
        },
        {
            id: 228,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18012200004005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Hanau</a>,
            location: { lat: 50.1475718, lng: 8.9318578 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18012200004005&product=HOURLY_RENTAL'
        },
        {
            id: 229,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110800009008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Heidenheim an der Brenz</a>,
            location: { lat: 48.6797488, lng: 10.1275105 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110800009008&product=HOURLY_RENTAL'
        },
        {
            id: 230,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18083100002003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Heuchelheim</a>,
            location: { lat: 50.57721845, lng: 8.63350531610597 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18083100002003&product=HOURLY_RENTAL'
        },
        {
            id: 231,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18091400003006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Hildesheim</a>,
            location: { lat: 52.1655347, lng: 9.97058568236252 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18091400003006&product=HOURLY_RENTAL'
        },
        {
            id: 232,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18101500007007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Hückelhoven</a>,
            location: { lat: 51.0560306999999, lng: 6.21591217553981 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18101500007007&product=HOURLY_RENTAL'
        },
        {
            id: 233,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18091000008009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Ingolstadt</a>,
            location: { lat: 48.7046471, lng: 11.4265798257149 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18091000008009&product=HOURLY_RENTAL'
        },
        {
            id: 234,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18101500002002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Kerpen</a>,
            location: { lat: 50.8835454, lng: 6.6935845 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18101500002002&product=HOURLY_RENTAL'
        },
        {
            id: 235,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18101500011001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Köln - Marsdorf</a>,
            location: { lat: 50.9211166, lng: 6.85680809472498 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18101500011001&product=HOURLY_RENTAL'
        },
        {
            id: 236,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19021900001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Krefeld</a>,
            location: { lat: 51.348895, lng: 6.5410319 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19021900001000&product=HOURLY_RENTAL'
        },
        {
            id: 237,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18100400002006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Leipzig</a>,
            location: { lat: 51.3479766, lng: 12.2659958133787 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18100400002006&product=HOURLY_RENTAL'
        },
        {
            id: 238,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18101500013009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Leopoldshöhe (Asemissen)</a>,
            location: { lat: 52.0125239, lng: 8.6987431 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18101500013009&product=HOURLY_RENTAL'
        },
        {
            id: 239,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de22072500004007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Lotte</a>,
            location: { lat: 52.3067255, lng: 7.948371 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de22072500004007&product=HOURLY_RENTAL'
        },
        {
            id: 240,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18102200004006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Ludwigsburg</a>,
            location: { lat: 48.9218528, lng: 9.1444528 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18102200004006&product=HOURLY_RENTAL'
        },
        {
            id: 241,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18102200003007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Ludwigshafen-Oggersheim</a>,
            location: { lat: 49.4856422, lng: 8.35621639935034 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18102200003007&product=HOURLY_RENTAL'
        },
        {
            id: 242,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18082200001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Magdeburg</a>,
            location: { lat: 52.1595871, lng: 11.6063029 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18082200001003&product=HOURLY_RENTAL'
        },
        {
            id: 243,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18102200001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Mannheim</a>,
            location: { lat: 49.5481218, lng: 8.4558208 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18102200001009&product=HOURLY_RENTAL'
        },
        {
            id: 244,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18100400007001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Markkleeberg</a>,
            location: { lat: 51.2767322, lng: 12.3635821 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18100400007001&product=HOURLY_RENTAL'
        },
        {
            id: 245,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19021900002009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Mönchengladbach</a>,
            location: { lat: 51.1971813, lng: 6.45254852627254 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19021900002009&product=HOURLY_RENTAL'
        },
        {
            id: 246,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18101500001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Mülheim-Kärlich</a>,
            location: { lat: 50.3949909, lng: 7.5305895 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18101500001003&product=HOURLY_RENTAL'
        },
        {
            id: 247,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18083100006009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Neu-Ulm</a>,
            location: { lat: 48.3966095, lng: 10.0193857754802 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18083100006009&product=HOURLY_RENTAL'
        },
        {
            id: 248,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18092400003005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Neumünster</a>,
            location: { lat: 54.0833149, lng: 9.978856 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18092400003005&product=HOURLY_RENTAL'
        },
        {
            id: 249,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18092800002007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Nordhausen</a>,
            location: { lat: 51.489572, lng: 10.828416 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18092800002007&product=HOURLY_RENTAL'
        },
        {
            id: 250,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18012200005004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Offenbach</a>,
            location: { lat: 50.1083193, lng: 8.7360589 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18012200005004&product=HOURLY_RENTAL'
        },
        {
            id: 251,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110800007000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Offenburg</a>,
            location: { lat: 48.4436761, lng: 7.93139952561913 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110800007000&product=HOURLY_RENTAL'
        },
        {
            id: 252,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18041800002009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Oranienburg</a>,
            location: { lat: 52.7529379, lng: 13.2457591 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18041800002009&product=HOURLY_RENTAL'
        },
        {
            id: 253,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110500007006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Oschatz</a>,
            location: { lat: 51.2992354, lng: 13.0949876175607 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110500007006&product=HOURLY_RENTAL'
        },
        {
            id: 254,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18101500014008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Paderborn</a>,
            location: { lat: 51.731468, lng: 8.7873365 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18101500014008&product=HOURLY_RENTAL'
        },
        {
            id: 255,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de19010200001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Ratingen</a>,
            location: { lat: 51.2966389, lng: 6.83251399936098 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de19010200001008&product=HOURLY_RENTAL'
        },
        {
            id: 256,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18102400002004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Reutlingen</a>,
            location: { lat: 48.502631, lng: 9.20654553071597 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18102400002004&product=HOURLY_RENTAL'
        },
        {
            id: 257,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18101500008006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Rhede</a>,
            location: { lat: 51.8333418, lng: 6.7067593 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18101500008006&product=HOURLY_RENTAL'
        },
        {
            id: 258,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18083100004001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Rosbach</a>,
            location: { lat: 50.2990407, lng: 8.6931233 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18083100004001&product=HOURLY_RENTAL'
        },
        {
            id: 259,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110500011000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Schwelm</a>,
            location: { lat: 51.2907591, lng: 7.28284876132338 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110500011000&product=HOURLY_RENTAL'
        },
        {
            id: 260,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18092400005003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Schwentinental</a>,
            location: { lat: 54.287047, lng: 10.2170539910593 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18092400005003&product=HOURLY_RENTAL'
        },
        {
            id: 261,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18091000002005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Schwerin</a>,
            location: { lat: 53.6598847, lng: 11.358085 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18091000002005&product=HOURLY_RENTAL'
        },
        {
            id: 262,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18101500012000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Senden (Bösensell)</a>,
            location: { lat: 51.8549598, lng: 7.4883046 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18101500012000&product=HOURLY_RENTAL'
        },
        {
            id: 263,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18091000011004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Sievershagen (Rostock)</a>,
            location: { lat: 54.0952268, lng: 12.0720148 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18091000011004&product=HOURLY_RENTAL'
        },
        {
            id: 264,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18120300002004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Singen</a>,
            location: { lat: 47.7530812, lng: 8.85286590538708 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18120300002004&product=HOURLY_RENTAL'
        },
        {
            id: 265,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110800012003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Sinsheim</a>,
            location: { lat: 49.2456955, lng: 8.88521122081808 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110800012003&product=HOURLY_RENTAL'
        },
        {
            id: 266,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18100400008000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Soest</a>,
            location: { lat: 51.5641729, lng: 8.08518047 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18100400008000&product=HOURLY_RENTAL'
        },
        {
            id: 267,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110500009004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Sömmerda</a>,
            location: { lat: 51.1558442, lng: 11.1461310487757 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110500009004&product=HOURLY_RENTAL'
        },
        {
            id: 268,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110800005002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Speyer</a>,
            location: { lat: 49.333823, lng: 8.4430353 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110800005002&product=HOURLY_RENTAL'
        },
        {
            id: 269,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18101500009005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER St. Ingbert</a>,
            location: { lat: 49.2715337, lng: 7.12917580173713 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18101500009005&product=HOURLY_RENTAL'
        },
        {
            id: 270,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18083100003002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Stendal</a>,
            location: { lat: 52.60863385, lng: 11.840138864595 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18083100003002&product=HOURLY_RENTAL'
        },
        {
            id: 271,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18092400002006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Stuhr</a>,
            location: { lat: 53.0097418999999, lng: 8.70428917277795 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18092400002006&product=HOURLY_RENTAL'
        },
        {
            id: 272,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110500016005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Teutschenthal</a>,
            location: { lat: 51.4627638, lng: 11.9037448610221 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110500016005&product=HOURLY_RENTAL'
        },
        {
            id: 273,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18102400001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Villingen-Schwenningen </a>,
            location: { lat: 48.0666944, lng: 8.448880447228 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18102400001005&product=HOURLY_RENTAL'
        },
        {
            id: 274,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110800013002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Waghäusel</a>,
            location: { lat: 49.2187859, lng: 8.5338481 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110800013002&product=HOURLY_RENTAL'
        },
        {
            id: 275,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18120300001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Waiblingen</a>,
            location: { lat: 48.8222345, lng: 9.2965539 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18120300001005&product=HOURLY_RENTAL'
        },
        {
            id: 276,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110500003000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Weimar</a>,
            location: { lat: 50.9811546, lng: 11.3811263 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110500003000&product=HOURLY_RENTAL'
        },
        {
            id: 277,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110800011004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Weinheim</a>,
            location: { lat: 49.5448997999999, lng: 8.66181006431578 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110800011004&product=HOURLY_RENTAL'
        },
        {
            id: 278,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110500010001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Weißenfels (Leißling) </a>,
            location: { lat: 51.1845857, lng: 11.9270824 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110500010001&product=HOURLY_RENTAL'
        },
        {
            id: 279,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18012200006003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Wentorf</a>,
            location: { lat: 53.487696, lng: 10.2715624442556 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18012200006003&product=HOURLY_RENTAL'
        },
        {
            id: 280,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18100400012004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Werdau</a>,
            location: { lat: 50.7102736, lng: 12.4152715 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18100400012004&product=HOURLY_RENTAL'
        },
        {
            id: 281,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18110800004003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Wiehl</a>,
            location: { lat: 50.9704019, lng: 7.5261291 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18110800004003&product=HOURLY_RENTAL'
        },
        {
            id: 282,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18091000003004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Wismar</a>,
            location: { lat: 53.90499275, lng: 11.408488885433 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18091000003004&product=HOURLY_RENTAL'
        },
        {
            id: 283,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de17101100002003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Wittlich</a>,
            location: { lat: 49.9782351, lng: 6.88945990690706 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de17101100002003&product=HOURLY_RENTAL'
        },
        {
            id: 284,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18100400013003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Zella-Mehlis</a>,
            location: { lat: 50.6433309, lng: 10.6877394 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18100400013003&product=HOURLY_RENTAL'
        },
        {
            id: 285,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de18100400014002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>ROLLER Zöllnitz (Jena)</a>,
            location: { lat: 50.8719079, lng: 11.6350629 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de18100400014002&product=HOURLY_RENTAL'
        },
        {
            id: 286,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23052500002001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Knuffmann Lager Krefeld</a>,
            location: { lat: 51.325144, lng: 6.5234953 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23052500002001&product=HOURLY_RENTAL'
        },
        {
            id: 287,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23052500001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Knuffmann Neuss</a>,
            location: { lat: 51.2033272, lng: 6.6845496 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23052500001002&product=HOURLY_RENTAL'
        },
        {
            id: 288,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de20111200001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Schaffrath Düsseldorf</a>,
            location: { lat: 51.2028359, lng: 6.77219708995061 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de20111200001004&product=HOURLY_RENTAL'
        },
        {
            id: 289,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de21090700002005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Schaffrath Krefeld</a>,
            location: { lat: 51.3482927, lng: 6.53505 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de21090700002005&product=HOURLY_RENTAL'
        },
        {
            id: 290,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de23052500003000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Schaffrath Lager Mönchengladbach</a>,
            location: { lat: 51.1604513, lng: 6.4984047 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de23052500003000&product=HOURLY_RENTAL'
        },
        {
            id: 291,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de22012800001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Schaffrath Mönchengladbach</a>,
            location: { lat: 51.182443, lng: 6.4442173 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de22012800001007&product=HOURLY_RENTAL'
        },
        {
            id: 292,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-de22060800001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Schaffrath Zentral- und Abhollager</a>,
            location: { lat: 51.1590912, lng: 6.5052924 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-de22060800001008&product=HOURLY_RENTAL'
        }
    ];
    
    return (
        isLoaded && (
            <>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={7}
                    options={{
                        streetViewControl: false,
                        mapTypeControl: false
                    }}

                >
                    {points.map((point) => {
                        return (
                            <div key={point.id}>
                                <Marker position={point.location} options={{
                                    icon : 'https://images.hertz.com/content/dam/hod/images/en-gb/static/Location_Pin.png',
                                }} onClick={() => {
                                    setSelectedMarker(point);
                                }} />
                            </div>
                        );
                    })}
                    {selectedMarker && (
                        <InfoWindow position={selectedMarker.location} options={{
                            pixelOffset : new window.google.maps.Size(0,-40)
                        }} onCloseClick={() => setSelectedMarker("")}> 
                            <>
                            <h6>{selectedMarker.name}</h6>
                                <button className="btn btn-warning" onClick={() => window.location.href = selectedMarker.ufoUrl}>Rezervovat nyní</button>
                            </>

                        </InfoWindow>
                    )}
                </GoogleMap>
            </>
        )
    );
};

export default Map;