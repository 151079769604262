import React from 'react';

function BodyHowitWorks() {
    return (<>
        <div className="container-fluid body-font">
            <div className="row">
                <div className="col-xs-12 col-md-6 py-md-5">
                    <p className="h6"><b>Come funziona</b></p>
                    <p className="h1"><i><b>Come iniziare con Hertz 24/7</b></i></p>
                    <p className="h6">Utilizzare Hertz 24/7® è facile. Basta creare il proprio profilo per avere accesso a tutti i veicoli Hertz 24/7® tra i nostri vari partner in Europa.</p>
                    <p className="h6">Il modo più semplice e veloce per farlo è la nostra App gratuita Hertz 24/7®. Inserite i vostri dati una volta e con la nostra funzione fotografica nell'app inviate i vostri documenti per la verifica e partite!</p>
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/howitworks-top.png" alt='' />
                </div>
            </div>

            <div className="row" style={{backgroundColor:'#F3F3F3'}}>
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br/>
                    <h1 className="display-5"><b>Come funziona Hertz 24/7</b></h1>
                    <br/>
                    <p className="h6">
                        Abbiamo reso il noleggio con Hertz24/7® così facile e veloce che potrete essere a bordo del vostro veicolo entro 15 minuti. Ecco come fare.
                    </p>
                    <br/>
                </div>
            </div>

            <div className="row" style={{backgroundColor:'#F3F3F3'}}>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_large.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>01</b></p>
                    <br/>
                    <p className="h2">Scarica l'applicazione</p>
                    <p>Scaricare l'applicazione Hertz 24/7 dal vostro app store preferito è facile e veloce, basta scansionare il QR code. </p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Register.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>02</b></p>
                    <br/>
                    <p className="h2">Registrazione</p>
                    <p>È sufficiente creare il proprio profilo e inviare i documenti per la verifica con la funzione foto dell'app.</p>                    
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Book_vehicle.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>03</b></p>
                    <br/>
                    <p className="h2">Prenota il tuo veicolo</p>
                    <p>Accedete all'app e prenotate il vostro veicolo selezionando la località e l'orario di ritiro desiderati. Avrete bisogno di una carta di credito/debito .</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step4-Start.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>04</b></p>
                    <br/>
                    <p className="h2">Inizio noleggio</p>
                    <p>A breve distanza dall'inizio del noleggio vi verrà assegnato il veicolo. Dopo l'ispezione iniziale del veicolo riceverete la chiave virtuale nell'app per sbloccare il veicolo.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step5-During.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>05</b></p>
                    <br/>
                    <p className="h2">Durante il noleggio</p>
                    <p>Durante il noleggio, se avete bisogno di più tempo, potete prolungare il noleggio. È inoltre sempre possibile accedere al contratto di noleggio. Durante l'inattività, il blocco accensione può riattivarsi. Utilizzate il pulsante di accensione nell'app prima di riavviare il veicolo.</p>

                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step6-End.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>06</b></p>
                    <br/>
                    <p className="h2">Fine noleggio</p>
                    <p>Una volta terminato, chiudete il noleggio seguendo le fasi dell'ispezione finale e la lista di controllo. La fattura sarà condivisa con voi entro 48 ore e la caparra sarà svincolata.</p>
                </div>
            </div>


            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br/>
                    <h1 className="display-5"><b>Domande frequenti</b></h1>
                    <p className="h6">
                       
                    </p>
                </div>

                <div className="col-xs-12" style={{ textAlign: 'left' }}>
                    <br /><br />
                    
                    <div className="accordion p-4" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading1">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                                    <b>Che cos'è Hertz 24/7?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse1" className="accordion-collapse collapse" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>Hertz 24/7™ vi offre la comodità e la flessibilità di avere accesso a un'ampia gamma di furgoni, per il tempo o per il tempo che desiderate. Potete noleggiare da un'ora fino a tre giorni. È inoltre possibile ritirare il veicolo a qualsiasi ora del giorno o della notte.<br/>Inoltre, l'assicurazione e la manutenzione sono incluse nella tariffa.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                                    <b>Di quali dettagli avete bisogno?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse2" className="accordion-collapse collapse" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Per iscriversi a Hertz 24/7 è necessario fornire i seguenti dati:<br/>
                                    · Una patente di guida valida a proprio nome<br/>
                                    · Una carta di credito o di debito a proprio nome<br/>
                                    · Il vostro numero di cellulare e il vostro indirizzo e-mail personale.<br/>
                                    Prima di confermare l'iscrizione, effettuiamo i necessari controlli dei documenti d'identità.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading3">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                                    <b>Come mi verrà addebitato quando prenoto un noleggio Hertz 24/7?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse3" className="accordion-collapse collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Preautorizzeremo 200€ sulla carta di credito (48) ore prima dell'inizio del noleggio o immediatamente se il noleggio inizia entro 48 ore. La prenotazione sarà confermata solo una volta ottenuta l'autorizzazione. La preautorizzazione verrà rilasciata entro un massimo di 10 giorni dalla data di emissione della fattura. Verranno detratti gli addebiti e restituito il resto. Se il costo del noleggio supera la caparra, l'importo eccedente verrà trattenuto. Si prega di notare che eventuali ritardi nella restituzione del deposito dipendono dalla banca che ha emesso la carta di credito o di debito.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading4">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                                    <b>Come sbloccare il veicolo?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse4" className="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">L'app Hertz 24/7 sarà la vostra chiave virtuale. Per aprire l'auto, premere il pulsante di sblocco sulla sezione di guida dell'app. Nota: non sarà possibile aprire l'auto fino a quando il periodo di noleggio non sarà ufficialmente iniziato, l'ispezione è stata completata e il contratto è stato firmato.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading5">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                                    <b>Chi paga il costo del carburante?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse5" className="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Il carburante viene addebitato in centesimi per chilometraggio, indicati al momento della prenotazione. Tutti i veicoli dovrebbero essere dotati di una scheda carburante sotto l'aletta parasole o all'interno del portaoggetti e di almeno un quarto di serbatoio di carburante all'inizio del noleggio. Anche la restituzione del veicolo dovrebbe avvenire in questo modo. Se la carta carburante non è presente all'inizio del noleggio, contattateci utilizzando il numero di telefono presente nella sezione Aiuto dell'app. La carta carburante fornita ha un limite di 50 euro per transazione. Le carte carburante possono essere utilizzate presso le stazioni di rifornimento IP e Total Erg e il codice pin visualizzato nell'app sarà richiesto al momento del pagamento del carburante.</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>
    );
}
export default BodyHowitWorks;
