import React from 'react';

function BodyHowitWorks() {
    return (<>
        <div className="container-fluid p-4 body-font">
            <div className="row">
                <div className="col-xs-12 col-md-6 py-md-5">
                    <p className="h6"><b>Comment cela fonctionne-t-il ?</b></p>
                    <p className="h1"><i><b>Démarrer avec Hertz 24/7</b></i></p>
                    <p className="h6">L'utilisation de Hertz 24/7® est facile. Il vous suffit de créer votre profil de membre et d'accéder à tous les services Hertz 24/7® à travers nos différents partenaires en Europe et au Royaume-Uni. La façon de procéder la plus rapide et la plus simple est d'utiliser notre application gratuite Hertz 24/7®.</p>
                    <p className="h6">Saisissez vos coordonnées une seule fois. Avec notre fonction photo dans l'application, envoyez vos documents pour vérification et c'est parti !</p>
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/howitworks-top.png" alt='' />
                </div>
            </div>

            <div className="row" style={{backgroundColor:'#F3F3F3'}}>
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br/>
                    <h1 className="display-5"><b>Comment fonctionne Hertz 24/7®</b></h1>
                    <br/>
                    <p className="h6">
                        Avec Hertz24/7®, nous avons rendu la location  si facile et si rapide que vous pouvez être à bord de votre véhicule dans les 15 minutes. Voici comment nous procédons.
                    </p>
                    <br/>
                </div>
            </div>

            <div className="row" style={{backgroundColor:'#F3F3F3'}}>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_large.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>01</b></p>
                    <br/>
                    <p className="h2">Télécharger l'application</p>
                    <p>Il est facile et rapide de télécharger l'application Hertz 24/7 à partir de votre magasin d'applications préféré. Vous pouvez simplement scanner le QR Code.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Register.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>02</b></p>
                    <br/>
                    <p className="h2">Inscription</p>
                    <p>Il vous suffit de créer votre profil de membre et soumettre vos documents pour vérification à l'aide de la fonction photo de l'application.</p>                    
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Book_vehicle.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>03</b></p>
                    <br/>
                    <p className="h2">Réservez votre véhicule</p>
                    <p>Connectez-vous à l'application et réservez votre véhicule en sélectionnant le lieu et l'heure de prise en charge souhaités. Vous pourrez avoir besoin d'une carte de crédit/débit.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step4-Start.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>04</b></p>
                    <br/>
                    <p className="h2">Début de la location</p>
                    <p>Peu de temps avant le début de votre location, votre véhicule vous sera attribué. Au moment de sa prise en charge, vous recevrez une clé virtuelle dans l'application pour déverrouiller le véhicule.</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step5-During.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>05</b></p>
                    <br/>
                    <p className="h2">Pendant la location</p>
                    <p>Pendant votre location, si vous avez besoin de plus de temps, vous pouvez prolonger votre location. Vous pouvez également accéder à tout moment au contrat de location. En cas d'inactivité, le système antidémarrage peut s'activer. Utilisez le bouton d'alimentation dans l'application pour le libérer.</p>

                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Step6-End.png" alt='' />
                    <br/><br/>
                    <p className="h2"><b>06</b></p>
                    <br/>
                    <p className="h2">Fin de la location</p>
                    <p>Lorsque vous avez terminé, clôturez la location en passant par les étapes de l'inspection finale et la liste de contrôle. La facture vous sera communiquée dans les 48 heures et le dépôt de garantie débloqué.</p>
                </div>
            </div>


            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                <br/>
                    <h1 className="display-5"><b>FAQ</b></h1>
                    <p className="h6">
                       
                    </p>
                </div>

                <div className="col-xs-12" style={{ textAlign: 'left' }}>
                    <br /><br />
                    
                    <div className="accordion p-4" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading1">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                                    <b>Qu'est-ce que Hertz 24/7 ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse1" className="accordion-collapse collapse" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                    <p>Hertz 24/7™ vous offre la commodité et la flexibilité d'avoir accès à une large gamme de camionnettes, pour la durée que vous souhaitez. Vous pouvez louer d'une heure à trois jours. Vous pouvez également récupérer le véhicule à n'importe quelle heure du jour ou de la nuit. De plus, l'assurance et l'entretien sont inclus dans le tarif.</p>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading2">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                                    <b>Quels sont les éléments dont vous avez besoin ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse2" className="accordion-collapse collapse" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Pour adhérer à Hertz 24/7, vous devez fournir les informations suivantes :<br/>
                                    · Un permis de conduire et une pièce d'identité (passeport ou CI) valide à votre nom.<br/>
                                    · Une carte de crédit ou de débit à votre nom<br/>
                                    · Votre propre numéro de téléphone portable et votre adresse électronique personnelle.<br/>
                                    Nous procédons ensuite aux contrôles d'identité nécessaires avant de confirmer votre inscription.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading3">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                                    <b>Comment serai-je facturé lors de la réservation d'une location Hertz 24/7 ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse3" className="accordion-collapse collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Nous ferons une demande de pré-autorisation à hauteur de 200 % des frais initiaux 48 heures avant le début de la location ou immédiatement si la location commence dans les 48 heures. La réservation ne sera confirmée qu'une fois l'autorisation obtenue. Les autorisations préalables seront débloquées au moment de l'émission de la facture. Nous déduirons les frais et rembourserons le reste. Si le coût de la location dépasse le montant de l'acompte, nous prélèverons le montant excédentaire. Veuillez noter que tout retard  dans le remboursement de la caution est imputable à la banque émettrice de votre carte de crédit ou de débit.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading4">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                                    <b>Comment déverrouiller le véhicule ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse4" className="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">L'application Hertz 24/7 sera votre clé numérique. Pour ouvrir votre voiture, appuyez sur le bouton "Déverrouiller" sur l'onglet de conduite pendant votre location. Remarque : vous ne pourrez  accéder au véhicule qu'une fois le début de la période de location commencée, l'inspection effectuée et le contrat signé.</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-heading5">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                                    <b>Qui paie les frais de carburant ?</b>
                                </button>
                            </h2>
                            <div id="flush-collapse5" className="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Le carburant est facturé sur la base d'un prix par kilomètre, affiché au moment de la réservation. Tous les véhicules doivent être munis d'une carte de carburant (dans la boite à gant ou sous le pare-soleil) et d'au moins un quart de réservoir au début de la location. Vous devez également restituer le véhicule de cette manière. Si la carte de carburant est manquante au début de la location, veuillez nous contacter en utilisant le numéro de téléphone figurant dans la section Aide de l'application. La carte de carburant fournie est limitée à 60€ par transaction. Les cartes de carburant peuvent être utilisées dans les stations-service du réseau DKV et le code pin affiché dans l'application sera requis lors du paiement du carburant.</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>
    );
}
export default BodyHowitWorks;
