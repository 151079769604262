import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { useState } from "react";

const Map = (props) => {
    const { isLoaded } = props;
    const [selectedMarker, setSelectedMarker] = useState("");
    const containerStyle = {
        width: '100%',
        height: '800px'
    };

    const center = {
        lat: 47.12332777506308,
        lng: 2.3404142679936966
    };
    const points = [
        {
            id: 2,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr21030300001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Béziers</a>,
            location: { lat: 43.337, lng: 3.28585 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr21030300001008&product=HOURLY_RENTAL'
        },
        {
            id: 3,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr22120100003009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Biganos</a>,
            location: { lat: 44.64121, lng: -0.94948 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr22120100003009&product=HOURLY_RENTAL'
        },
        {
            id: 4,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr23020800002004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Bordeaux - Gradignan</a>,
            location: { lat: 44.777, lng: -0.6427 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr23020800002004&product=HOURLY_RENTAL'
        },
        {
            id: 5,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr23082200002002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Bordeaux - Merignac</a>,
            location: { lat: 44.83172, lng: -0.66451 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr23082200002002&product=HOURLY_RENTAL'
        },
        {
            id: 6,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr23020800003003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Bordeaux Lac</a>,
            location: { lat: 44.883094, lng: -0.564822 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr23020800003003&product=HOURLY_RENTAL'
        },
        {
            id: 7,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr23030200001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Bordeaux Lac - cour des matériaux</a>,
            location: { lat: 44.8804, lng: -0.5609 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr23030200001006&product=HOURLY_RENTAL'
        },
        {
            id: 8,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr19110500008003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Forbach</a>,
            location: { lat: 49.1717, lng: 6.88 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr19110500008003&product=HOURLY_RENTAL'
        },
        {
            id: 9,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr18092700002009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Fréjus - Puget sur Argens</a>,
            location: { lat: 43.457, lng: 6.69475 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr18092700002009&product=HOURLY_RENTAL'
        },
        {
            id: 10,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr21090800001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin La Rochelle</a>,
            location: { lat: 46.1765, lng: -1.1236 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr21090800001004&product=HOURLY_RENTAL'
        },
        {
            id: 11,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr22062900001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Le Havre - Montivilliers</a>,
            location: { lat: 49.5405, lng: 0.20435 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr22062900001004&product=HOURLY_RENTAL'
        },
        {
            id: 12,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr20070600001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Lyon Tassin</a>,
            location: { lat: 45.7693341, lng: 4.7863598 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr20070600001004&product=HOURLY_RENTAL'
        },
        {
            id: 13,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr22022500001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Montpellier - St Aunes</a>,
            location: { lat: 43.6495, lng: 3.9804 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr22022500001001&product=HOURLY_RENTAL'
        },
        {
            id: 14,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr20101900002000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Nîmes</a>,
            location: { lat: 43.8127672, lng: 4.3507326 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr20101900002000&product=HOURLY_RENTAL'
        },
        {
            id: 15,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr22120100001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Niort</a>,
            location: { lat: 46.31875, lng: -0.41625 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr22120100001001&product=HOURLY_RENTAL'
        },
        {
            id: 16,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr23100300001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Rouen - Tourville</a>,
            location: { lat: 49.3306, lng: 1.09 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr23100300001009&product=HOURLY_RENTAL'
        },
        {
            id: 17,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr19100400001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Saint-Denis</a>,
            location: { lat: 48.9266021, lng: 2.3619589 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr19100400001005&product=HOURLY_RENTAL'
        },
        {
            id: 18,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr19110500007004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Toulon - La Valette-Du-Var</a>,
            location: { lat: 43.1392639, lng: 6.0038962 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr19110500007004&product=HOURLY_RENTAL'
        },
        {
            id: 19,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr23020200001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Toulouse - Balma</a>,
            location: { lat: 43.5927798, lng: 1.4995542 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr23020200001008&product=HOURLY_RENTAL'
        },
        {
            id: 20,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-fr23020800001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Toulouse - Roques</a>,
            location: { lat: 43.5098, lng: 1.365 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-fr23020800001005&product=HOURLY_RENTAL'
        }
    ];

    return (
        isLoaded && (
            <>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={7}
                    options={{
                        streetViewControl: false,
                        mapTypeControl: false
                    }}

                >
                    {points.map((point) => {
                        return (
                            <div key={point.id}>
                                <Marker position={point.location} options={{
                                    icon: 'https://images.hertz.com/content/dam/hod/images/en-gb/static/Location_Pin.png',
                                }} onClick={() => {
                                    setSelectedMarker(point);
                                }} />
                            </div>
                        );
                    })}
                    {selectedMarker && (
                        <InfoWindow position={selectedMarker.location} options={{
                            pixelOffset: new window.google.maps.Size(0, -40)
                        }} onCloseClick={() => setSelectedMarker("")}>
                            <>
                                <h6>{selectedMarker.name}</h6>
                                <button className="btn btn-warning" onClick={() => window.location.href = selectedMarker.ufoUrl}>Réserver</button>
                            </>

                        </InfoWindow>
                    )}
                </GoogleMap>
            </>
        )
    );
};

export default Map;