import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { useState } from "react";

const Map = (props) => {
    const { isLoaded } = props;
    const [selectedMarker, setSelectedMarker] = useState("");
    const containerStyle = {
        width: '100%',
        height: '800px'
    };

    const center = {
        lat: 42.53059709637559,
        lng: 12.926756183113625
    };
    const points = [
        {
            id: 293,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it23101200002001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Hertz Genova Porto</a>,
            location: { lat: 44.4105433, lng: 8.9088404 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it23101200002001&product=HOURLY_RENTAL'
        },
        {
            id: 294,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it23032900001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Hertz Milano San Siro</a>,
            location: { lat: 45.479055, lng: 9.146127 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it23032900001001&product=HOURLY_RENTAL'
        },
        {
            id: 295,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it23052400001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Hertz Pavia</a>,
            location: { lat: 45.1873169, lng: 9.17602253 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it23052400001007&product=HOURLY_RENTAL'
        },
        {
            id: 296,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it23092600001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lambruschini Park - Genova</a>,
            location: { lat: 44.4105433, lng: 8.9088404 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it23092600001004&product=HOURLY_RENTAL'
        },
        {
            id: 297,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it14121600001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Ancona</a>,
            location: { lat: 43.529911, lng: 13.5201244 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it14121600001008&product=HOURLY_RENTAL'
        },
        {
            id: 298,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it19021500003008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Bari</a>,
            location: { lat: 41.0884441, lng: 16.8985085 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it19021500003008&product=HOURLY_RENTAL'
        },
        {
            id: 299,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it15090400001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Bologna</a>,
            location: { lat: 44.4871129, lng: 11.2544382 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it15090400001007&product=HOURLY_RENTAL'
        },
        {
            id: 300,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it17072800005002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Carugate</a>,
            location: { lat: 45.5507516, lng: 9.33200759 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it17072800005002&product=HOURLY_RENTAL'
        },
        {
            id: 301,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it17072800008009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Catania</a>,
            location: { lat: 37.4469852, lng: 15.0360756 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it17072800008009&product=HOURLY_RENTAL'
        },
        {
            id: 302,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it17032700004004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Chieti</a>,
            location: { lat: 42.396267, lng: 14.167791 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it17032700004004&product=HOURLY_RENTAL'
        },
        {
            id: 303,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it17072800004003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Corsico</a>,
            location: { lat: 45.4276199, lng: 9.07838726 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it17072800004003&product=HOURLY_RENTAL'
        },
        {
            id: 304,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it17072800011004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Firenze</a>,
            location: { lat: 43.8079187, lng: 11.1870437 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it17072800011004&product=HOURLY_RENTAL'
        },
        {
            id: 305,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it14092200001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Genova</a>,
            location: { lat: 44.423295, lng: 8.88416 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it14092200001002&product=HOURLY_RENTAL'
        },
        {
            id: 306,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it14092500002004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Gorizia Villesse</a>,
            location: { lat: 45.8653, lng: 13.433358 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it14092500002004&product=HOURLY_RENTAL'
        },
        {
            id: 307,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it23091800001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Napoli</a>,
            location: { lat: 40.9099426, lng: 14.3170052 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it23091800001001&product=HOURLY_RENTAL'
        },
        {
            id: 308,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it17072800009008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Padova</a>,
            location: { lat: 45.4181671, lng: 11.9337921 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it17072800009008&product=HOURLY_RENTAL'
        },
        {
            id: 309,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it14092500001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Parma</a>,
            location: { lat: 44.83075, lng: 10.36425 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it14092500001005&product=HOURLY_RENTAL'
        },
        {
            id: 310,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it15012700001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Pisa</a>,
            location: { lat: 43.69325, lng: 10.383 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it15012700001005&product=HOURLY_RENTAL'
        },
        {
            id: 311,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it17033000001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Rimini</a>,
            location: { lat: 44.0792417, lng: 12.4759 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it17033000001001&product=HOURLY_RENTAL'
        },
        {
            id: 312,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it17072800007000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Roma Anagnina</a>,
            location: { lat: 41.8346863, lng: 12.5938301 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it17072800007000&product=HOURLY_RENTAL'
        },
        {
            id: 313,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it17072800002005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Roma Porta di Roma</a>,
            location: { lat: 41.9740143, lng: 12.5396147 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it17072800002005&product=HOURLY_RENTAL'
        },
        {
            id: 314,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it17072800006001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA San Giuliano</a>,
            location: { lat: 45.3888022, lng: 9.2612295 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it17072800006001&product=HOURLY_RENTAL'
        },
        {
            id: 315,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it17072800003004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Torino</a>,
            location: { lat: 45.0978121, lng: 7.5814196 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it17072800003004&product=HOURLY_RENTAL'
        },
        {
            id: 316,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it21021600001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Ancona</a>,
            location: { lat: 43.5586968, lng: 13.5121813 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it21021600001004&product=HOURLY_RENTAL'
        },
        {
            id: 317,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it21102200001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Assago</a>,
            location: { lat: 45.4064331, lng: 9.15489578 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it21102200001005&product=HOURLY_RENTAL'
        },
        {
            id: 318,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it18092100007009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Busnago</a>,
            location: { lat: 45.6210365, lng: 9.47209072 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it18092100007009&product=HOURLY_RENTAL'
        },
        {
            id: 319,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it22121900002004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Cagliari Elmas</a>,
            location: { lat: 39.2738113, lng: 9.05488777 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it22121900002004&product=HOURLY_RENTAL'
        },
        {
            id: 320,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it18070500005008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Caponago</a>,
            location: { lat: 45.5699309, lng: 9.36672654 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it18070500005008&product=HOURLY_RENTAL'
        },
        {
            id: 321,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it18051600004000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Carugate</a>,
            location: { lat: 45.5493928, lng: 9.32900942 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it18051600004000&product=HOURLY_RENTAL'
        },
        {
            id: 322,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it22101300001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Catania</a>,
            location: { lat: 37.445854, lng: 15.034066 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it22101300001002&product=HOURLY_RENTAL'
        },
        {
            id: 323,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it18051500005001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Collegno</a>,
            location: { lat: 45.102157, lng: 7.594996 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it18051500005001&product=HOURLY_RENTAL'
        },
        {
            id: 324,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it18051600002002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Corsico</a>,
            location: { lat: 45.4310466, lng: 9.08134839 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it18051600002002&product=HOURLY_RENTAL'
        },
        {
            id: 325,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it18051600005009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Curno</a>,
            location: { lat: 45.6805954, lng: 9.60397928 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it18051600005009&product=HOURLY_RENTAL'
        },
        {
            id: 326,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it14120200002007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Lissone</a>,
            location: { lat: 45.5974846, lng: 9.23416138 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it14120200002007&product=HOURLY_RENTAL'
        },
        {
            id: 327,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it18040300003002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Moncalieri</a>,
            location: { lat: 44.9749298, lng: 7.70894146 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it18040300003002&product=HOURLY_RENTAL'
        },
        {
            id: 328,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it18070500004009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Nova Milanese</a>,
            location: { lat: 45.5894021, lng: 9.17511589 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it18070500004009&product=HOURLY_RENTAL'
        },
        {
            id: 329,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it21110900001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Pantigliate</a>,
            location: { lat: 45.4296074, lng: 9.36597443 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it21110900001000&product=HOURLY_RENTAL'
        },
        {
            id: 330,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it21070500004003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Pescara</a>,
            location: { lat: 42.419001, lng: 14.17487 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it21070500004003&product=HOURLY_RENTAL'
        },
        {
            id: 331,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it18092100008008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Rimini Savignano</a>,
            location: { lat: 44.15766, lng: 12.4337 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it18092100008008&product=HOURLY_RENTAL'
        },
        {
            id: 332,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it14120200001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Roma Laurentina</a>,
            location: { lat: 41.7950477, lng: 12.4848475 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it14120200001008&product=HOURLY_RENTAL'
        },
        {
            id: 333,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it18070500003000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Rozzano</a>,
            location: { lat: 45.3896025, lng: 9.17409515 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it18070500003000&product=HOURLY_RENTAL'
        },
        {
            id: 334,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it18080200002006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Seriate</a>,
            location: { lat: 45.6701403, lng: 9.7390863 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it18080200002006&product=HOURLY_RENTAL'
        },
        {
            id: 335,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it16121400005004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Torino</a>,
            location: { lat: 45.1172342, lng: 7.713 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it16121400005004&product=HOURLY_RENTAL'
        },
        {
            id: 336,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it19012800001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Treviso</a>,
            location: { lat: 45.6768841, lng: 12.3335874 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it19012800001005&product=HOURLY_RENTAL'
        },
        {
            id: 337,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it18051600006008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Udine</a>,
            location: { lat: 46.085063, lng: 13.177794 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it18051600006008&product=HOURLY_RENTAL'
        },
        {
            id: 338,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it15021800001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Varese Solbiate Arno</a>,
            location: { lat: 45.7213593, lng: 8.80969524 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it15021800001002&product=HOURLY_RENTAL'
        },
        {
            id: 339,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it19011000016006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Venezia Marcon</a>,
            location: { lat: 45.5457069, lng: 12.3025071 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it19011000016006&product=HOURLY_RENTAL'
        },
        {
            id: 340,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it19011000017005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Venezia Marghera</a>,
            location: { lat: 45.4570045, lng: 12.21053 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it19011000017005&product=HOURLY_RENTAL'
        },
        {
            id: 341,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it18051500004002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Leroy Merlin Verona</a>,
            location: { lat: 45.396045, lng: 11.017645 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it18051500004002&product=HOURLY_RENTAL'
        },
        {
            id: 342,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it23111300001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Hertz Genova Aeroporto</a>,
            location: { lat: 44.41576, lng: 8.8527136 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it23111300001008&product=HOURLY_RENTAL'
        },
        {
            id: 343,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it19100800001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Tecnomat by Bricoman Carate</a>,
            location: { lat: 45.6696358, lng: 9.22222328 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it19100800001008&product=HOURLY_RENTAL'
        },
        {
            id: 344,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it19082200001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Tecnomat by Bricoman Caronno Pertusella</a>,
            location: { lat: 45.581975, lng: 9.061116 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it19082200001003&product=HOURLY_RENTAL'
        },
        {
            id: 345,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it19102900001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Tecnomat by Bricoman Cerro</a>,
            location: { lat: 45.610079, lng: 8.93876 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it19102900001004&product=HOURLY_RENTAL'
        },
        {
            id: 346,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it19052300002006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Tecnomat by Bricoman Milano Pero</a>,
            location: { lat: 45.5085487, lng: 9.09625912 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it19052300002006&product=HOURLY_RENTAL'
        },
        {
            id: 347,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it19052300003005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Tecnomat by Bricoman Milano Segrate</a>,
            location: { lat: 45.4870415, lng: 9.26167965 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it19052300003005&product=HOURLY_RENTAL'
        },
        {
            id: 348,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it21032900002004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Tecnomat by Bricoman Venegono</a>,
            location: { lat: 45.7346916, lng: 8.88324833 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it21032900002004&product=HOURLY_RENTAL'
        },
        {
            id: 349,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it24070500002009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Hertz Milano Centrale Railway Station</a>,
            location: { lat: 45.48295, lng: 9.2011 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it24070500002009&product=HOURLY_RENTAL'
        },
        {
            id: 350,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it24070500001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Hertz Roma Fiumicino Aeroporto</a>,
            location: { lat: 41.7940113876359, lng: 12.2530573000387 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it24070500001000&product=HOURLY_RENTAL'
        },
        {
            id: 351,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it24052300001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Hertz Roma Termini Railway Station</a>,
            location: { lat: 41.899803, lng: 12.505361 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it24052300001007&product=HOURLY_RENTAL'
        },
        {
            id: 352,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-it17072800010005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Brescia</a>,
            location: { lat: 45.534439, lng: 10.167006 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-it17072800010005&product=HOURLY_RENTAL'
        }
    ];

    return (
        isLoaded && (
            <>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={7}
                    options={{
                        streetViewControl: false,
                        mapTypeControl: false
                    }}

                >
                    {points.map((point) => {
                        return (
                            <div key={point.id}>
                                <Marker position={point.location} options={{
                                    icon: 'https://images.hertz.com/content/dam/hod/images/en-gb/static/Location_Pin.png',
                                }} onClick={() => {
                                    setSelectedMarker(point);
                                }} />
                            </div>
                        );
                    })}
                    {selectedMarker && (
                        <InfoWindow position={selectedMarker.location} options={{
                            pixelOffset: new window.google.maps.Size(0, -40)
                        }} onCloseClick={() => setSelectedMarker("")}>
                            <>
                                <h6>{selectedMarker.name}</h6>
                                <button className="btn btn-warning" onClick={() => window.location.href = selectedMarker.ufoUrl}>Prenota ora</button>
                            </>

                        </InfoWindow>
                    )}
                </GoogleMap>
            </>
        )
    );
};

export default Map;