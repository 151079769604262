import React from 'react';
import Button from 'react-bootstrap/Button';

function BusinessSolutionBody() {
    return (<>
        <div className="container-fluid  p-4 body-font">
            <div className='row p-4' style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/BusinessSolutions_top_desktop.png)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1">
                    </div>
                    <div className="col-xs-12 col-md-5">
                        <br />
                        <p className="h6"><b>Business-Lösungen</b></p>
                        <span>
                            <h1><i><b>Ihr Unternehmen auf dem Sprung!</b></i></h1>
                        </span>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-4">
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <p className="h2">Business-Lösungen</p>
                    <br />
                    <p>
                        Hertz 24/7 Business bietet Ihnen die effektivste Möglichkeit, den Fahrzeugbedarf Ihres Unternehmens zu organisieren. Wir haben die passende Lösung für Sie, egal ob Sie ein großer Konzern, ein mittleres oder ein kleines Unternehmen sind. Dank der preisgekrönten Technologie in all unseren Fahrzeugen ermöglichen wir Unternehmen, Kosten zu reduzieren, die Nutzung der Firmenfahrzeuge zu optimieren und die Verwaltung des Fahrzeugparks zu verbessern.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                    <img className='img-fluid' src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Corda_Mobile.png" alt='' />
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }}>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" sstyle={{ textAlign: 'left' }}>
                            <p className="h3">Kostengünstig</p>
                            <p>Durch den Einsatz einer eigenen Firmenflotte für Carsharing können Unternehmen potenziell Kosten einsparen, die im Vergleich zur Erstattung individueller Fahrten durch die Mitarbeiter oder zur Nutzung von Diensten Dritter anfallen.</p>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" style={{ textAlign: 'left' }}>
                            <p className="h3">Transparent und unter Kontrolle</p>
                            <p>Die Unterhaltung eines eigenen Fuhrparks bietet Unternehmen eine größere Transparenz und Kontrolle über ihre Transportvorgänge. Sie können Nutzungsmuster verfolgen, die Fahrzeugwartung überwachen und Unternehmensrichtlinien effektiver durchsetzen.</p>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-12  py-md-5" style={{ textAlign: 'left' }}>
                            <p className="h3">Schonend für die Umwelt</p>
                            <p>Die Einführung einer Unternehmensflotte für Carsharing kann den ökologischen Fußabdruck der Mitarbeiterbeförderung erheblich verringern. Unternehmen können in ihrem Fuhrpark vorrangig umweltfreundliche Fahrzeuge wie Hybrid- oder Elektroautos einsetzen. Auf diese Weise tragen sie zur Senkung der Kohlenstoffemissionen bei und unterstützen Nachhaltigkeitsinitiativen.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br />
                    <p className="h2">Kontaktieren Sie uns und erfahren Sie mehr</p>
                    <br />
                    <p>
                        Schicken Sie uns eine E-Mail (<b>✉:ge247sales@hertz.com</b>), wenn Sie mehr erfahren möchten. Ein Mitglied unseres Teams für Unternehmensflotten wird sich mit Ihnen in Verbindung setzen.
                    </p>
                    <br />
                    <p>
                        <Button href='mailto:ge247sales@hertz.com' variant="warning"><b>E-Mail senden</b></Button>
                    </p>
                </div>
            </div>
        </div>
    </>
    );
}

export default BusinessSolutionBody;

