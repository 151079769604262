import React from 'react';

function HomeBody() {
    return (<>
        <div className="container-fluid p-4 body-font">
            <div className="row">
                <div className="col-xs-12 col-md-6 py-md-5">
                    <p className="h6"><b>Van sharing fácil e rápido</b></p>
                    <p className="h1"><b>RESERVAR. ENTRAR.</b> <font className="text-warning"><b>CONDUZIR!</b></font></p>
                    <p className="h6">Alugueres à hora, ideais para transporte de grandes e médias dimensões.<br />Não há balcão, basta transferir a App, registar-se e avançar com a sua reserva.</p>
                    <div className="row">
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }} >
                            <div className="row">
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }} >
                                    <a href='https://apps.apple.com/pt/app/hertz-24-7-mobility/id6453605078'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }} >
                                    <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=pt_pt&gl=pt '>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} style={{ backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-6">

                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'right' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Mercedes_24sprinter_top.png" alt='' />
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <h1 className="display-5"><b>Como funciona</b></h1>
                    <br />
                    <p className="h6">
                        Alugar com a Hertz 24/7® é tão fácil e eficiente que pode entrar na sua viatura poucos minutos depois de ter criado a sua reserva. É simples!
                    </p>
                    <br />
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/QR_large.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>01</b></p><br />
                    <p className="h2">Descarregar a App</p>
                    <p>É rápido e fácil descarregar a aplicação Hertz 24/7® a partir da sua loja de aplicações favorita, basta digitalizar o Código QR.</p>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Register.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>02</b></p><br />
                    <p className="h2">Registar</p>
                    <p>Crie o seu perfil de membro e submeta os seus documentos para validação. Tudo através da app!</p>

                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Select_Location_PT.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>03</b></p>
                    <p className="h2">Reservar a sua viatura</p><br />
                    <p>Inicie a sessão na App e reserve a sua viatura, selecionando o local e a hora de recolha pretendidos. Precisará de um cartão de crédito/débito e carta de condução válidos.</p>
                </div>
                <div className="col-xs-12 col-md-3" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Unlock.png" alt='' />
                    <br /><br />
                    <p className="h2"><b>04</b></p><br />
                    <p className="h2">Iniciar o aluguer</p>
                    <p>Antes de iniciar o aluguer, ser-lhe-á atribuída uma viatura. No levantamento, receberá a chave digital através da App para se ligar à viatura e destrancar as portas.</p>
                </div>
            </div>

            <div className="row" style={{ backgroundColor: '#F3F3F3' }}>
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br />
                    <h1 className="display-5"><b>A nossa frota</b></h1>
                    <br />
                    <p className="h6">
                        A Hertz 24/7® tem a viatura perfeita para as suas necessidades de transporte. Basta selecionar o modelo que deseja conduzir.
                    </p>
                    <br />
                    <div className="row">
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/MercedesVito.png" alt='' />

                            <div style={{ textAlign: 'left' }}>
                                <p className="h2">Mercedes Vito ou similar</p>

                                <span>
                                    <ul>
                                        <li>Portas: 4 ou 5</li>
                                        <li>Medidas interiores úteis aproximadas em mts: 2,47 x 1,64 x 1,31 (Comp x Larg x Alt)</li>
                                    </ul>
                                </span>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/MercedesSprinter.png" alt='' />
                            <div style={{ textAlign: 'left' }}>
                                <p className="h2">Mercedes Sprinter</p>

                                <span>
                                    <ul>
                                        <li>Portas: 5</li>
                                        <li>Medidas interiores úteis aprox. em mts: 3,22 x 1,77 x 2,00  (Comp x Larg x Alt)</li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4" style={{ textAlign: 'center' }}>
                            <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/MercedesSprinterLong.png" alt='' />
                            <div style={{ textAlign: 'left' }}>
                                <p className="h2">Mercedes Sprinter Longa</p>

                                <span>
                                    <ul>
                                        <li>Portas: 5</li>
                                        <li>Medidas interiores úteis aprox. em mts: 4,30 x 1,73 x 1,91  (Comp x Larg x Alt)</li>
                                    </ul>
                                </span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-6" style={{ textAlign: 'center' }}>
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Mobile_PT.png" alt='' />
                </div>
                <div className="col-xs-12 col-md-6 py-md-5" style={{ textAlign: 'center' }}>
                    <h1 className="display-5"><b>As nossas localizações</b></h1>
                    <p>Com mais de 700 locais em toda a Europa, um automóvel ou carrinha Hertz 24/7® está mais perto do que pensa.</p>
                    <div className="row">
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Reino Unido (300 Localizações)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600020009&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Manchester</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902024006&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>London</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>França (20 Localizações)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-fr19100400001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Paris</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-fr19110500007004&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Marseilles</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Alemanha (280 Localizações)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-de18112800001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Berlin</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-de15102900001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Frankfurt</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Itália (75 Localizações)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-it17072800007000&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Rome</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-it17072800005002&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Milan</a></p>
                        </div>
                        <div className="col-xs-12 col-md-4 " style={{ textAlign: 'left' }}>
                            <p><b>Portugal (39 Localizações)</b></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-pt21051200001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Lisbon</a></p>
                            <p><a href='https://booking.hertz247.com/booking/book?location=prk-pt17062200003001&product=HOURLY_RENTAL' style={{ textDecoration: 'none' }}>Porto</a></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12" style={{ textAlign: 'left' }}>
                            <b><u>Explorar locais perto de si</u></b><br /><br />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }}>
                            <div className="row">
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }}>
                                    <a href='https://apps.apple.com/pt/app/hertz-24-7-mobility/id6453605078'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/AppleAppStore.png" alt='' width={250} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                                <div className="col-xs-12 col-md-5" style={{ textAlign: 'left' }}>
                                    <a href='https://play.google.com/store/apps/details?id=com.hertz247.driverApp&hl=pt_pt&gl=pt'>
                                        <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/GooglePlayStore.png" alt='' width={250} />
                                    </a>
                                </div>
                                <div className="col-xs-12 col-md-1" style={{ textAlign: 'center' }}>
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                        <div className="col-xs-12 col-md-6" style={{ textAlign: 'left' }}>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-4" style={{ width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div className="row img-fluid" style={{ backgroundImage: 'url(https://images.hertz.com/content/dam/hod/images/en-gb/static/Transit_city.png)', backgroundSize: 'cover' }}>
                    <div className="col-xs-12 col-md-1" >
                    </div>
                    <div className="col-xs-12 col-md-3" >
                        <br />
                        <br />
                        <br />
                        <span>
                            <h1 className="display-4"><b>Porque deve escolher a Hertz 24/7® ?</b></h1>
                        </span>
                        <p>Com a Hertz 24/7®, alugue viaturas a preços competitivos, com tarifas horárias consoante o tipo de modelo, localização e distância percorrida. O preço estimado para o seu aluguer será apresentado no momento da reserva.*</p>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-md-8" >
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center', backgroundColor: '#fff7cc' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Contact.png" alt='' />
                    <p className="h4">Contact Center - Apoio ao Cliente</p>
                    <p>Estamos à sua disposição em caso de dúvidas com o seu aluguer (todos os dias da semana, das 8h às 22h).</p>
                </div>
                <div className="col-xs-12 col-md-4 " style={{ textAlign: 'center', backgroundColor: '#fff099' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Price.png" alt='' />
                    <p className="h4">Faturação simplificada</p>
                    <p>Alugue à hora. Aceitamos cartões de crédito e débito (consultar condições).</p>
                </div>
                <div className="col-xs-12 col-md-4" style={{ textAlign: 'center', backgroundColor: '#ffe866' }}>
                    <br />
                    <img className="img-fluid" src="https://images.hertz.com/content/dam/hod/images/en-gb/static/Location.png" alt='' />
                    <p className="h4">Sempre por perto</p>
                    <p>Com lojas de norte a sul de Portugal, encontre uma viatura perto de si.</p>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-12" style={{ textAlign: 'center' }}>
                    <br />
                    <span><h6>800 20 20 88 (chamada gratuita) / customersupport.247@hertz.pt</h6></span>
                    <span><h6>*O seu aluguer inclui 40km gratuitos. Cada quilómetro acima deste valor será cobrado a 0,35€/km.</h6></span>

                </div>
            </div>
        </div>
    </>);
}
export default HomeBody;