import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { useState } from "react";




const Map = (props) => {  

    const { isLoaded } = props;
    const [selectedMarker, setSelectedMarker] = useState("");
    const containerStyle = {
        width: '100%',
        height: '800px'
    };

    const center = {
        lat: 53.380860904216355,
        lng: -1.4699408453424565
    };
    const points = [
        {
            id: 353,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11112400001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Baker Hughes - Newcastle - Employees Only</a>,
            location: { lat: 54.96, lng: -1.54 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11112400001002&product=HOURLY_RENTAL'
        },
        {
            id: 354,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901891008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Brunel University - West Spur Road</a>,
            location: { lat: 51.53, lng: -0.47 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901891008&product=HOURLY_RENTAL'
        },
        {
            id: 355,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901000006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Imperial college</a>,
            location: { lat: 51.49, lng: -0.17 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901000006&product=HOURLY_RENTAL'
        },
        {
            id: 356,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb14063000001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>McLaren Production Centre - [Staff Only]</a>,
            location: { lat: 51.34, lng: -0.54 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb14063000001003&product=HOURLY_RENTAL'
        },
        {
            id: 357,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11111800002003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>St Modwen - Uxbridge</a>,
            location: { lat: 53.2, lng: -0.61 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11111800002003&product=HOURLY_RENTAL'
        },
        {
            id: 358,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11090600001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>The Lamptons [ Residents Only]</a>,
            location: { lat: 51.5, lng: -0.12 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11090600001003&product=HOURLY_RENTAL'
        },
        {
            id: 359,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901935006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Aberdeen</a>,
            location: { lat: 57.12, lng: -2.12 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901935006&product=HOURLY_RENTAL'
        },
        {
            id: 360,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902026004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Acton</a>,
            location: { lat: 51.52, lng: -0.27 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902026004&product=HOURLY_RENTAL'
        },
        {
            id: 361,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901947002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Aintree</a>,
            location: { lat: 53.48, lng: -2.94 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901947002&product=HOURLY_RENTAL'
        },
        {
            id: 362,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902027003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Ashford</a>,
            location: { lat: 51.13, lng: 0.87 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902027003&product=HOURLY_RENTAL'
        },
        {
            id: 363,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19032100001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Ashton Under Lyne</a>,
            location: { lat: 53.4779244, lng: -2.1245041 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19032100001008&product=HOURLY_RENTAL'
        },
        {
            id: 364,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb20121100001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Aylesbury</a>,
            location: { lat: 51.818264, lng: -0.806887 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb20121100001008&product=HOURLY_RENTAL'
        },
        {
            id: 365,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901999009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Bamber Bridge</a>,
            location: { lat: 53.72, lng: -2.66 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901999009&product=HOURLY_RENTAL'
        },
        {
            id: 366,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb17091200001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Basildon</a>,
            location: { lat: 51.580116, lng: 0.445411 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb17091200001008&product=HOURLY_RENTAL'
        },
        {
            id: 367,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901968006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Beckton</a>,
            location: { lat: 51.52, lng: 0.07 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901968006&product=HOURLY_RENTAL'
        },
        {
            id: 368,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902000004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Belvedere</a>,
            location: { lat: 51.49, lng: 0.15 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902000004&product=HOURLY_RENTAL'
        },
        {
            id: 369,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18102200005009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Bolton</a>,
            location: { lat: 53.5864029, lng: -2.4268322 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18102200005009&product=HOURLY_RENTAL'
        },
        {
            id: 370,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902003001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Brighton</a>,
            location: { lat: 50.84, lng: -0.12 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902003001&product=HOURLY_RENTAL'
        },
        {
            id: 371,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901938003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Bristol</a>,
            location: { lat: 51.44, lng: -2.5 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901938003&product=HOURLY_RENTAL'
        },
        {
            id: 372,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13091700001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Burgess Hill</a>,
            location: { lat: 50.95, lng: -0.15 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13091700001006&product=HOURLY_RENTAL'
        },
        {
            id: 373,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb16042200001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Bury</a>,
            location: { lat: 53.5943947, lng: -2.2693338 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb16042200001000&product=HOURLY_RENTAL'
        },
        {
            id: 374,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902032006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Cambridge</a>,
            location: { lat: 52.21, lng: 0.14 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902032006&product=HOURLY_RENTAL'
        },
        {
            id: 375,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902005009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Cardiff Culverhouse Cross</a>,
            location: { lat: 51.46, lng: -3.26 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902005009&product=HOURLY_RENTAL'
        },
        {
            id: 376,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23032000001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Cardiff Gate</a>,
            location: { lat: 51.5371132, lng: -3.128686 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23032000001002&product=HOURLY_RENTAL'
        },
        {
            id: 377,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22032900001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Cheetham Hill</a>,
            location: { lat: 53.4983788, lng: -2.2367752 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22032900001005&product=HOURLY_RENTAL'
        },
        {
            id: 378,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb15102700002007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Chelmsford</a>,
            location: { lat: 51.746513, lng: 0.50776 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb15102700002007&product=HOURLY_RENTAL'
        },
        {
            id: 379,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902036002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Cheltenham</a>,
            location: { lat: 51.89, lng: -2.13 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902036002&product=HOURLY_RENTAL'
        },
        {
            id: 380,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901980000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Chester</a>,
            location: { lat: 53.19, lng: -2.91 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901980000&product=HOURLY_RENTAL'
        },
        {
            id: 381,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23033100001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Chingford</a>,
            location: { lat: 51.6087298, lng: -0.0296273 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23033100001009&product=HOURLY_RENTAL'
        },
        {
            id: 382,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901898001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Chiswick</a>,
            location: { lat: 51.49, lng: -0.28 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901898001&product=HOURLY_RENTAL'
        },
        {
            id: 383,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902007007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Coatbridge</a>,
            location: { lat: 55.85, lng: -4.01 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902007007&product=HOURLY_RENTAL'
        },
        {
            id: 384,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21111600001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Colchester Tollgate</a>,
            location: { lat: 51.8879356, lng: 0.82715893 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21111600001007&product=HOURLY_RENTAL'
        },
        {
            id: 385,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901906001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Coventry</a>,
            location: { lat: 52.39, lng: -1.43 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901906001&product=HOURLY_RENTAL'
        },
        {
            id: 386,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902066005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Crawley</a>,
            location: { lat: 51.12, lng: -0.18 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902066005&product=HOURLY_RENTAL'
        },
        {
            id: 387,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902009005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Crewe</a>,
            location: { lat: 53.08, lng: -2.42 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902009005&product=HOURLY_RENTAL'
        },
        {
            id: 388,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901899000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Cribbs Causeway</a>,
            location: { lat: 51.52, lng: -2.6 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901899000&product=HOURLY_RENTAL'
        },
        {
            id: 389,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901900007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Cricklewood</a>,
            location: { lat: 51.55, lng: -0.21 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901900007&product=HOURLY_RENTAL'
        },
        {
            id: 390,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901966008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Croydon</a>,
            location: { lat: 51.38, lng: -0.12 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901966008&product=HOURLY_RENTAL'
        },
        {
            id: 391,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901946003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Darnley</a>,
            location: { lat: 55.8, lng: -4.34 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901946003&product=HOURLY_RENTAL'
        },
        {
            id: 392,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21060100001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Dearne Valley</a>,
            location: { lat: 53.5065239, lng: -1.3871019 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21060100001009&product=HOURLY_RENTAL'
        },
        {
            id: 393,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901953003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Derby</a>,
            location: { lat: 52.89, lng: -1.47 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901953003&product=HOURLY_RENTAL'
        },
        {
            id: 394,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901944005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Doncaster</a>,
            location: { lat: 53.49, lng: -1.11 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901944005&product=HOURLY_RENTAL'
        },
        {
            id: 395,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902011001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Dundee</a>,
            location: { lat: 56.48, lng: -3 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902011001&product=HOURLY_RENTAL'
        },
        {
            id: 396,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901952004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q East Kilbride</a>,
            location: { lat: 55.78, lng: -4.16 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901952004&product=HOURLY_RENTAL'
        },
        {
            id: 397,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23030800001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Eastleigh</a>,
            location: { lat: 50.97689, lng: -1.363417 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23030800001007&product=HOURLY_RENTAL'
        },
        {
            id: 398,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901936005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Edinburgh</a>,
            location: { lat: 55.93, lng: -3.09 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901936005&product=HOURLY_RENTAL'
        },
        {
            id: 399,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22042700001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Eltham</a>,
            location: { lat: 51.445733, lng: 0.061954 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22042700001007&product=HOURLY_RENTAL'
        },
        {
            id: 400,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22113000001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Enfield</a>,
            location: { lat: 51.6540718, lng: -0.0569539 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22113000001006&product=HOURLY_RENTAL'
        },
        {
            id: 401,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23021400002006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Erdington</a>,
            location: { lat: 52.5121574, lng: -1.7987167 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23021400002006&product=HOURLY_RENTAL'
        },
        {
            id: 402,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901933008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Farnborough</a>,
            location: { lat: 51.29, lng: -0.76 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901933008&product=HOURLY_RENTAL'
        },
        {
            id: 403,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901937004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Gillingham</a>,
            location: { lat: 51.36, lng: 0.57 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901937004&product=HOURLY_RENTAL'
        },
        {
            id: 404,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902069002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Glasshoughton</a>,
            location: { lat: 53.7, lng: -1.33 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902069002&product=HOURLY_RENTAL'
        },
        {
            id: 405,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901976006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Gloucester</a>,
            location: { lat: 51.87, lng: -2.24 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901976006&product=HOURLY_RENTAL'
        },
        {
            id: 406,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23021400001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Gravesend</a>,
            location: { lat: 51.4446964, lng: 0.36119274 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23021400001007&product=HOURLY_RENTAL'
        },
        {
            id: 407,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19061800001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Great Western Road</a>,
            location: { lat: 55.904336, lng: -4.378603 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19061800001007&product=HOURLY_RENTAL'
        },
        {
            id: 408,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901975007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Greenwich</a>,
            location: { lat: 51.48, lng: 0.01 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901975007&product=HOURLY_RENTAL'
        },
        {
            id: 409,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19100700001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Guildford</a>,
            location: { lat: 51.2490925, lng: -0.5821099 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19100700001002&product=HOURLY_RENTAL'
        },
        {
            id: 410,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901907000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Halesowen</a>,
            location: { lat: 52.45, lng: -2.03 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901907000&product=HOURLY_RENTAL'
        },
        {
            id: 411,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22021600001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Handforth</a>,
            location: { lat: 53.3577272, lng: -2.2004125 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22021600001004&product=HOURLY_RENTAL'
        },
        {
            id: 412,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902070009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Hartcliffe</a>,
            location: { lat: 51.41, lng: -2.59 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902070009&product=HOURLY_RENTAL'
        },
        {
            id: 413,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901965009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Havant</a>,
            location: { lat: 50.86, lng: -1.01 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901965009&product=HOURLY_RENTAL'
        },
        {
            id: 414,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901969005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Hedge End</a>,
            location: { lat: 50.92, lng: -1.3 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901969005&product=HOURLY_RENTAL'
        },
        {
            id: 415,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23032100001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Hemel Hempstead</a>,
            location: { lat: 51.743003, lng: -0.473259 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23032100001000&product=HOURLY_RENTAL'
        },
        {
            id: 416,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901945004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Hermiston Gait</a>,
            location: { lat: 55.92, lng: -3.3 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901945004&product=HOURLY_RENTAL'
        },
        {
            id: 417,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb15110400001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q High Wycombe</a>,
            location: { lat: 51.6172028, lng: -0.7140303 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb15110400001005&product=HOURLY_RENTAL'
        },
        {
            id: 418,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901916009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Huddersfield</a>,
            location: { lat: 53.65, lng: -1.76 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901916009&product=HOURLY_RENTAL'
        },
        {
            id: 419,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb15092400001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Ilford</a>,
            location: { lat: 51.574218, lng: 0.082235 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb15092400001007&product=HOURLY_RENTAL'
        },
        {
            id: 420,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902072007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Inverness</a>,
            location: { lat: 57.48, lng: -4.22 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902072007&product=HOURLY_RENTAL'
        },
        {
            id: 421,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18052200002002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Kidderminster</a>,
            location: { lat: 52.3818359, lng: -2.2475405 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18052200002002&product=HOURLY_RENTAL'
        },
        {
            id: 422,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb20022500001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Leatherhead</a>,
            location: { lat: 51.31255, lng: -0.3341 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb20022500001009&product=HOURLY_RENTAL'
        },
        {
            id: 423,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23030700004006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Leeds Beeston Ring Road</a>,
            location: { lat: 53.7732086, lng: -1.5834436 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23030700004006&product=HOURLY_RENTAL'
        },
        {
            id: 424,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23030700005005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Leeds Killingbeck</a>,
            location: { lat: 53.8066177, lng: -1.4782406 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23030700005005&product=HOURLY_RENTAL'
        },
        {
            id: 425,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901918007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Leicester</a>,
            location: { lat: 52.64, lng: -1.14 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901918007&product=HOURLY_RENTAL'
        },
        {
            id: 426,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902044002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Leyton</a>,
            location: { lat: 51.55, lng: 0 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902044002&product=HOURLY_RENTAL'
        },
        {
            id: 427,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901974008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Lincoln</a>,
            location: { lat: 53.22, lng: -0.55 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901974008&product=HOURLY_RENTAL'
        },
        {
            id: 428,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb17062100003003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Liverpool</a>,
            location: { lat: 53.4094353, lng: -2.9155793 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb17062100003003&product=HOURLY_RENTAL'
        },
        {
            id: 429,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902074005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Loughborough</a>,
            location: { lat: 52.77, lng: -1.2 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902074005&product=HOURLY_RENTAL'
        },
        {
            id: 430,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901924009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Luton</a>,
            location: { lat: 51.88, lng: -0.45 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901924009&product=HOURLY_RENTAL'
        },
        {
            id: 431,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901912003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Meir Park</a>,
            location: { lat: 52.97, lng: -2.09 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901912003&product=HOURLY_RENTAL'
        },
        {
            id: 432,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21051900001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Middlesbrough</a>,
            location: { lat: 54.569887, lng: -1.178911 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21051900001003&product=HOURLY_RENTAL'
        },
        {
            id: 433,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901927006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Milton Keynes</a>,
            location: { lat: 52.03, lng: -0.78 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901927006&product=HOURLY_RENTAL'
        },
        {
            id: 434,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901934007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q New Malden</a>,
            location: { lat: 51.39, lng: -0.24 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901934007&product=HOURLY_RENTAL'
        },
        {
            id: 435,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901970002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q North Shields</a>,
            location: { lat: 55, lng: -1.5 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901970002&product=HOURLY_RENTAL'
        },
        {
            id: 436,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901926007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Northampton</a>,
            location: { lat: 52.23, lng: -0.9 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901926007&product=HOURLY_RENTAL'
        },
        {
            id: 437,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901940009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Nottingham</a>,
            location: { lat: 52.93, lng: -1.16 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901940009&product=HOURLY_RENTAL'
        },
        {
            id: 438,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901977005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Nursling</a>,
            location: { lat: 50.93, lng: -1.47 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901977005&product=HOURLY_RENTAL'
        },
        {
            id: 439,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901978004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Oldham</a>,
            location: { lat: 53.55, lng: -2.12 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901978004&product=HOURLY_RENTAL'
        },
        {
            id: 440,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21112600001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Oxford</a>,
            location: { lat: 51.7550774, lng: -1.29504 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21112600001006&product=HOURLY_RENTAL'
        },
        {
            id: 441,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901921002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Paisley</a>,
            location: { lat: 55.86, lng: -4.41 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901921002&product=HOURLY_RENTAL'
        },
        {
            id: 442,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902017005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Parkhead</a>,
            location: { lat: 55.85, lng: -4.2 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902017005&product=HOURLY_RENTAL'
        },
        {
            id: 443,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902024006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Peckham</a>,
            location: { lat: 51.48, lng: -0.06 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902024006&product=HOURLY_RENTAL'
        },
        {
            id: 444,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902065006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Peterborough</a>,
            location: { lat: 52.59, lng: -0.26 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902065006&product=HOURLY_RENTAL'
        },
        {
            id: 445,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901939002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Plymouth</a>,
            location: { lat: 50.41, lng: -4.12 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901939002&product=HOURLY_RENTAL'
        },
        {
            id: 446,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901901006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Poole</a>,
            location: { lat: 50.73, lng: -1.98 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901901006&product=HOURLY_RENTAL'
        },
        {
            id: 447,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902075004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Portsmouth</a>,
            location: { lat: 50.79, lng: -1.06 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902075004&product=HOURLY_RENTAL'
        },
        {
            id: 448,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901954002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Queens Road</a>,
            location: { lat: 53.37, lng: -1.46 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901954002&product=HOURLY_RENTAL'
        },
        {
            id: 449,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901964000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Reading</a>,
            location: { lat: 51.42, lng: -0.98 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901964000&product=HOURLY_RENTAL'
        },
        {
            id: 450,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901919006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Romford</a>,
            location: { lat: 51.59, lng: 0.22 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901919006&product=HOURLY_RENTAL'
        },
        {
            id: 451,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901973009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Scotswood</a>,
            location: { lat: 54.97, lng: -1.69 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901973009&product=HOURLY_RENTAL'
        },
        {
            id: 452,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22072000001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Sidcup</a>,
            location: { lat: 51.520484, lng: -0.635464 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22072000001005&product=HOURLY_RENTAL'
        },
        {
            id: 453,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901971001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Slough</a>,
            location: { lat: 51.52, lng: -0.63 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901971001&product=HOURLY_RENTAL'
        },
        {
            id: 454,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901928005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Southend</a>,
            location: { lat: 51.55, lng: 0.72 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901928005&product=HOURLY_RENTAL'
        },
        {
            id: 455,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18082300001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Speke</a>,
            location: { lat: 53.3510094, lng: -2.8767521 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18082300001005&product=HOURLY_RENTAL'
        },
        {
            id: 456,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21042700003007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q St Albans</a>,
            location: { lat: 53.445836, lng: -2.734172 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21042700003007&product=HOURLY_RENTAL'
        },
        {
            id: 457,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902019003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q St Helens</a>,
            location: { lat: 53.44, lng: -2.73 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902019003&product=HOURLY_RENTAL'
        },
        {
            id: 458,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb15102700001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Stanmore</a>,
            location: { lat: 51.593323, lng: -0.288075 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb15102700001008&product=HOURLY_RENTAL'
        },
        {
            id: 459,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902073006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Stevenage</a>,
            location: { lat: 51.89, lng: -0.2 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902073006&product=HOURLY_RENTAL'
        },
        {
            id: 460,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901920003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Stockport</a>,
            location: { lat: 53.41, lng: -2.17 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901920003&product=HOURLY_RENTAL'
        },
        {
            id: 461,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901967007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Stockton</a>,
            location: { lat: 54.57, lng: -1.28 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901967007&product=HOURLY_RENTAL'
        },
        {
            id: 462,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902082005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Sunderland</a>,
            location: { lat: 54.91, lng: -1.39 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902082005&product=HOURLY_RENTAL'
        },
        {
            id: 463,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901942007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Sutton</a>,
            location: { lat: 51.36, lng: -0.18 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901942007&product=HOURLY_RENTAL'
        },
        {
            id: 464,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23030600003009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Sutton-In-Ashfield</a>,
            location: { lat: 53.1316795, lng: -1.23719 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23030600003009&product=HOURLY_RENTAL'
        },
        {
            id: 465,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23020900002006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Swansea</a>,
            location: { lat: 51.6443138, lng: -3.9324398 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23020900002006&product=HOURLY_RENTAL'
        },
        {
            id: 466,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901903004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Swindon</a>,
            location: { lat: 51.56, lng: -1.8 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901903004&product=HOURLY_RENTAL'
        },
        {
            id: 467,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13072400002005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Sydenham</a>,
            location: { lat: 51.42, lng: -0.03 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13072400002005&product=HOURLY_RENTAL'
        },
        {
            id: 468,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11102100001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Tamworth</a>,
            location: { lat: 52.62, lng: -1.7 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11102100001000&product=HOURLY_RENTAL'
        },
        {
            id: 469,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901948001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Trafford Park</a>,
            location: { lat: 53.47, lng: -2.34 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901948001&product=HOURLY_RENTAL'
        },
        {
            id: 470,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901922001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Wallasey</a>,
            location: { lat: 53.41, lng: -3.07 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901922001&product=HOURLY_RENTAL'
        },
        {
            id: 471,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901914001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Warrington</a>,
            location: { lat: 53.42, lng: -2.6 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901914001&product=HOURLY_RENTAL'
        },
        {
            id: 472,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901981009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Washington</a>,
            location: { lat: 54.91, lng: -1.54 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901981009&product=HOURLY_RENTAL'
        },
        {
            id: 473,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901913002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Watford</a>,
            location: { lat: 51.64, lng: -0.38 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901913002&product=HOURLY_RENTAL'
        },
        {
            id: 474,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb17030700002000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Welwyn</a>,
            location: { lat: 51.800645, lng: -0.193144 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb17030700002000&product=HOURLY_RENTAL'
        },
        {
            id: 475,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901972000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q West Thurrock</a>,
            location: { lat: 51.48, lng: 0.28 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901972000&product=HOURLY_RENTAL'
        },
        {
            id: 476,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082902020000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Wigan</a>,
            location: { lat: 53.54, lng: -2.64 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082902020000&product=HOURLY_RENTAL'
        },
        {
            id: 477,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901941008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q Yeading</a>,
            location: { lat: 51.52, lng: -0.38 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901941008&product=HOURLY_RENTAL'
        },
        {
            id: 478,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb11082901982008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>B&Q York</a>,
            location: { lat: 53.95, lng: -1.02 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb11082901982008&product=HOURLY_RENTAL'
        },
        {
            id: 479,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121700002009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Aberdeen</a>,
            location: { lat: 57.14, lng: -2.27 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121700002009&product=HOURLY_RENTAL'
        },
        {
            id: 480,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Birmingham</a>,
            location: { lat: 52.5, lng: -1.85 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600001002&product=HOURLY_RENTAL'
        },
        {
            id: 481,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb20102700001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Bristol</a>,
            location: { lat: 51.499604, lng: -2.691534 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb20102700001008&product=HOURLY_RENTAL'
        },
        {
            id: 482,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19081200004003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Cardiff</a>,
            location: { lat: 51.4709473, lng: -3.2030239 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19081200004003&product=HOURLY_RENTAL'
        },
        {
            id: 483,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121700003008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Chester</a>,
            location: { lat: 53.24, lng: -2.93 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121700003008&product=HOURLY_RENTAL'
        },
        {
            id: 484,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600005008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Chingford</a>,
            location: { lat: 51.61, lng: -0.03 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600005008&product=HOURLY_RENTAL'
        },
        {
            id: 485,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600004009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Coventry</a>,
            location: { lat: 52.39, lng: -1.55 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600004009&product=HOURLY_RENTAL'
        },
        {
            id: 486,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600006007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Croydon</a>,
            location: { lat: 51.35, lng: -0.11 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600006007&product=HOURLY_RENTAL'
        },
        {
            id: 487,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600013008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Derby</a>,
            location: { lat: 52.91, lng: -1.44 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600013008&product=HOURLY_RENTAL'
        },
        {
            id: 488,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600014007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Edinburgh</a>,
            location: { lat: 55.87, lng: -3.16 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600014007&product=HOURLY_RENTAL'
        },
        {
            id: 489,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600007006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Farnborough</a>,
            location: { lat: 51.28, lng: -0.76 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600007006&product=HOURLY_RENTAL'
        },
        {
            id: 490,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600015006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Gateshead</a>,
            location: { lat: 54.96, lng: -1.65 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600015006&product=HOURLY_RENTAL'
        },
        {
            id: 491,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600016005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Glasgow</a>,
            location: { lat: 55.87, lng: -4.22 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600016005&product=HOURLY_RENTAL'
        },
        {
            id: 492,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600017004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Haydock</a>,
            location: { lat: 53.47, lng: -2.66 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600017004&product=HOURLY_RENTAL'
        },
        {
            id: 493,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb14081900001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Hayes</a>,
            location: { lat: 51.49, lng: -0.4 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb14081900001002&product=HOURLY_RENTAL'
        },
        {
            id: 494,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600018003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Leeds</a>,
            location: { lat: 53.78, lng: -1.53 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600018003&product=HOURLY_RENTAL'
        },
        {
            id: 495,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600008005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Leicester</a>,
            location: { lat: 52.68, lng: -1.09 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600008005&product=HOURLY_RENTAL'
        },
        {
            id: 496,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600019002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Liverpool</a>,
            location: { lat: 53.41, lng: -3 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600019002&product=HOURLY_RENTAL'
        },
        {
            id: 497,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600020009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Manchester</a>,
            location: { lat: 53.46, lng: -2.34 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600020009&product=HOURLY_RENTAL'
        },
        {
            id: 498,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600009004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Milton Keynes</a>,
            location: { lat: 52.03, lng: -0.68 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600009004&product=HOURLY_RENTAL'
        },
        {
            id: 499,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600021008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Oldham</a>,
            location: { lat: 53.52, lng: -2.15 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600021008&product=HOURLY_RENTAL'
        },
        {
            id: 500,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600010001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Reading</a>,
            location: { lat: 51.42, lng: -0.98 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600010001&product=HOURLY_RENTAL'
        },
        {
            id: 501,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121700004007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Sheffield</a>,
            location: { lat: 53.38, lng: -1.42 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121700004007&product=HOURLY_RENTAL'
        },
        {
            id: 502,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600011000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Southampton</a>,
            location: { lat: 50.91, lng: -1.43 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600011000&product=HOURLY_RENTAL'
        },
        {
            id: 503,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19071500001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Stevenage</a>,
            location: { lat: 51.9058065, lng: -0.2187761 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19071500001001&product=HOURLY_RENTAL'
        },
        {
            id: 504,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb15082400001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Sunbury</a>,
            location: { lat: 51.4227066, lng: -0.4141089 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb15082400001009&product=HOURLY_RENTAL'
        },
        {
            id: 505,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13121600012009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Thurrock</a>,
            location: { lat: 51.48, lng: 0.28 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13121600012009&product=HOURLY_RENTAL'
        },
        {
            id: 506,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13083000004008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Watford</a>,
            location: { lat: 51.66, lng: -0.36 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13083000004008&product=HOURLY_RENTAL'
        },
        {
            id: 507,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb16082200001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Costco Wembley</a>,
            location: { lat: 51.5564728, lng: -0.273086 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb16082200001001&product=HOURLY_RENTAL'
        },
        {
            id: 508,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb17082100005007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase Basildon</a>,
            location: { lat: 51.5536079, lng: 0.46585012 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb17082100005007&product=HOURLY_RENTAL'
        },
        {
            id: 509,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb17112200003001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase Basingstoke</a>,
            location: { lat: 51.249355, lng: -1.115158 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb17112200003001&product=HOURLY_RENTAL'
        },
        {
            id: 510,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18121200002009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase Bracknell</a>,
            location: { lat: 51.4180985, lng: -0.7621608 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18121200002009&product=HOURLY_RENTAL'
        },
        {
            id: 511,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21102800010003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase Bredbury</a>,
            location: { lat: 53.423, lng: -2.1176 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21102800010003&product=HOURLY_RENTAL'
        },
        {
            id: 512,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21102900001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase Brentford</a>,
            location: { lat: 51.483104, lng: -0.325464 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21102900001002&product=HOURLY_RENTAL'
        },
        {
            id: 513,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21102800007008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase Cheltenham</a>,
            location: { lat: 51.9098541, lng: -2.0925963 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21102800007008&product=HOURLY_RENTAL'
        },
        {
            id: 514,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22112900002007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase Chester</a>,
            location: { lat: 53.1975441, lng: -2.9161177 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22112900002007&product=HOURLY_RENTAL'
        },
        {
            id: 515,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21102800008007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase Colchester</a>,
            location: { lat: 51.8854293, lng: 0.8359598 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21102800008007&product=HOURLY_RENTAL'
        },
        {
            id: 516,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18010300007006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase Hanworth</a>,
            location: { lat: 51.437243, lng: -0.37627 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18010300007006&product=HOURLY_RENTAL'
        },
        {
            id: 517,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21102800014009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase Leeds</a>,
            location: { lat: 53.8436984, lng: -1.5552863 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21102800014009&product=HOURLY_RENTAL'
        },
        {
            id: 518,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb17062100002004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase Milton Keynes</a>,
            location: { lat: 52.0307465, lng: -0.7679476 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb17062100002004&product=HOURLY_RENTAL'
        },
        {
            id: 519,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18112200002000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase Orpington</a>,
            location: { lat: 51.4068298, lng: 0.1137571 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18112200002000&product=HOURLY_RENTAL'
        },
        {
            id: 520,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21102800012001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase Romford</a>,
            location: { lat: 51.5709, lng: 0.1825 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21102800012001&product=HOURLY_RENTAL'
        },
        {
            id: 521,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21102800009006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase Sheffield</a>,
            location: { lat: 53.3500942, lng: -1.4801358 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21102800009006&product=HOURLY_RENTAL'
        },
        {
            id: 522,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb17042700001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase St Albans</a>,
            location: { lat: 51.750897, lng: -0.282826 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb17042700001007&product=HOURLY_RENTAL'
        },
        {
            id: 523,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21102800013000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase Staines</a>,
            location: { lat: 51.4343, lng: -0.5222 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21102800013000&product=HOURLY_RENTAL'
        },
        {
            id: 524,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21102900002001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase Streatham Vale</a>,
            location: { lat: 51.4170874, lng: -0.1354629 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21102900002001&product=HOURLY_RENTAL'
        },
        {
            id: 525,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21102900003000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Homebase Woking</a>,
            location: { lat: 51.3122191, lng: -0.6246 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21102900003000&product=HOURLY_RENTAL'
        },
        {
            id: 526,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19102800002007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Aberdeen</a>,
            location: { lat: 57.1094797, lng: -2.0955891 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19102800002007&product=HOURLY_RENTAL'
        },
        {
            id: 527,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb14070700007003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Bristol</a>,
            location: { lat: 51.47, lng: -2.56 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb14070700007003&product=HOURLY_RENTAL'
        },
        {
            id: 528,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb15020600003007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Cardiff</a>,
            location: { lat: 51.4636803, lng: -3.1888261 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb15020600003007&product=HOURLY_RENTAL'
        },
        {
            id: 529,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb16112900001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Croydon</a>,
            location: { lat: 51.381331, lng: -0.124278 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb16112900001000&product=HOURLY_RENTAL'
        },
        {
            id: 530,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb14070700004006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Edinburgh</a>,
            location: { lat: 55.88, lng: -3.16 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb14070700004006&product=HOURLY_RENTAL'
        },
        {
            id: 531,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18042400001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Exeter</a>,
            location: { lat: 50.7067375, lng: -3.4784789 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18042400001002&product=HOURLY_RENTAL'
        },
        {
            id: 532,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13083000005007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Gateshead</a>,
            location: { lat: 54.95, lng: -1.68 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13083000005007&product=HOURLY_RENTAL'
        },
        {
            id: 533,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb14120100001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Glasgow</a>,
            location: { lat: 55.87, lng: -4.35 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb14120100001002&product=HOURLY_RENTAL'
        },
        {
            id: 534,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb15061900001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Lakeside</a>,
            location: { lat: 51.4804077, lng: 0.2857995 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb15061900001003&product=HOURLY_RENTAL'
        },
        {
            id: 535,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb14070700006004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Leeds</a>,
            location: { lat: 53.74, lng: -1.65 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb14070700006004&product=HOURLY_RENTAL'
        },
        {
            id: 536,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb14050600001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Manchester</a>,
            location: { lat: 53.49, lng: -2.1 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb14050600001005&product=HOURLY_RENTAL'
        },
        {
            id: 537,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13103000003005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Milton Keynes</a>,
            location: { lat: 52, lng: -0.72 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13103000003005&product=HOURLY_RENTAL'
        },
        {
            id: 538,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb15062400001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Nottingham</a>,
            location: { lat: 53.0017586, lng: -1.2822227 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb15062400001003&product=HOURLY_RENTAL'
        },
        {
            id: 539,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb16063000001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Reading</a>,
            location: { lat: 51.4416313, lng: -1.0648518 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb16063000001008&product=HOURLY_RENTAL'
        },
        {
            id: 540,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb17092600001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Sheffield</a>,
            location: { lat: 53.4073639, lng: -1.4092577 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb17092600001008&product=HOURLY_RENTAL'
        },
        {
            id: 541,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb15020600002008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Southampton</a>,
            location: { lat: 50.9029503, lng: -1.4103049 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb15020600002008&product=HOURLY_RENTAL'
        },
        {
            id: 542,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb14070700005005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Warrington</a>,
            location: { lat: 53.41, lng: -2.62 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb14070700005005&product=HOURLY_RENTAL'
        },
        {
            id: 543,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb13103000001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>IKEA Wembley</a>,
            location: { lat: 51.55, lng: -0.26 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb13103000001007&product=HOURLY_RENTAL'
        },
        {
            id: 544,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22110400002000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Hertz 24/7 Brighton Branch</a>,
            location: { lat: 50.8298732, lng: -0.1127601 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22110400002000&product=HOURLY_RENTAL'
        },
        {
            id: 545,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19090200001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Hertz 24/7 Cambridge Branch</a>,
            location: { lat: 52.1923997, lng: 0.1384558 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19090200001005&product=HOURLY_RENTAL'
        },
        {
            id: 546,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22110400003009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Hertz 24/7 Cardiff Branch</a>,
            location: { lat: 51.4675998, lng: -3.1992487 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22110400003009&product=HOURLY_RENTAL'
        },
        {
            id: 547,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22110400001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Hertz 24/7 Cheltenham Branch</a>,
            location: { lat: 51.900905, lng: -2.15057 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22110400001001&product=HOURLY_RENTAL'
        },
        {
            id: 548,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23033100002008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Hertz 24/7 Dartford Branch</a>,
            location: { lat: 51.4667186, lng: 0.18160586 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23033100002008&product=HOURLY_RENTAL'
        },
        {
            id: 549,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22033100001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Hertz 24/7 Feltham Branch</a>,
            location: { lat: 51.463138, lng: -0.392681 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22033100001001&product=HOURLY_RENTAL'
        },
        {
            id: 550,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19081900002000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Hertz 24/7 Manchester Piccadilly Branch</a>,
            location: { lat: 53.4780083, lng: -2.2287476 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19081900002000&product=HOURLY_RENTAL'
        },
        {
            id: 551,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21021000001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Hertz 24/7 Oxford Branch</a>,
            location: { lat: 51.748771, lng: -1.2756772 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21021000001009&product=HOURLY_RENTAL'
        },
        {
            id: 552,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23020700002000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Hertz 24/7 Welwyn Garden City Branch</a>,
            location: { lat: 51.7993252, lng: -0.1995842 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23020700002000&product=HOURLY_RENTAL'
        },
        {
            id: 553,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19092700002001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Apex Storage Cheadle</a>,
            location: { lat: 53.358435, lng: -2.2039257 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19092700002001&product=HOURLY_RENTAL'
        },
        {
            id: 554,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22011100001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Apex Storage Congleton</a>,
            location: { lat: 53.1697369, lng: -2.2013306 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22011100001007&product=HOURLY_RENTAL'
        },
        {
            id: 555,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23041800002003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Apex Storage Glossop</a>,
            location: { lat: 53.4603271, lng: -1.984729 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23041800002003&product=HOURLY_RENTAL'
        },
        {
            id: 556,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19080700002005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Apex Storage Hulme</a>,
            location: { lat: 53.4588356, lng: -2.2458739 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19080700002005&product=HOURLY_RENTAL'
        },
        {
            id: 557,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23092700002003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Cinch Self Storage Brentwood</a>,
            location: { lat: 51.6152532, lng: 0.290361 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23092700002003&product=HOURLY_RENTAL'
        },
        {
            id: 558,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23092700001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Cinch Self Storage Watford</a>,
            location: { lat: 51.6807935, lng: -0.4012446 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23092700001004&product=HOURLY_RENTAL'
        },
        {
            id: 559,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19011000001005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Aldershot</a>,
            location: { lat: 51.2422719, lng: -0.7379302 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19011000001005&product=HOURLY_RENTAL'
        },
        {
            id: 560,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19011000002004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Ashford</a>,
            location: { lat: 51.1298582, lng: 0.8653338 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19011000002004&product=HOURLY_RENTAL'
        },
        {
            id: 561,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19011000003003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Basingstoke</a>,
            location: { lat: 51.2879594, lng: -1.0696928 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19011000003003&product=HOURLY_RENTAL'
        },
        {
            id: 562,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23022800001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Bedford</a>,
            location: { lat: 52.1308286, lng: -0.4573981 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23022800001007&product=HOURLY_RENTAL'
        },
        {
            id: 563,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19011000004002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Bristol</a>,
            location: { lat: 51.443445, lng: -2.499947 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19011000004002&product=HOURLY_RENTAL'
        },
        {
            id: 564,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19021300003005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Cardiff</a>,
            location: { lat: 51.4633522, lng: -3.1945477 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19021300003005&product=HOURLY_RENTAL'
        },
        {
            id: 565,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19011000006000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Crawley</a>,
            location: { lat: 51.1343109, lng: -0.1676252 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19011000006000&product=HOURLY_RENTAL'
        },
        {
            id: 566,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19011000007009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Crayford</a>,
            location: { lat: 51.4622388, lng: 0.18995 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19011000007009&product=HOURLY_RENTAL'
        },
        {
            id: 567,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19011700001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Dover</a>,
            location: { lat: 51.1521797, lng: 1.30515516 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19011700001000&product=HOURLY_RENTAL'
        },
        {
            id: 568,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19061700001009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Exeter</a>,
            location: { lat: 50.69524, lng: -3.5202422 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19061700001009&product=HOURLY_RENTAL'
        },
        {
            id: 569,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19011000009007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Fareham</a>,
            location: { lat: 50.8621615, lng: -1.1684415 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19011000009007&product=HOURLY_RENTAL'
        },
        {
            id: 570,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19011000010004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Farnborough</a>,
            location: { lat: 51.3144989, lng: -0.760196 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19011000010004&product=HOURLY_RENTAL'
        },
        {
            id: 571,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19011000011003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Gillingham</a>,
            location: { lat: 51.364905, lng: 0.579884 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19011000011003&product=HOURLY_RENTAL'
        },
        {
            id: 572,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22030900004004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Gloucester</a>,
            location: { lat: 51.8612995, lng: -2.2247845 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22030900004004&product=HOURLY_RENTAL'
        },
        {
            id: 573,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18053000001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Harlow</a>,
            location: { lat: 51.783205, lng: 0.110443 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18053000001006&product=HOURLY_RENTAL'
        },
        {
            id: 574,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb20120200001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Hedge End</a>,
            location: { lat: 50.9236238, lng: -1.309443 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb20120200001007&product=HOURLY_RENTAL'
        },
        {
            id: 575,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18053000002005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Hemel Hempstead</a>,
            location: { lat: 51.7553444, lng: -0.4381356 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18053000002005&product=HOURLY_RENTAL'
        },
        {
            id: 576,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19081200003004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Ipswich</a>,
            location: { lat: 52.035183, lng: 1.20390904 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19081200003004&product=HOURLY_RENTAL'
        },
        {
            id: 577,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb20092900001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Leicester</a>,
            location: { lat: 52.6191483, lng: -1.1343478 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb20092900001006&product=HOURLY_RENTAL'
        },
        {
            id: 578,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18060100004002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Luton</a>,
            location: { lat: 51.883902, lng: -0.40997 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18060100004002&product=HOURLY_RENTAL'
        },
        {
            id: 579,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19011000013001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Maidenhead</a>,
            location: { lat: 51.5173531, lng: -0.7164371 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19011000013001&product=HOURLY_RENTAL'
        },
        {
            id: 580,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18060100005001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Milton Keynes</a>,
            location: { lat: 52.0309944, lng: -0.6895177 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18060100005001&product=HOURLY_RENTAL'
        },
        {
            id: 581,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18060100006000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Northampton Central</a>,
            location: { lat: 52.2425766, lng: -0.903603 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18060100006000&product=HOURLY_RENTAL'
        },
        {
            id: 582,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18060100007009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Northampton Riverside</a>,
            location: { lat: 52.2443886, lng: -0.8342574 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18060100007009&product=HOURLY_RENTAL'
        },
        {
            id: 583,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23020900001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Oldbury</a>,
            location: { lat: 52.4996977, lng: -2.0185506 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23020900001007&product=HOURLY_RENTAL'
        },
        {
            id: 584,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19011000014000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Poole</a>,
            location: { lat: 50.7349724, lng: -1.9896563 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19011000014000&product=HOURLY_RENTAL'
        },
        {
            id: 585,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19011000015009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Portsmouth</a>,
            location: { lat: 50.8135649, lng: -1.0878977 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19011000015009&product=HOURLY_RENTAL'
        },
        {
            id: 586,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18060100008008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Reading</a>,
            location: { lat: 51.4473335, lng: -0.9748883 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18060100008008&product=HOURLY_RENTAL'
        },
        {
            id: 587,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21040600001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Salford</a>,
            location: { lat: 53.4785321, lng: -2.2760122 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21040600001003&product=HOURLY_RENTAL'
        },
        {
            id: 588,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19011100002002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Southampton</a>,
            location: { lat: 50.9150242, lng: -1.4482954 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19011100002002&product=HOURLY_RENTAL'
        },
        {
            id: 589,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22061300002001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Stevenage</a>,
            location: { lat: 51.8887273, lng: -0.2025655 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22061300002001&product=HOURLY_RENTAL'
        },
        {
            id: 590,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18060100009007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Sunbury-on-Thames</a>,
            location: { lat: 51.4218983, lng: -0.4153281 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18060100009007&product=HOURLY_RENTAL'
        },
        {
            id: 591,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19011100003001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Swindon</a>,
            location: { lat: 51.5766678, lng: -1.771248 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19011100003001&product=HOURLY_RENTAL'
        },
        {
            id: 592,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19011100004000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Tonbridge</a>,
            location: { lat: 51.194332, lng: 0.284518 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19011100004000&product=HOURLY_RENTAL'
        },
        {
            id: 593,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22031100001003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Warrington</a>,
            location: { lat: 53.396398, lng: -2.5933943 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22031100001003&product=HOURLY_RENTAL'
        },
        {
            id: 594,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18060100010004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Lok'nStore Wellingborough</a>,
            location: { lat: 52.2902756, lng: -0.6857309 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18060100010004&product=HOURLY_RENTAL'
        },
        {
            id: 595,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb20111000003000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Magenta Self Storage Banbury</a>,
            location: { lat: 52.0729942, lng: -1.317806 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb20111000003000&product=HOURLY_RENTAL'
        },
        {
            id: 596,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb20110900001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Magenta Self Storage Chiswick</a>,
            location: { lat: 51.499998, lng: -0.275022 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb20110900001004&product=HOURLY_RENTAL'
        },
        {
            id: 597,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb20111000004009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Magenta Self Storage Nottingham</a>,
            location: { lat: 52.9435387, lng: -1.1333807 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb20111000004009&product=HOURLY_RENTAL'
        },
        {
            id: 598,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23011300002000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Magenta Self Storage Reading</a>,
            location: { lat: 51.4280156, lng: -0.975733 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23011300002000&product=HOURLY_RENTAL'
        },
        {
            id: 599,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb20111000005008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Magenta Self Storage St Albans</a>,
            location: { lat: 51.7676576, lng: -0.3210541 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb20111000005008&product=HOURLY_RENTAL'
        },
        {
            id: 600,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23052600003002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Squab Storage Evesham</a>,
            location: { lat: 52.0745698, lng: -1.9391691 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23052600003002&product=HOURLY_RENTAL'
        },
        {
            id: 601,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23052600001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Squab Storage Leamington Spa</a>,
            location: { lat: 52.254764, lng: -1.5295272 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23052600001004&product=HOURLY_RENTAL'
        },
        {
            id: 602,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23052600002003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Squab Storage Rubery</a>,
            location: { lat: 52.4005635, lng: -2.0001473 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23052600002003&product=HOURLY_RENTAL'
        },
        {
            id: 603,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23090700001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Squab Storage Stowmarket</a>,
            location: { lat: 52.1852672, lng: 1.01283022 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23090700001006&product=HOURLY_RENTAL'
        },
        {
            id: 604,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19022200021004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King  Chester</a>,
            location: { lat: 53.1938553, lng: -2.9117773 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19022200021004&product=HOURLY_RENTAL'
        },
        {
            id: 605,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18062200001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Aylesford</a>,
            location: { lat: 51.3046265, lng: 0.44913971 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18062200001001&product=HOURLY_RENTAL'
        },
        {
            id: 606,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18080700003006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Banbury</a>,
            location: { lat: 52.072203, lng: -1.327459 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18080700003006&product=HOURLY_RENTAL'
        },
        {
            id: 607,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19022200003006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Basildon</a>,
            location: { lat: 51.5835964, lng: 0.4658163 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19022200003006&product=HOURLY_RENTAL'
        },
        {
            id: 608,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23032300002005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Bath</a>,
            location: { lat: 51.3807345, lng: -2.3897716 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23032300002005&product=HOURLY_RENTAL'
        },
        {
            id: 609,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19050700005008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Bedford</a>,
            location: { lat: 52.147472, lng: -0.411669 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19050700005008&product=HOURLY_RENTAL'
        },
        {
            id: 610,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23041800001004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Blackpool</a>,
            location: { lat: 53.7997578, lng: -3.009054 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23041800001004&product=HOURLY_RENTAL'
        },
        {
            id: 611,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19022200007002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Cambridge</a>,
            location: { lat: 52.1956703, lng: 0.1719087 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19022200007002&product=HOURLY_RENTAL'
        },
        {
            id: 612,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19022200022003&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Crewe</a>,
            location: { lat: 53.0877762, lng: -2.4303367 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19022200022003&product=HOURLY_RENTAL'
        },
        {
            id: 613,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19022200011006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Dartford</a>,
            location: { lat: 51.438407, lng: 0.2458 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19022200011006&product=HOURLY_RENTAL'
        },
        {
            id: 614,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19022200015002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Derby</a>,
            location: { lat: 52.923869, lng: -1.463421 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19022200015002&product=HOURLY_RENTAL'
        },
        {
            id: 615,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19022200018009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Doncaster</a>,
            location: { lat: 53.508316, lng: -1.1197892 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19022200018009&product=HOURLY_RENTAL'
        },
        {
            id: 616,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21102700003004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Dudley</a>,
            location: { lat: 52.5168492, lng: -2.0664217 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21102700003004&product=HOURLY_RENTAL'
        },
        {
            id: 617,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19022200012005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Dunstable</a>,
            location: { lat: 51.8976026, lng: -0.5137206 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19022200012005&product=HOURLY_RENTAL'
        },
        {
            id: 618,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18070600002001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Epsom</a>,
            location: { lat: 51.3406853, lng: -0.2633607 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18070600002001&product=HOURLY_RENTAL'
        },
        {
            id: 619,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19022200019008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Gloucester</a>,
            location: { lat: 51.8657053, lng: -2.2110733 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19022200019008&product=HOURLY_RENTAL'
        },
        {
            id: 620,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22070800004007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Harrogate</a>,
            location: { lat: 54.0046367, lng: -1.5494331 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22070800004007&product=HOURLY_RENTAL'
        },
        {
            id: 621,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19050700004009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King High Wycombe</a>,
            location: { lat: 51.6316328, lng: -0.7658558 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19050700004009&product=HOURLY_RENTAL'
        },
        {
            id: 622,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22071300001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Huddersfield</a>,
            location: { lat: 53.6534926, lng: -1.776692 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22071300001000&product=HOURLY_RENTAL'
        },
        {
            id: 623,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18070600003000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Milton Keynes</a>,
            location: { lat: 51.9847545, lng: -0.7301864 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18070600003000&product=HOURLY_RENTAL'
        },
        {
            id: 624,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb20072100001007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Nottingham</a>,
            location: { lat: 52.9729118, lng: -1.1745616 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb20072100001007&product=HOURLY_RENTAL'
        },
        {
            id: 625,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18070600004009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Oxford</a>,
            location: { lat: 51.7286973, lng: -1.1996809 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18070600004009&product=HOURLY_RENTAL'
        },
        {
            id: 626,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22062100002004&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Salisbury</a>,
            location: { lat: 51.175118, lng: -1.7570025 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22062100002004&product=HOURLY_RENTAL'
        },
        {
            id: 627,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22070800002009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Wakefield</a>,
            location: { lat: 53.6792123, lng: -1.4920619 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22070800002009&product=HOURLY_RENTAL'
        },
        {
            id: 628,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb20073000001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Warrington</a>,
            location: { lat: 53.416161, lng: -2.599766 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb20073000001008&product=HOURLY_RENTAL'
        },
        {
            id: 629,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19050700006007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Weybridge </a>,
            location: { lat: 51.3499718, lng: -0.4769638 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19050700006007&product=HOURLY_RENTAL'
        },
        {
            id: 630,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb18070600005008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Woodley Reading</a>,
            location: { lat: 51.4544345, lng: -0.8951519 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb18070600005008&product=HOURLY_RENTAL'
        },
        {
            id: 631,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb19022200020005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King Worcester</a>,
            location: { lat: 52.1968597, lng: -2.2072156 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb19022200020005&product=HOURLY_RENTAL'
        },
        {
            id: 632,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22070800003008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Storage King York</a>,
            location: { lat: 53.9835205, lng: -1.0948654 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22070800003008&product=HOURLY_RENTAL'
        },
        {
            id: 633,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22020400001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Sure Store Ashton-Under-Lyne</a>,
            location: { lat: 53.4884122, lng: -2.1147334 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22020400001000&product=HOURLY_RENTAL'
        },
        {
            id: 634,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb20081700003001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>SureStore Bolton</a>,
            location: { lat: 53.5712173, lng: -2.4216497 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb20081700003001&product=HOURLY_RENTAL'
        },
        {
            id: 635,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb20081700007007&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>SureStore Burton-On-Trent</a>,
            location: { lat: 52.7906611, lng: -1.6694837 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb20081700007007&product=HOURLY_RENTAL'
        },
        {
            id: 636,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb20081700004000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>SureStore Bury</a>,
            location: { lat: 53.5756493, lng: -2.2731283 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb20081700004000&product=HOURLY_RENTAL'
        },
        {
            id: 637,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb20081700005009&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>SureStore Cannock</a>,
            location: { lat: 52.6760405, lng: -2.0369721 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb20081700005009&product=HOURLY_RENTAL'
        },
        {
            id: 638,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb20081700009005&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>SureStore Northwich</a>,
            location: { lat: 53.2611601, lng: -2.5057597 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb20081700009005&product=HOURLY_RENTAL'
        },
        {
            id: 639,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21083100001002&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>SureStore Old Trafford</a>,
            location: { lat: 53.458416, lng: -2.2954185 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21083100001002&product=HOURLY_RENTAL'
        },
        {
            id: 640,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb20081700008006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>SureStore Stafford</a>,
            location: { lat: 52.837306, lng: -2.1351167 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb20081700008006&product=HOURLY_RENTAL'
        },
        {
            id: 641,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb21021000002008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>SureStore Wigan</a>,
            location: { lat: 53.5435147, lng: -2.6360706 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb21021000002008&product=HOURLY_RENTAL'
        },
        {
            id: 642,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22093000001000&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>SureStore Wokingham</a>,
            location: { lat: 51.4051482, lng: -0.8465785 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22093000001000&product=HOURLY_RENTAL'
        },
        {
            id: 643,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23032300001006&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>SureStore York</a>,
            location: { lat: 53.9885106, lng: -1.0917539 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23032300001006&product=HOURLY_RENTAL'
        },
        {
            id: 644,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb22090700001008&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>The Self Storage Company Welwyn Garden City</a>,
            location: { lat: 51.800243, lng: -0.1991314 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb22090700001008&product=HOURLY_RENTAL'
        },
        {
            id: 645,
            name: <a href='https://booking.hertz247.com/booking/book?location=prk-gb23020700001001&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>The Self Storage Company West Molesey</a>,
            location: { lat: 51.3964932, lng: -0.3792193 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=prk-gb23020700001001&product=HOURLY_RENTAL'
        },
        {
            id: 646,
            name: <a href='https://booking.hertz247.com/booking/book?location=Gb-prk-Lens_Granton&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Len's Self Storage - Granton</a>,
            location: { lat: 55.9814869063365, lng: -3.22449691810586 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=Gb-prk-Lens_Granton&product=HOURLY_RENTAL'
        },
        {
            id: 647,
            name: <a href='https://booking.hertz247.com/booking/book?location=gb- prk-Lens_Hillington&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Len's Self Storage - Hillington</a>,
            location: { lat: 55.86435, lng: -4.36948 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=gb- prk-Lens_Hillington&product=HOURLY_RENTAL'
        },
        {
            id: 648,
            name: <a href='https://booking.hertz247.com/booking/book?location=gb-prk-Lens_Kinning_Park&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Len's Self Storage - Kinning Park</a>,
            location: { lat: 55.8483781490017, lng: -4.29043615981958 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=gb-prk-Lens_Kinning_Park&product=HOURLY_RENTAL'
        },
        {
            id: 649,
            name: <a href='https://booking.hertz247.com/booking/book?location=gb-prk-Lens_Sighthill&product=HOURLY_RENTAL' style={{ textDecoration: 'none', color: 'black' }}>Len's Self Storage - Sighthill</a>,
            location: { lat: 55.9269799439315, lng: -3.29832040331951 },
            ufoUrl: 'https://booking.hertz247.com/booking/book?location=gb-prk-Lens_Sighthill&product=HOURLY_RENTAL'
        }
    ];

    return (
        isLoaded && (
            <>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={7}
                    options={{
                        streetViewControl: false,
                        mapTypeControl: false
                    }}

                >
                    {points.map((point) => {
                        return (
                            <div key={point.id}>
                                <Marker position={point.location} options={{
                                    icon : 'https://images.hertz.com/content/dam/hod/images/en-gb/static/Location_Pin.png',
                                }} onClick={() => {
                                    setSelectedMarker(point);
                                }} />
                            </div>
                        );
                    })}
                    {selectedMarker && (
                        <InfoWindow position={selectedMarker.location} options={{
                            pixelOffset: new window.google.maps.Size(0, -40)
                        }} onCloseClick={() => setSelectedMarker("")}>
                            <>
                                <h6>{selectedMarker.name}</h6>
                                <button className="btn btn-warning" onClick={() => window.location.href = selectedMarker.ufoUrl}>Book Now</button>
                            </>

                        </InfoWindow>
                    )}
                </GoogleMap>
            </>
        )
    );
};

export default Map;