import React from "react";
import Nav from "./Components/Nav";
import Footer from "./Components/Footer";
import BodyHowitWorks from "./Components/BodyHowitWorks";

class ITHowitWorks extends React.Component {
    render() {
        return (<>
            <Nav />
            <BodyHowitWorks />
            <Footer />
        </>
        );

    }
}

export default ITHowitWorks;